import { Modal } from 'antd'
import { useState, type FC, type ReactNode } from 'react'
import { type Filter } from '../../../../services/question'
import { OwnTest } from './components/OwnTest'
import { SubjectsTest } from './components/SubjectTest'
import { TopicTest } from './components/TopicTest'

import './SelectTestModal.scss'
import { type ITest } from '../../../../types/test'
import { useUser } from '../../../../hooks/useUser'
import { OwnUserTest } from './components/OwnTest/OwnUserTest'
import { RatingTest } from './RatingTest/RatingTest'

interface Props {
  onCancel: () => void
  onStart: (filter: Filter) => void
  visible: boolean
  onSelectTest: (test: ITest) => void
}

enum TestTypes {
  SUBJECT = 'subject',
  TOPIC = 'topic',
  SIMULATION = 'simulation',
  OWN = 'own',
  RATING = 'rating',
  MY_RATING = 'my-rating'
}

const SelectTestModal: FC<Props> = ({ onCancel, onStart, visible, onSelectTest }) => {
  const { isAdmin, isSuperAdmin } = useUser()
  const [selectedSubject, setSelectedSubject] = useState<string>()
  const [selectedTopic, setSelectedTopic] = useState<string>()
  const [selectedTest, setSelectedTest] = useState<ITest>()
  const [rating, setRating] = useState(0)
  const [ownRating, setOwnRating] = useState(0)
  const [numberOfQuestions, setNumberOfQuestions] = useState(30)

  const [testType, setTestType] = useState<TestTypes>()

  const getDisabledState = () => {
    if (testType === TestTypes.TOPIC) {
      return !(selectedSubject && selectedTopic)
    }
    if (testType === TestTypes.SUBJECT) {
      return !selectedSubject
    }
    if (testType === TestTypes.OWN) {
      return !selectedTest
    }
    return false
  }

  const handleStart = () => {
    if (testType === TestTypes.OWN) {
      if (selectedTest) {
        onSelectTest(selectedTest)
      }
    } else {
      onStart({
        rating,
        subjects: selectedSubject ? [selectedSubject!] : [],
        topics: selectedTopic ? [selectedTopic!] : [],
        ownRating,
        numberOfQuestions,
        onlyFailed: false
      })
    }
  }

  const getOwnTypeTest = (): ReactNode => {
    if (testType === TestTypes.OWN && (isAdmin || isSuperAdmin)) {
      return <OwnTest selectedTest={selectedTest} setSelectedTest={setSelectedTest} />
    } else if (testType === TestTypes.OWN) {
      return <OwnUserTest selectedTest={selectedTest} setSelectedTest={setSelectedTest} />
    } else {
      return null
    }
  }

  return (
    <Modal title="Seleccionar test" open={visible} okButtonProps={{ disabled: getDisabledState() }} onOk={handleStart} onCancel={onCancel} okText="Empezar">
      {!testType && (
        <div className='select-test-type'>
          <p>Selecciona el tipo de test que quieres hacer</p>
          <button onClick={() => setTestType(TestTypes.TOPIC)}>
            De un tema
          </button>
          <button onClick={() => setTestType(TestTypes.SUBJECT)}>
            De un bloque entero
          </button>
          <button onClick={handleStart}>
            Simulacro (incluye preguntas de cualquier bloque)
          </button>
          <button onClick={() => setTestType(TestTypes.OWN)}>
            Propios (Tests que hemos diseñado para tí)
          </button>
          <button onClick={() => setTestType(TestTypes.RATING)}>
            Por dificultad (critério del profesor)
          </button>
          <button onClick={() => setTestType(TestTypes.MY_RATING)}>
            Por dificultad (critério propio)
          </button>
        </div>
      )}
      {getOwnTypeTest()}
      {testType === TestTypes.SUBJECT
        ? (
          <SubjectsTest
            selectedSubject={selectedSubject}
            setSelectedSubject={setSelectedSubject}
            setNumberOfQuestions={setNumberOfQuestions}
            numberOfQuestions={numberOfQuestions}
          />
          )
        : null}
      {testType === TestTypes.RATING
        ? (
          <RatingTest
            setRating={setRating}
            rating={rating}
            setNumberOfQuestions={setNumberOfQuestions}
            numberOfQuestions={numberOfQuestions}
          />
          )
        : null}
      {testType === TestTypes.MY_RATING
        ? (
          <RatingTest
            setRating={setOwnRating}
            rating={ownRating}
            setNumberOfQuestions={setNumberOfQuestions}
            numberOfQuestions={numberOfQuestions}
          />
          )
        : null}
      {testType === TestTypes.TOPIC
        ? (
          <TopicTest
            selectedSubject={selectedSubject}
            setSelectedSubject={setSelectedSubject}
            selectedTopic={selectedTopic}
            setSelectedTopic={setSelectedTopic}
            setNumberOfQuestions={setNumberOfQuestions}
            numberOfQuestions={numberOfQuestions}
          />
          )
        : null}
    </Modal>
  )
}

export { SelectTestModal }
