import { gql } from '@apollo/client'

export const GET_ROLES = gql`
query getAllRoles {
    getAllUserRoles{
      _id
      name
    }
  }
`
