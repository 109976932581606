import { PlusOutlined } from '@ant-design/icons'

import { EmptyCase, Input, Screen, Select } from '../../components'
import { useCreateOnlineSession, useDeleteOnlineSession, useGetAllOnlineSessions } from '../../services/onlineSession'
import { Loader } from '../../components/Loader/Loader'

import './OnlineSessionsPage.scss'
import { Collapse, Modal, Spin } from 'antd'
import { useMemo, useState } from 'react'
// import { format } from 'date-fns'
import { useAuth } from '../../hooks/useAuth'
import { RoleEnum } from '../../types/role'
// import { IconTrash } from '@tabler/icons-react'
import { parseSelect } from '../../utils/parseSelect'
import { useGetTopicsBySubject } from '../../services/topic'
import { useGetSubjectsByBranch } from '../../services/subject'
import { type IOnlineSession } from '../../types/onlineSession'
import { format } from 'date-fns'
import { IconTrash } from '@tabler/icons-react'

const OnlineSessionsPage = () => {
  const { loading, onlineSessions, refetch } = useGetAllOnlineSessions()
  const { createOnlineSession, loading: creating } = useCreateOnlineSession()
  const { deleteOnlineSession, loading: deleting } = useDeleteOnlineSession()
  const [visible, setVisible] = useState(false)
  const { user } = useAuth()

  const isAdmin = (user?.role?.name === RoleEnum.superadmin || user?.role?.name === RoleEnum.admin)

  const [title, setTitle] = useState('')
  const [url, setUrl] = useState('')
  const [editedSubject, setEditedSubject] = useState<string>('')
  const [editedTopic, setEditedTopic] = useState<string>('')

  const { subjects, loading: loadingSubjects } = useGetSubjectsByBranch()
  const { topics } = useGetTopicsBySubject(editedSubject)

  const parsedSubjects = useMemo(() => parseSelect({
    array: subjects || [],
    labelKey: 'name',
    valueKey: '_id'
  }), [subjects])

  const parsedTopics = useMemo(() => parseSelect({
    array: topics || [],
    labelKey: 'name',
    valueKey: '_id'
  }), [topics])

  const handleCancel = () => {
    setVisible(false)
    setTitle('')
    setUrl('')
  }

  const handleSave = async () => {
    await createOnlineSession({ title, url, topic: editedTopic, subject: editedSubject })
    await refetch()
    handleCancel()
  }

  const handleDelete = async (id: string) => {
    await deleteOnlineSession(id)
    await refetch()
  }

  const renderHeader = (name: string) => {
    return (
      <div className='subject-header'>
        <p>{name}</p>
      </div>
    )
  }

  const actions = isAdmin ? [{ icon: <PlusOutlined />, onClick: () => setVisible(true) }] : []

  return (
    <Screen id="online-sessions" title="Clases online" actions={actions}>
      {loading && (
        <div className='online-session-container'>
          <Loader size={200} />
        </div>
      )}
      {!loading && (!onlineSessions || onlineSessions.length === 0) && (
        <EmptyCase size={100} text="No se han encontrado clases" />
      )}
      {!loading && !loadingSubjects && !!onlineSessions
        ? (
          <Collapse>
            {Object.entries(onlineSessions).map(([bloc, blocContent]) => (
              <Collapse.Panel header={renderHeader(bloc)} key={bloc}>
                <Collapse>
                  {Object.entries(blocContent as any).map(([topic, topicContent]) => (
                    <Collapse.Panel header={renderHeader(topic)} key={topic}>
                      {(topicContent as IOnlineSession[]).map((onlineSession) => (
                        <div key={onlineSession._id} className='online-session'>
                          <div>
                            <p className='title'>{onlineSession.title}</p>
                            <p className='date'>{format(new Date(onlineSession.createdAt), 'dd/MM/yyyy')}</p>
                          </div>
                          <div className='buttons'>

                            <a href={onlineSession.url} target='_blank' rel="noreferrer">
                              Ver video
                            </a>
                            {isAdmin && (
                              <button disabled={deleting} onClick={async () => await handleDelete(onlineSession._id)}>
                                <IconTrash size={20} />
                              </button>
                            )}
                            {deleting && (
                              <Spin />
                            )}
                          </div>
                        </div>
                      ))}
                    </Collapse.Panel>
                  ))}
                </Collapse>
              </Collapse.Panel>
            ))}
          </Collapse>
        )
        : null}
      <Modal
        open={visible}
        onCancel={handleCancel}
        onOk={handleSave}
        okButtonProps={{
          disabled: !title || !url,
          loading: creating
        }}
        okText="Guardar"
        title="Crear clase online"
      >
        <Input value={title} onChange={(e) => { setTitle(e.target.value) }} placeholder='Título' />
        <Input value={url} onChange={(e) => { setUrl(e.target.value) }} placeholder='Enlace' />
        <Select
          placeholder="Bloque"
          onChange={setEditedSubject}
          options={parsedSubjects}
          value={editedSubject}
        />
        <Select
          placeholder="Tema"
          onChange={setEditedTopic}
          options={parsedTopics}
          value={editedTopic}
        />
      </Modal>
    </Screen>
  )
}

export { OnlineSessionsPage }
