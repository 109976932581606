import { gql } from '@apollo/client'

export const GET_TOPICS = gql`
query getTopicsByBranch ($input: String!){
    getTopicsByBranch(input: $input) {
        _id
        active
        branch {
            _id
            active
            image
            name
        }
        subject {
            name
            color
            active
            _id
        }
        color
        name
    }
}
`

export const GET_TOPICS_BY_SUBJECT = gql`
    query getTopicsBySubject($input: String!) {
        getTopicsBySubject(input: $input) {
            _id
        active
        branch {
            _id
            active
            image
            name
        }
        subject {
            name
            color
            active
            _id
        }
        color
        name
        }
    }
`
