import { type FC, type PropsWithChildren } from 'react'

import './Title.scss'
import clsx from 'clsx'

type Props = PropsWithChildren<{
  dark?: boolean
}>

const Title: FC<Props> = ({ children, dark }) => (
    <div className={clsx('title-container', dark && 'dark-container')}>
        <div className="small-decorator" />
        <h1 className={clsx('title-comp', dark && 'title-dark')}>{children}</h1>
        <div className="decorator" />
    </div>
)

export { Title }
