import { useState } from 'react'
import axios from 'axios'
import { BACKEND_URL } from '../constants/apollo'
import { useCompleteMultipartUpload, useGetMultipartUploadId, useGetPresignedUrls } from '../services/aws'

const convertBase64 = async (file: any) => {
  return await new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)

    fileReader.onload = () => {
      resolve(fileReader.result)
    }

    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}

interface SaveParts {
  file: File
  numberOfChunks: number
  chunkSize: number
  totalSize: number
  presignedUrls: string[]
}

const savePartsOnS3 = async ({ chunkSize, file, numberOfChunks, presignedUrls, totalSize }: SaveParts) => {
  const uploadPromises = []

  for (let i = 0; i < numberOfChunks; i++) {
    const start = i * chunkSize
    const end = Math.min(start + chunkSize, totalSize)
    const chunk = file.slice(start, end)
    const presignedUrl = presignedUrls[i]

    uploadPromises.push(
      axios.put(presignedUrl, chunk, {
        headers: {
          'Content-Type': file.type,
          'Access-Control-Expose-Headers': 'etag'
        }
      })
    )
  }

  const uploadResponses = await Promise.all(uploadPromises)

  return uploadResponses
}

export const useUploadToS3 = () => {
  const [multiUploading, setMultiuploading] = useState(false)
  // Multipart hooks
  const { getMultipartUploadId, loading: uploadIdLoading } = useGetMultipartUploadId()
  const { getPresignedUrls, loading: presignedUrlsLoading } = useGetPresignedUrls()
  const { completeMultipartUploadId, loading: finishing } = useCompleteMultipartUpload()

  const [uploading, setUploading] = useState(false)
  const [progress] = useState(0)
  const [error] = useState<string>()

  const uploadFile = async (file: File) => {
    if (file.size < 1000000000) { // 10mb
      const base64 = await convertBase64(file)
      setUploading(true)

      const response = await axios.post(`${BACKEND_URL}/uploadToS3`, { file: base64, filename: file.name })

      setUploading(false)

      return response.data
    } else {
      setMultiuploading(true)
      const multipartUploadId = await getMultipartUploadId(file.name)

      // get total size of the file
      const totalSize = file.size
      // set chunk size to 10MB
      const chunkSize = 10000000
      // calculate number of chunks
      const numberOfParts = Math.ceil(totalSize / chunkSize)

      const urls = await getPresignedUrls({
        filename: file.name,
        uploadId: multipartUploadId,
        numberOfParts
      })

      const parts: any = await savePartsOnS3({
        file,
        numberOfChunks: numberOfParts,
        chunkSize,
        totalSize: file.size,
        presignedUrls: urls
      })

      const finalResult = await completeMultipartUploadId({
        filename: file.name,
        uploadId: multipartUploadId,
        parts: parts.map((response: any, i: number) => ({
          etag: response.headers.etag as string,
          PartNumber: i + 1
        }))
      })

      setMultiuploading(false)

      return finalResult
    }
  }

  return {
    error,
    uploadFile,
    uploading: multiUploading || uploading || uploadIdLoading || presignedUrlsLoading || finishing,
    progress
  }
}
