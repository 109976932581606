import { Modal, Spin, Table } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { useGetBranches, useRemoveBranch } from '../../services/branch'
import { getColumns } from './constants'
import { Screen } from '../../components'
import { useCallback, useState } from 'react'
import { type IBranch } from '../../types/branch'
import { BranchModal } from './components/BranchModal'

const AdminBranches = () => {
  const { branches, loading, refetch } = useGetBranches()
  const { removeBranch, loading: removeLoading } = useRemoveBranch()

  const [modal, setModal] = useState(false)
  const [removeModal, setRemoveModal] = useState(false)
  const [selectedBranch, setSelectedBranch] = useState<IBranch | undefined>()

  const toggleModal = useCallback(() => { setModal((prev) => !prev) }, [])
  const toggleRemoveModal = useCallback(() => { setRemoveModal((prev) => !prev) }, [])

  const onClose = async () => {
    setSelectedBranch(undefined)
    setModal(false)
    setRemoveModal(false)
    await refetch()
  }

  const onCloseRemove = () => {
    setSelectedBranch(undefined)
    toggleRemoveModal()
  }

  const onEditBranch = (branch: IBranch) => {
    setSelectedBranch(branch)
    toggleModal()
  }

  const onRemoveModal = (branch: IBranch) => {
    setSelectedBranch(branch)
    toggleRemoveModal()
  }

  const handleRemove = async () => {
    if (selectedBranch?._id) {
      await removeBranch(selectedBranch?._id)
      await onClose()
    }
  }

  const columns = getColumns({
    onEdit: onEditBranch,
    onRemove: onRemoveModal
  })

  return (
    <Screen title='Ramas' id="branches" actions={[{ icon: <PlusOutlined />, onClick: toggleModal }]}>
        {loading && <Spin tip="Loading" size="large" />}
        {!loading && (
            <Table locale={{ emptyText: 'No hay ramas que mostrar' }} columns={columns} dataSource={branches} rowKey="_id" />
        )}
        <BranchModal
            selectedBranch={selectedBranch}
            open={modal}
            onClose={onClose}
            title={`${selectedBranch ? 'Actualizar' : 'Crear'} Rama`}
        />
        <Modal
            open={removeModal}
            onCancel={onCloseRemove}
            okButtonProps={{
              danger: true,
              loading: removeLoading
            }}
            onOk={handleRemove}
            okText="Eliminar rama"
            title="Eliminar rama"
        >
            {`Vas a eliminar la rama "${selectedBranch?.name}". ¿Estas seguro de que quieres continuar? No podrás acceder a la rama, ni sus temas, preguntas, etc...`}
        </Modal>
    </Screen>
  )
}

export { AdminBranches }
