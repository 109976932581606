import { gql } from '@apollo/client'

export const CREATE_FORUM_QUESTION = gql`
mutation createForumQuestion ($input: ForumQuestionInput!) {
    createForumQuestion(input: $input) {
      title
      content
      deleted
          branch {
        name
      }
    }
  }
`

export const CREATE_FORUM_ANSWER = gql`
mutation createForumAnswer ($input: ForumAnswerInput!) {
    createForumAnswer(input: $input) {
      content
      deleted
    }
  }
`

export const DELETE_FORUM_QUESTION = gql`
  mutation removeForumQuestion($input: String!) {
    removeForumQuestion(input: $input) {
      _id
    }
  }
`
