import * as React from 'react'

import { AdminBranches, DashboardPage, LoginPage, ProfilePage, ResultsPage, StartTestPage, StatsPage, SubjectsPage, TestPage, TeachersPage } from '../pages'
import { SavedQuestionsPage } from '../pages/SavedQuestionsPage'
import { AdminPage } from '../pages/AdminPage'
import { AdminTemaryPage } from '../pages/AdminTemaryPage'
import { AdminTestsPage } from '../pages/AdminTestsPage'
import { AdminSubjects } from '../pages/AdminSubjects'
import { AdminTopics } from '../pages/AdminTopics'
import { AdminQuestions } from '../pages/AdminQuestions'
import { SessionsPage } from '../pages/AdminSessions'
import { UsersPage } from '../pages/AdminUsers'
import { DocsViewer } from '../pages/SubjectsPage/DocsViewer'
import { AdminSuscriptions } from '../pages/AdminSuscriptions'
import { ForumPage } from '../pages/ForumPage'
import RestorePasswordScreen from '../pages/RestorePasswordScreen'
import { ImpugnatedQuestionsPage } from '../pages/ImpugnatedQuestionsPage'
import { OnlineSessionsPage } from '../pages/OnlineSessionsPage'

export const PUBLIC_ROUTES = {
  LOGIN: '*',
  RESET_PASSWORD: '/restore-password'
}

export const PROTECTED_ROUTES = {
  ADMIN: '/admin',
  ADMIN_BRANCHES: '/admin/branches',
  ADMIN_QUESTIONS: '/admin/questions',
  ADMIN_TESTS: '/admin/tests',
  ADMIN_TOPICS: '/admin/topics',
  ADMIN_SUBJECTS: '/admin/subjects',
  ADMIN_TEACHERS: '/admin/teachers',
  ADMIN_SESSIONS: '/admin/sessions',
  ADMIN_SUSCRIPTIONS: '/admin/suscriptions',
  ADMIN_TEMARY: '/admin/temary',
  DASHBOARD: '/',
  FORUM: '/forum',
  PROFILE: '/profile',
  RESULTS: '/results',
  ONLINE_SESSIONS: '/online-sessions',
  IMPUGNATED_QUESTIONS: '/impugnated-questions',
  SAVED_QUESTIONS: '/saved-questions',
  START_TEST: '/start-test',
  STATS: '/stats',
  SUBJECTS: '/subjects',
  TEST: '/test',
  USERS: '/users',
  PDFDOCS: '/doc/:fileName/:type/:title',
  NOTFOUND: '*'
}

export const ROUTES = {
  public: [
    { component: <LoginPage />, route: PUBLIC_ROUTES.LOGIN },
    { component: <RestorePasswordScreen />, route: PUBLIC_ROUTES.RESET_PASSWORD }
  ],
  protected: [
    { component: <DashboardPage />, route: PROTECTED_ROUTES.DASHBOARD },
    { component: <ProfilePage />, route: PROTECTED_ROUTES.PROFILE },
    { component: <ResultsPage />, route: PROTECTED_ROUTES.RESULTS },
    { component: <SavedQuestionsPage />, route: PROTECTED_ROUTES.SAVED_QUESTIONS },
    { component: <StartTestPage />, route: PROTECTED_ROUTES.START_TEST },
    { component: <StatsPage />, route: PROTECTED_ROUTES.STATS },
    { component: <SubjectsPage />, route: PROTECTED_ROUTES.SUBJECTS },
    { component: <TestPage />, route: PROTECTED_ROUTES.TEST },
    { component: <DocsViewer />, route: PROTECTED_ROUTES.PDFDOCS },
    { component: <ForumPage />, route: PROTECTED_ROUTES.FORUM },
    { component: <AdminTemaryPage />, route: PROTECTED_ROUTES.ADMIN_TEMARY },
    { component: <DashboardPage />, route: PROTECTED_ROUTES.NOTFOUND },
    { component: <OnlineSessionsPage />, route: PROTECTED_ROUTES.ONLINE_SESSIONS }
  ],
  admin: [
    { component: <AdminPage />, route: PROTECTED_ROUTES.ADMIN },
    { component: <SessionsPage />, route: PROTECTED_ROUTES.ADMIN_SESSIONS },
    { component: <AdminQuestions />, route: PROTECTED_ROUTES.ADMIN_QUESTIONS },
    { component: <AdminTopics />, route: PROTECTED_ROUTES.ADMIN_TOPICS },
    { component: <AdminTestsPage />, route: PROTECTED_ROUTES.ADMIN_TESTS },
    { component: <AdminSuscriptions />, route: PROTECTED_ROUTES.ADMIN_SUSCRIPTIONS },
    { component: <AdminBranches />, route: PROTECTED_ROUTES.ADMIN_BRANCHES },
    { component: <AdminSubjects />, route: PROTECTED_ROUTES.ADMIN_SUBJECTS },
    { component: <TeachersPage />, route: PROTECTED_ROUTES.ADMIN_TEACHERS },
    { component: <UsersPage />, route: PROTECTED_ROUTES.USERS },
    { component: <ImpugnatedQuestionsPage />, route: PROTECTED_ROUTES.IMPUGNATED_QUESTIONS }
  ]
}
