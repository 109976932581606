import { Modal } from 'antd'

interface Props {
  onContinue: () => void
  visible: boolean
  onCancel: () => void
  loading: boolean
}

const ClearDevices = ({ onContinue, visible, onCancel, loading }: Props) => (
    <Modal
        className='alloweance-modal'
        open={visible}
        onOk={onContinue}
        okText="Guardar"
        okButtonProps={{ loading }}
        onCancel={onCancel}
        title={'Reiniciar dispositivos'}
    >
        <p>Esto reiniciará los dispositivos permitidos al usuario.</p>
    </Modal>
)

export { ClearDevices }
