import { type FC } from 'react'
import './ForumAnswer.scss'
import { format } from 'date-fns'

interface Props {
  user: string
  content: string
  date: Date
}

const ForumAnswer: FC<Props> = ({ user, content, date }) => (
    <div className="forum-answer">
        <p><span>{`${user}:`}</span>{content}</p>
        <label>{format(new Date(date), 'HH:mm - dd MMM yyyy')}</label>
    </div>
)

export { ForumAnswer }
