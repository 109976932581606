import { Spin, Table } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { Screen } from '../../components'
import { getColumns } from './constants'
import { ManageUsers, RemoveUsers } from './components/modal/ManageUsers'
import { useState } from 'react'
import { type IUser } from '../../types/user'
import { useClearDevices, useGetActiveUsers } from '../../services/user'
import { useModal } from '../../hooks/useModal'
import { ManageAlloweance } from './components/ManageAlloweance'
import { ClearDevices } from './components/ClearDevices'
import { ManageTemary } from './components/ManageTemary/ManageTemary'

const UsersPage = () => {
  const { loading, users, refetch } = useGetActiveUsers()
  const { loading: clearing, clearDevices } = useClearDevices()

  const [selectedUser, setSelectedUser] = useState<IUser | undefined>(undefined)

  const { visible: clearingDevice, toggleModal: toggleClearDevices } = useModal()
  const { visible: openManageUser, toggleModal: toogleManageUsers } = useModal()
  const { visible: openRemoveUser, toggleModal: toogleRemoveUsers } = useModal()
  const { visible: openAlloweanceUser, toggleModal: toggleAlloweance } = useModal()
  const { visible: openManageContent, toggleModal: toggleContent } = useModal()

  const closeManageUser = async () => {
    setSelectedUser(undefined)
    toogleManageUsers()
    await refetch()
  }

  const closeRemoveUser = async () => {
    toogleRemoveUsers()
    await refetch()
  }

  const onEdit = (user: IUser) => {
    toogleManageUsers()
    setSelectedUser(user)
  }

  const onClear = (user: IUser) => {
    toggleClearDevices()
    setSelectedUser(user)
  }

  const handleClear = async () => {
    await clearDevices(selectedUser!)
    toggleClearDevices()
    setSelectedUser(undefined)
  }

  const onRemove = (user: IUser) => {
    toogleRemoveUsers()
    setSelectedUser(user)
  }

  const onManageAlloweance = (user: IUser) => {
    toggleAlloweance()
    setSelectedUser(user)
  }

  const onManageTemary = (user: IUser) => {
    toggleContent()
    setSelectedUser(user)
  }

  const columns = getColumns({ onClear, onEdit, onRemove, onManageAlloweance, onManageTemary })

  return (
    <Screen id="Users" title="Usuarios" actions={[{ icon: <PlusOutlined />, onClick: toogleManageUsers }]}>
      {loading && <Spin tip="Loading" size="large" />}
      {!loading && (
        <Table locale={{ emptyText: 'No hay usuarios que mostrar' }} columns={columns} dataSource={users} rowKey="_id" />
      )}
      <ManageUsers isOpen={openManageUser} user={selectedUser} onCancel={closeManageUser} title={`${selectedUser ? 'Actualizar' : 'Nuevo'} Usuario`} isCreating={!selectedUser}/>
      <RemoveUsers isOpen={openRemoveUser} user={selectedUser} onCancel={closeRemoveUser}/>
      <ManageAlloweance user={selectedUser} visible={openAlloweanceUser} toggleModal={toggleAlloweance} />
      <ManageTemary user={selectedUser} visible={openManageContent} toggleModal={toggleContent} />
      <ClearDevices loading={clearing} onCancel={() => {
        toggleClearDevices()
        setSelectedUser(undefined)
      }} visible={clearingDevice} onContinue={handleClear}/>
    </Screen>
  )
}

export { UsersPage }
