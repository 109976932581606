import { IconBook, IconChecklist, IconEdit, IconRefresh, IconTrash } from '@tabler/icons-react'
import { UserOutlined, SearchOutlined } from '@ant-design/icons'
import { type IUser } from '../../types/user'
import { type Role } from '../../types/role'
import { Avatar } from 'antd'
import { DropDownFilterTitle } from '../AdminQuestions/components/DropDownFilterTitle'

interface Params {
  onEdit: (user: IUser) => void
  onRemove: (user: IUser) => void
  onClear: (user: IUser) => void
  onManageAlloweance: (user: IUser) => void
  onManageTemary: (user: IUser) => void
}

export const getColumns = ({ onClear, onEdit, onRemove, onManageAlloweance, onManageTemary }: Params) => [
  {
    title: '',
    dataIndex: '',
    key: 'avatar',
    render: (user: IUser) => {
      return user.avatar ? <Avatar src={user.avatar} /> : <Avatar icon={<UserOutlined />} />
    }
  },
  {
    title: 'DNI',
    dataIndex: 'DNI',
    key: 'DNI'
  },
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    filterDropdown: DropDownFilterTitle,
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (title: string | number | boolean, user: IUser) => {
      const regex = new RegExp(`.*${title}.*`, 'ig')
      return regex.test(user.name!)
    }
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    filterDropdown: DropDownFilterTitle,
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (title: string | number | boolean, user: IUser) => {
      const regex = new RegExp(`.*${title}.*`, 'ig')
      return regex.test(user.email!)
    }
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone'
  },
  {
    title: 'Calle',
    dataIndex: 'street',
    key: 'street'
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    render: (role: Role) => (role.name)
  },
  {
    title: '',
    dataIndex: '',
    key: 'x',
    width: 50,
    render: (user: IUser) => (
      <button className='table-action' onClick={() => { onClear(user) }}>
        <IconRefresh size={15} color="white"/>
      </button>
    )
  },
  {
    title: '',
    dataIndex: '',
    key: 'x',
    width: 50,
    render: (user: IUser) => (
      <button className='table-action' onClick={() => { onEdit(user) }}>
        <IconEdit size={15} color="white"/>
      </button>
    )
  },
  {
    title: '',
    dataIndex: '',
    key: 'x',
    width: 50,
    render: (user: IUser) => (
      <button className='table-action' onClick={() => { onManageAlloweance(user) }}>
        <IconChecklist size={15} color="white"/>
      </button>
    )
  },
  {
    title: '',
    dataIndex: '',
    key: 'x',
    width: 50,
    render: (user: IUser) => (
      <button className='table-action' onClick={() => { onManageTemary(user) }}>
        <IconBook size={15} color="white"/>
      </button>
    )
  },
  {
    title: '',
    dataIndex: '',
    key: 'x',
    width: 50,
    render: (user: IUser) => (
      <button className='table-action' onClick={() => { onRemove(user) }}>
        <IconTrash size={15} color="white"/>
      </button>
    )
  }
]
