import { gql } from '@apollo/client'

export const CREATE_QUESTION = gql`
mutation createQuestion($input: QuestionInput!) {
    createQuestion(input: $input) {
      title
      image
      active
      answers
      info
      pastexam
      branch {
        name
      }
      subject {
        name
      }
      topic {
        name
      }
      correctAnswer
    }
  }
`

export const CREATE_QUESTIONS = gql`
mutation createQuestions($input: [QuestionInput!]!) {
    createQuestions(input: $input) {
      title
      image
      active
      answers
      info
      pastexam
      correctAnswer
    }
  }
`

export const UPDATE_QUESTION = gql`
mutation updateQuestion($input: UpdateQuestionInput!) {
    updateQuestion(input: $input) {
      _id
      title
      image
      active
      answers
      info
      pastexam
      correctAnswer
    }
  }
`

export const REMOVE_QUESTION = gql`
mutation removeQuestion($input: String!) {
  removeQuestion(input: $input) {
      _id
      title
    }
  }
`
