import { gql } from '@apollo/client'

export const GET_USER_ALLOWEANCE = gql`
    query getUserAlloweance($input: String!) {
        getUserAlloweance(input: $input) {
            _id
            tests {
                _id
            }
            subjects {
                _id
            }
            topics {
                _id
            }
            content {
                _id
            }
            contentTopics {
                _id
            }
        }
    }
`
