import { Modal } from 'antd'
import { useState, type FC, useEffect, useMemo } from 'react'
import { Input, Select } from '../../../../components'
import { parseSelect } from '../../../../utils/parseSelect'
import { useCreateUserFare, useExtendUserFare } from '../../../../services/userFare'
import { useGetActiveUsers } from '../../../../services/user'
import { type IUserFare } from '../../../../types/userFare'

interface Props {
  onClose: () => void
  selectedSuscription?: IUserFare
  open: boolean
  refetch: () => void
}

const UserFareModal: FC<Props> = ({ open, onClose, refetch, selectedSuscription }) => {
  const { createUserFare, loading: createLoading } = useCreateUserFare()
  const { extendUserFare, loading: extendLoading } = useExtendUserFare()
  const { users, loading: loadingUsers } = useGetActiveUsers()

  const [editedUser, setEditedUser] = useState<string>('')
  const [editedDate, setEditedDate] = useState<string>('')

  useEffect(() => {
    if (selectedSuscription?._id) {
      setEditedUser(selectedSuscription.user._id!)
    }
  }, [selectedSuscription])

  const handleClose = () => {
    setEditedUser('')
    refetch()
    onClose()
  }

  const handleSave = async () => {
    if (selectedSuscription) {
      await extendUserFare({
        userFare: selectedSuscription._id,
        date: new Date(editedDate)
      })
    } else {
      await createUserFare({
        user: editedUser,
        endDate: new Date(editedDate)
      })
    }
    handleClose()
  }

  const parsedUsers = useMemo(() => parseSelect({
    array: users ?? [],
    labelKey: 'name',
    valueKey: '_id'
  }), [users])

  return (
    <Modal
        open={open}
        onCancel={handleClose}
        onOk={handleSave}
        okButtonProps={{
          disabled: !editedUser || !editedDate,
          loading: createLoading || extendLoading
        }}
        okText="Guardar"
        title={selectedSuscription ? 'Editar suscripción' : 'Crear suscripción'}
    >
        <Select
            disabled={!!selectedSuscription}
            loading={loadingUsers}
            placeholder="Usuario"
            onChange={setEditedUser}
            options={parsedUsers}
            value={editedUser}
        />
        <Input
            placeholder='Fecha fin'
            onChange={(e) => setEditedDate(e.target.value)}
            type='date'
            value={editedDate}
        />

    </Modal>
  )
}

export { UserFareModal }
