import { useUser } from './useUser'
import { type IUser } from '../types/user'
import { useBranch } from './useBranch'
import { BRANCH_KEY } from '../constants/storageKeys'

export const useAuth = () => {
  const { user, addUser, removeUser } = useUser()
  const { setBranch } = useBranch()

  const login = (user: IUser) => {
    if (user) {
      addUser(user)
    }
  }

  const logout = () => {
    removeUser()
    setBranch(null)
    localStorage.removeItem(BRANCH_KEY)
  }

  const setUser = (user: IUser | null) => {
    if (!user) {
      logout()
    } else {
      login(user)
    }
  }

  return { user, login, logout, setUser }
}
