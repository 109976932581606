import { Screen } from '../../components'

import './LoadingScreen.scss'

import Logo from '../../assets/cf-logo.png'

const UnauthorizedScreen = () => (
    <Screen id="loading" dark sidebar={false}>
        <div className='content'>
            <img alt="logo" src={Logo} />
            <p>Este dispositivo no está autorizado al uso de la plataforma.</p>
        </div>
    </Screen>
)

export { UnauthorizedScreen }
