import { useMutation, useQuery } from '@apollo/client'
import { GET_BRANCHES } from './queries'
import { type IBranch } from '../../types/branch'
import { CREATE_BRANCH, REMOVE_BRANCH, UPDATE_BRANCH } from './mutations'

interface GetBranchesResponse {
  getAllActiveBranches: IBranch[]
}
export const useGetBranches = () => {
  const { data, loading, refetch } = useQuery<GetBranchesResponse>(GET_BRANCHES)

  return {
    branches: data?.getAllActiveBranches,
    loading,
    refetch
  }
}

export const useCreateBranch = () => {
  const [action, { loading }] = useMutation(CREATE_BRANCH)

  const createBranch = async ({ name, image, penalty, testtime }: IBranch) => {
    await action({
      variables: {
        input: {
          name,
          image,
          penalty,
          testtime,
          active: true,
          executedBy: 'changeForUser'
        }
      }
    })
  }

  return { createBranch, loading }
}

export const useUpdateBranch = () => {
  const [action, { loading }] = useMutation(UPDATE_BRANCH)

  const updateBranch = async ({ _id, name, image, penalty, testtime }: IBranch) => {
    await action({
      variables: {
        input: {
          _id,
          name,
          image,
          penalty,
          testtime,
          active: true,
          executedBy: 'changeForUser'
        }
      }
    })
  }

  return { updateBranch, loading }
}

export const useRemoveBranch = () => {
  const [action, { loading }] = useMutation(REMOVE_BRANCH)

  const removeBranch = async (id: string) => {
    await action({
      variables: {
        input: id
      }
    })
  }

  return { removeBranch, loading }
}
