import { IconEdit, IconTrash } from '@tabler/icons-react'
import { type ITopic } from '../../types/topic'

interface Params {
  onEdit: (topic: ITopic) => void
  onRemove: (topic: ITopic) => void
  info: any
}

export const getColumns = ({ onEdit, onRemove, info }: Params) => [
  {
    title: '',
    dataIndex: 'color',
    key: 'color',
    render: (color: string) => <div style={{ background: color, width: 20, height: 20, borderRadius: '100%' }}/>,
    width: 80
  },
  {
    title: 'Nombre',
    dataIndex: 'name',
    render: (name: string) => <p>{`${name} (${info?.[name]?.length || 0} preguntas)`}</p>,
    key: 'name'
  },
  {
    title: 'Rama',
    dataIndex: ['branch', 'name'],
    key: 'branch_name'
  },
  {
    title: 'Bloque',
    dataIndex: ['subject', 'name'],
    key: 'subject_name'
  },
  {
    title: '',
    dataIndex: '',
    key: 'x',
    width: 50,
    render: (topic: ITopic) => (
      <button className='table-action' onClick={() => { onEdit(topic) }}>
        <IconEdit size={15} color="white" />
      </button>
    )
  },
  {
    title: '',
    dataIndex: '',
    key: 'x',
    render: (topic: ITopic) => (
      <button className='table-action' onClick={() => { onRemove(topic) }}>
        <IconTrash size={15} color="white" />
      </button>
    )
  }
]
