export enum Record {
  cloud = 'cloud',
  local = 'local',
  none = 'none'
}

export interface Meeting {
  _id?: string
  id?: number
  host_email?: string
  created_at?: Date
  duration: number
  start_url?: string
  join_url?: string
  record_type: string
}
