import { useGetFailedQuestions } from '../../../../services/userQuestion'
import { QuestionBox } from '../QuestionBox'
import { EmptyCase } from '../../../../components'
import { Loader } from '../../../../components/Loader/Loader'

const FailedQuestions = () => {
  const { questions, loading } = useGetFailedQuestions()

  if (loading) {
    return (
      <Loader size={200}/>
    )
  }
  return (
    <div style={{ width: '100%' }}>
        {questions && questions.length > 0 && questions?.map((question) =>
            <QuestionBox key={question._id} question={question.question} comment='' />
        )}
        {(!questions || questions.length === 0) && (
          <EmptyCase text='No hay preguntas que mostrar' />
        )}
    </div>
  )
}

export { FailedQuestions }
