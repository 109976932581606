import { gql } from '@apollo/client'

export const GET_BRANCH_FORUM_QUESTIONS = gql`
query getAllForumQuestionsByBranch($input: String!) {
    getAllForumQuestionsByBranch(input: $input) {
                _id
        title
        content
        user {
            _id
            name
            avatar
        }
        answers {
            _id
            content
            user {
                name
            }
            date
        }
    }
}
`
