import { gql } from '@apollo/client'

export const LOGIN = gql`
    mutation login($input: LoginParams!) {
        login(input: $input) {
            _id
            active
            name
            DNI
            phone
            avatar
            street
            authToken
            email
            role {
                name
            }
            temarySection
            testSection
            devices
            devices
        }
    }
`

export const AUTOLOGIN = gql`
mutation autologin($input: AutologinParams!) {
  autologin(input: $input) {
      _id
      active
      name
      DNI
      phone
      avatar
      street
      authToken
      email
      role {
          name
      }
      temarySection
      testSection
      devices
  }
}
`

export const UPDATEUSER = gql`
mutation updateUser($input: UpdateUserInput!){
    updateUser(input:$input){
      email
      DNI
      name
      phone
      avatar
    }
  }
`
export const UPDATEUSERPROFILE = gql`
mutation updateUserProfile($input: UpdateUserInputProfile!){
  updateUserProfile(input:$input){
      email
      DNI
      name
      phone
      avatar
      street
    }
  }
`

export const CREATEUSER = gql`
mutation createUser($input: UserInput!){
    createUser(input:$input){
      _id
      DNI
      active
      deleted
      name
      email
    }
  }
`

export const REMOVEUSER = gql`
mutation removeUser($input: String!){
    removeUser(input:$input){
      _id
      name
      DNI
      email
      password
      active
      deleted
    }
  }
`

export const RESET_PASSWORD = gql`
mutation resetPassword ($input: ResetPassword!) {
    resetPassword(input: $input) {
      _id
      name
      email
        
  }
}`

export const RESOLVE_TOKEN = gql`
    mutation resolveToken ($input: String!) {
        resolveToken(input: $input) {
            _id
            name
            email
        }
    }
`

export const FORGOT_PASSWORD = gql`
mutation forgotPasswordRequestv2 ($input: ResetPasswordV2!) {
    forgotPasswordRequestv2(input: $input)
  }
`

export const REGISTER_DEVICE = gql`
  mutation registerDevice($input: RegisterDeviceInput!) {
    registerDevice(input: $input) {
      _id
    }
  }
`

export const HIDE_QUESTION = gql`
  mutation addHiddenQuestion($input: HideQuestionInput!) {
    addHiddenQuestion(input: $input)
  }
`

export const CLEAR_DEVICES = gql`
  mutation clearUserDevices($input: String!) {
    clearUserDevices(input: $input) {
      _id
    }
  }
`
