import { IconEdit, IconTrash } from '@tabler/icons-react'
import { type ITest } from '../../types/test'
import { type IQuestion } from '../../types/question'
import { type ColumnFilterItem } from 'antd/es/table/interface'

interface Params {
  onEdit: (subject: ITest) => void
  onRemove: (subject: ITest) => void
  columnFilter?: Filters

}

interface Filters {
  teacher: ColumnFilterItem[]
}

export const getColumns = ({ onEdit, onRemove, columnFilter }: Params) => [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Profesor',
    dataIndex: ['teacher', 'name'],
    key: 'teacher',
    filters: columnFilter?.teacher,
    filterSearch: true,
    onFilter: (value: any, test: ITest) => {
      return value === (test as ITest).teacher._id
    }
  },
  {
    title: 'Rama',
    dataIndex: ['branch', 'name'],
    key: 'branch_name'
  },
  {
    title: 'Bloque',
    dataIndex: ['subject', 'name'],
    key: 'subject_name'
  },
  {
    title: 'Tema',
    dataIndex: ['topic', 'name'],
    key: 'topic_name'
  },
  {
    title: 'Preguntas',
    key: 'questions',
    dataIndex: 'questions',
    render: (questions: IQuestion[]) => (
      <p>{questions.length}</p>
    )
  },
  {
    title: '',
    dataIndex: '',
    key: 'x',
    width: 50,
    render: (test: ITest) => (
      <button className='table-action' onClick={() => { onEdit(test) }}>
        <IconEdit size={15} color='white'/>
      </button>
    )
  },
  {
    title: '',
    dataIndex: '',
    key: 'x',
    width: 50,
    render: (test: ITest) => (
      <button className='table-action' onClick={() => { onRemove(test) }}>
        <IconTrash size={15} color='white'/>
      </button>
    )
  }
]
