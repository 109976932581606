import { type FC, useMemo } from 'react'
import { Input, Select } from '../../../../../../components'
import { useGetSubjectsByBranch } from '../../../../../../services/subject'
import { parseSelect } from '../../../../../../utils/parseSelect'
import { useGetTopicsBySubject } from '../../../../../../services/topic'

interface Props {
  selectedSubject?: string
  setSelectedSubject?: (val: string) => void
  selectedTopic?: string
  setSelectedTopic?: (val: string) => void
  numberOfQuestions: number
  setNumberOfQuestions: (val: number) => void
}

const TopicTest: FC<Props> = ({ numberOfQuestions, setNumberOfQuestions, selectedSubject, selectedTopic, setSelectedSubject, setSelectedTopic }) => {
  const { subjects, loading } = useGetSubjectsByBranch()
  const { topics, loading: loadingTopics } = useGetTopicsBySubject(selectedSubject)

  const parsedTopics = useMemo(() => parseSelect({
    array: topics ?? [],
    labelKey: 'name',
    valueKey: '_id'
  }), [topics])

  const parsedSubjects = useMemo(() => parseSelect({
    array: subjects ?? [],
    labelKey: 'name',
    valueKey: '_id'
  }), [subjects])

  return (
    <div>
         {!loading && subjects && subjects.length > 0 && (
          <Select
            placeholder="Bloque"
            onChange={setSelectedSubject}
            options={parsedSubjects}
            value={selectedSubject}
          />
         )}
         {!loadingTopics && topics && topics.length > 0 && (
          <Select
            placeholder="Temas"
            onChange={setSelectedTopic}
            options={parsedTopics}
            value={selectedTopic}
          />
         )}
        <Input
            placeholder='Número de preguntas'
            value={numberOfQuestions}
            onChange={(e) => {
              setNumberOfQuestions(Number(e.target.value))
            }}
            type="number"
          />
    </div>
  )
}

export { TopicTest }
