import { Screen } from '../../components'
import { useGetAllBranchForumQuestions } from '../../services/forumQuestion'
import { ForumQuestion } from './components/ForumQuestion'
import { PlusOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { ForumQuestionModal } from './components/ForumQuestionModal'
import { LoadingScreen } from '../LoadingScreen'

const ForumPage = () => {
  const { questions, loading, refetch } = useGetAllBranchForumQuestions()

  const [modal, setModal] = useState(false)

  const toggleModal = () => {
    setModal((prev) => !prev)
  }

  if (loading) {
    return <LoadingScreen />
  }
  return (
    <Screen id="forum" title="Foro" actions={[{ icon: <PlusOutlined />, onClick: toggleModal }]}>
        {questions?.map((question) => (
            <ForumQuestion key={question._id} question={question} refetch={refetch} />
        ))}
        <ForumQuestionModal open={modal} onClose={toggleModal} refetch={refetch} />
    </Screen>
  )
}

export { ForumPage }
