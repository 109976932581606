import { useMutation, useQuery } from '@apollo/client'
import { type ISubject } from '../../types/subject'
import { GET_SUBJECTS, GET_SUBJECTS_BY_BRANCH } from './queries'
import { CREATE_SUBJECT, REMOVE_SUBJECT, UPDATE_SUBJECT } from './mutations'
import { useContext } from 'react'
import { BranchContext } from '../../contexts/BranchContext'
import { useBranch } from '../../hooks/useBranch'
import { useUser } from '../../hooks/useUser'
import { useGetUserAlloweance } from '../userAlloweance'

interface GetSubjectsResponse {
  getAllActiveSubjects: ISubject[]
}
export const useGetSubjects = () => {
  const { data, loading, refetch } = useQuery<GetSubjectsResponse>(GET_SUBJECTS)

  return {
    subjects: data?.getAllActiveSubjects,
    loading,
    refetch
  }
}

export const useGetSubjectsByBranch = (selectedBranch?: string) => {
  const { isAdmin, user } = useUser()
  const { branch } = useContext(BranchContext)
  const { alloweance, loading: loadingAlloweance } = useGetUserAlloweance(user?._id)

  const { data, loading, refetch } = useQuery(GET_SUBJECTS_BY_BRANCH, {
    variables: {
      input: selectedBranch || branch?._id
    },
    skip: !(branch || selectedBranch)
  })

  if (!isAdmin) {
    const subjects = data?.getSubjectsByBranch as ISubject[]
    const allowedSubjectIds = (alloweance?.subjects as any)?.map((s: any) => s._id)
    const filteredSubjects = (subjects || []).filter(({ _id }) => allowedSubjectIds?.includes(_id!))

    return {
      subjects: filteredSubjects,
      loading: loadingAlloweance || loading,
      refetch
    }
  }

  return {
    subjects: data?.getSubjectsByBranch as ISubject[],
    loading: loadingAlloweance || loading,
    refetch
  }
}

export const useCreateSubject = () => {
  const { branch } = useBranch()
  const [action, { loading }] = useMutation(CREATE_SUBJECT)

  const createSubject = async ({ color, name, image }: ISubject) => {
    await action({
      variables: {
        input: {
          active: true,
          branch: branch?._id,
          color,
          executedBy: 'changeForUser',
          image,
          name
        }
      }
    })
  }

  return { createSubject, loading }
}

export const useUpdateSubject = () => {
  const { branch } = useBranch()
  const [action, { loading }] = useMutation(UPDATE_SUBJECT)

  const updateSubject = async ({ _id, color, name, image }: ISubject) => {
    await action({
      variables: {
        input: {
          _id,
          active: true,
          branch: branch?._id,
          color,
          executedBy: 'changeForUser',
          image,
          name
        }
      }
    })
  }

  return { updateSubject, loading }
}

export const useRemoveSubject = () => {
  const [action, { loading }] = useMutation(REMOVE_SUBJECT)

  const removeSubject = async (id: string) => {
    await action({
      variables: {
        input: id
      }
    })
  }

  return { removeSubject, loading }
}
