import { gql } from '@apollo/client'

export const GET_USER_FARES = gql`
query getAllUserFaresByUser($input: String!){
    getAllUserFaresByUser(input:$input) {
        _id
        user {
            name
            email
        }
        branch {
            _id
            name
            image
            penalty
        }
        endDate
        startDate
    }
  }
`

export const GET_ALL_USER_FARES = gql`
query getAllUserFares {
    adminGetAllActiveUserFares {
        _id
        user {
            _id
            name
            email
        }
        branch {
            _id
            name
            image
        }
        endDate
        startDate
    }
  }
`
