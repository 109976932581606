import { gql } from '@apollo/client'

export const GET_BY_RATING = gql`
    query getRatedQuestionByRating ($input: GetByRatingInput!) {
        getRatedQuestionByRating(input: $input) {
            question {
                _id
                title
                image
                answers
                correctAnswer
                info
                topic {
                    name
                    _id
                    color
                }
                subject {
                    name
                    _id
                    color
                }
                branch {
                    name
                    _id
                }
            }
        }
    }
`
