import { gql } from '@apollo/client'

export const GET_SUBJECTS = gql`
query getAllActiveSubjects {
    getAllActiveSubjects {
        _id
        active
        branch {
            _id
            active
            image
            name
        } 
        color
        image
        name
    }
}
`

export const GET_SUBJECTS_BY_BRANCH = gql`
query getSubjectsByBranch($input:String!) {
    getSubjectsByBranch(input: $input) {
        _id
          active
          branch {
              _id
              active
              image
              name
          } 
          color
          image
          name
    }
  }
`
