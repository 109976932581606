import { Button, Checkbox, Input } from 'antd'

import { Screen } from '../../components'
import { useState, type FC, useCallback, useEffect, useLayoutEffect } from 'react'

import './LoginPage.scss'
import { useLogin, useAutoLogin } from '../../services/user'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { AUTH_KEY } from '../../constants/storageKeys'

import Logo from '../../assets/cf-logo.png'
import { LoadingScreen } from '../LoadingScreen'

const LoginPage: FC = () => {
  const { doLogin, loading, error } = useLogin()
  const { getItem } = useLocalStorage()
  const { doAutologin, loading: autologinLoading } = useAutoLogin()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [formError, setFormError] = useState('')
  const [remember, setRemember] = useState(false)

  const [forgotPassword, setForgotPassword] = useState(false)

  useLayoutEffect(() => {
    const authToken = getItem(AUTH_KEY)
    if (authToken) {
      doAutologin(authToken).catch(err => console.log(err))
    }
  }, [])

  useEffect(() => {
    if (error?.message) {
      setFormError(error.message)
    }
  }, [error])

  const handleChange = (key: string, value: string) => {
    setFormError('')
    if (key === 'email') {
      setEmail(value)
    } else if (key === 'password') {
      setPassword(value)
    }
  }

  const saveCredentials = useCallback(() => {
    localStorage.setItem('factory-email', email)
    localStorage.setItem('factory-password', password)
  }, [email, password])

  const handleLogin = useCallback(() => {
    if (remember) {
      saveCredentials()
    }

    doLogin({
      email,
      password
    }).catch((error) => { console.log(error) })
  }, [email, password])

  const handleForgotPassword = () => {
    console.log('exec forgot password')
  }

  useEffect(() => {
    const getCredentials = () => {
      const emailStored = localStorage.getItem('factory-email')
      const passwordStored = localStorage.getItem('factory-password')

      if (emailStored || passwordStored) {
        setRemember(true)
        setEmail(emailStored as string)
        setPassword(passwordStored as string)
      }
    }

    getCredentials()
  }, [])

  if (autologinLoading) {
    return <LoadingScreen />
  }

  return (
    <Screen id="login-page" sidebar={false} dark>
      <main>
        <img src={Logo} style={{ marginBottom: 30 }} />
        <Input
          value={email}
          onChange={(e) => handleChange('email', e.target.value)}
          placeholder="Email"
          className='login-input'
        />
        {!forgotPassword && (
          <>
            <Input.Password
              placeholder="Contraseña"
              className='login-input'
              value={password}
              onChange={(e) => handleChange('password', e.target.value)}
            />
            <div className='forgot-remember'>
              <Checkbox checked={remember} className='remember' onChange={(e) => setRemember(e.target.checked)}>
                Recordar
              </Checkbox>
              <Button type="text" className='forgot' onClick={() => setForgotPassword(true)}>
                Olvidaste la contraseña?
              </Button>
            </div>
          </>
        )}

        <Button type="primary" className='login-button' onClick={forgotPassword ? handleForgotPassword : handleLogin} loading={loading}>
          {forgotPassword ? 'Recuperar contraseña' : 'Acceder'}
        </Button>
        {forgotPassword && (
          <Button style={{ marginTop: 10 }} type="text" className='forgot' onClick={() => setForgotPassword(false)}>
            Volver al login
          </Button>
        )}
        {formError && <p className="error-message">Credenciales incorrectas</p>}
      </main>
    </Screen>
  )
}

export { LoginPage }
