import { type DescriptionsProps, Avatar } from 'antd'
import { useUploadToS3 } from '../../hooks/useUploadToS3'
import { ProfileEdit } from './ProfileEdit'
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import { type IUser } from '../../types/user'
import { type Role } from '../../types/role'

interface Params {
  userEdit: IUser
  isEditing: boolean
  setField: (key: string, value: string) => void
}

export const getDescriptionColumns = ({
  userEdit,
  isEditing,
  setField
}: Params): DescriptionsProps['items'] => {
  const { uploadFile } = useUploadToS3()

  return [
    {
      key: '0',
      label: 'Avatar',
      children: (
        <>
          {isEditing
            ? (
              <input
                type='file'
                accept='.jpg,.png,.jpeg'
                className='ant-btn css-dev-only-do-not-override-1vr7spz ant-btn-default input-file'
                multiple={false}
                onChange={async ({ target }) => {
                  const files = target?.files
                  if (files?.length) {
                    const url = await uploadFile(files[0])
                    setField('avatar', url!)
                  }
                }}
              />
              )
            : (
              <Avatar src={userEdit.avatar} shape='square' />
              )}
        </>
      )
    },
    {
      key: '1',
      label: 'Activo',
      children: <p>{userEdit?.active ? <CheckCircleTwoTone /> : <CloseCircleTwoTone />}</p>
    },
    {
      key: '2',
      label: 'Nombre',
      children: <ProfileEdit fieldKey='name' isEditing={isEditing} setField={setField} value={userEdit.name ?? ''} >{userEdit?.name ?? ''}</ProfileEdit>
    },
    {
      key: '3',
      label: 'DNI',
      children: <ProfileEdit fieldKey='DNI' isEditing={isEditing} setField={setField} value={userEdit.DNI ?? ''}>{userEdit?.DNI ?? ''}</ProfileEdit>
    },
    {
      key: '4',
      label: 'Teléfono',
      children: <ProfileEdit fieldKey='phone' isEditing={isEditing} setField={setField} value={userEdit.phone ?? ''}>{userEdit?.phone ?? ''}</ProfileEdit>
    },
    {
      key: '5',
      label: 'Email',
      children: <p>{userEdit?.email}</p>
    },
    {
      key: '6',
      label: 'Calle',
      children: <ProfileEdit fieldKey='street' isEditing={isEditing} setField={setField} value={userEdit.street ?? ''}>{userEdit?.street ?? ''}</ProfileEdit>
    },
    {
      key: '7',
      label: 'Role',
      children: <p>{(userEdit?.role as Role).name}</p>
    }
  ]
}
