import { type FC, useState } from 'react'

import '../../ManageTemary.scss'
import { IconMinus, IconPlus } from '@tabler/icons-react'
import { type IContent } from '../../../../../../types/content'
import Checkbox from 'antd/es/checkbox/Checkbox'
import { AlloweanceContent } from '../AlloweanceContent'

interface Props {
  name: string
  content: IContent[]
  toggleContent: (id: string) => void
  toggleTopic: (id: string) => void
  editedAlloweance?: string[]
  editedAllowedTopics?: string[]
  setEditedAlloweance: (val: string[]) => void
}

export const AlloweanceTopics: FC<Props> = ({ setEditedAlloweance, editedAllowedTopics, editedAlloweance, toggleContent, toggleTopic, name, content }) => {
  const [open, setOpen] = useState(false)

  const toggleOpen = () => {
    setOpen((prev) => !prev)
  }

  const [topicName, topicId] = name?.split('***')

  const isTopicAllowed = editedAllowedTopics?.includes(topicId)

  const onSelectAll = () => {
    const contentIDs = content.map((c) => c._id)
    const newAlloweance = [...(editedAlloweance ?? [])].filter((cont) => !contentIDs.includes(cont!))

    setEditedAlloweance(newAlloweance)
  }

  return (
    <>
        <div className='button-line'>
            <div className='topic'>
                <Checkbox onChange={() => toggleTopic(topicId)} checked={isTopicAllowed} style={{ display: 'flex' }}>
                    <p>{topicName}</p>
                </Checkbox>
                <button className='select-all-content' onClick={onSelectAll}>Seleccionar todo</button>
            </div>
            <button className='style-button' onClick={toggleOpen}>
                {open
                  ? (
                        <IconMinus color='white' className='chevron'/>
                    )
                  : (
                        <IconPlus color='white' className='chevron'/>
                    )
                }
            </button>
        </div>
        {open && (
          content?.map((el) => (
                <AlloweanceContent
                  editedAlloweance={editedAlloweance}
                  onToggle={toggleContent}
                  key={el._id}
                  name={el.name}
                  _id={el._id!}
                />
          ))
        )}
    </>
  )
}
