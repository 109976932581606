import { useMutation, useQuery } from '@apollo/client'
import { GET_ALL_USER_FARES, GET_USER_FARES } from './queries'
import { useUser } from '../../hooks/useUser'
import { type IUserFare } from '../../types/userFare'
import { CREATE_USER_FARE, EXTEND_USER_FARE, REMOVE_USER_FARE } from './mutations'
import { useBranch } from '../../hooks/useBranch'

export const useGetUserFares = () => {
  const { user } = useUser()
  const { data, loading } = useQuery(GET_USER_FARES, {
    variables: {
      input: user?._id
    }
  })

  return { userFares: (data?.getAllUserFaresByUser || []) as IUserFare[], loading }
}

interface INewUserFare {
  user: string
  endDate: Date
}

export const useGetAllUserFares = () => {
  const { branch } = useBranch()
  const { data, loading, refetch } = useQuery(GET_ALL_USER_FARES)

  const filtered = ((data?.adminGetAllActiveUserFares || []) as IUserFare[]).filter(({ branch: ufBranch }) => ufBranch._id === branch?._id)

  return { refetch, userFares: filtered as IUserFare[], loading }
}

export const useCreateUserFare = () => {
  const { branch } = useBranch()
  const [action, { loading }] = useMutation(CREATE_USER_FARE)

  const createUserFare = async (input: INewUserFare) => {
    const userFare = await action({
      variables: {
        input: {
          ...input,
          branch: branch?._id
        }
      }
    })

    return userFare
  }

  return { createUserFare, loading }
}

export const useRemoveUserFare = () => {
  const [action, { loading }] = useMutation(REMOVE_USER_FARE)

  const removeUserFare = async (input: string) => {
    const userFare = await action({
      variables: {
        input
      }
    })

    return userFare
  }

  return { removeUserFare, loading }
}

interface IExtendUserFare {
  userFare: string
  date: Date
}

export const useExtendUserFare = () => {
  const [action, { loading }] = useMutation(EXTEND_USER_FARE)

  const extendUserFare = async (input: IExtendUserFare) => {
    const userFare = await action({
      variables: {
        input
      }
    })

    return userFare
  }

  return { extendUserFare, loading }
}
