import { Input, Screen } from '../../components'

import './DeviceRegisterScreen.scss'

import Logo from '../../assets/cf-logo.png'
import { useContext, useState } from 'react'
import { Button } from 'antd'
import { DeviceContext } from '../../contexts/DeviceContext'
import { useRegisterDevice } from '../../services/user'
import { useDevice } from '../../hooks/useDevice'

const DeviceRegisterScreen = () => {
  const { save } = useDevice()

  const { setDeviceCode } = useContext(DeviceContext)
  const [deviceName, setDeviceName] = useState('')
  const { loading } = useRegisterDevice()

  const handleSave = async () => {
    await save(deviceName)

    location.reload()
    setDeviceCode(deviceName)
  }

  return (
    <Screen id="loading" dark sidebar={false}>
        <div className='content'>
            <img alt="logo" src={Logo} />
            <Input className='new-device-input' value={deviceName} onChange={(e) => { setDeviceName(e.target.value) }} placeholder='Nombre del dispositivo' />

            <Button
                onClick={handleSave}
                loading={loading}
              >
                Guardar
              </Button>
        <p>Dale un nombre a este dispositivo. Asegurate de que es el mismo navegador y dispositivo que usarás normalmente.
        </p>
        </div>
    </Screen>
  )
}

export { DeviceRegisterScreen }
