import { gql } from '@apollo/client'

export const CREATE_ONLINE_SESSION = gql`
    mutation createOnlineSessions($input: ICreateOnlineSession!) {
        createOnlineSessions(input: $input) {
            _id
        }
    }
`

export const DELETE_ONLINE_SESSION = gql`
    mutation deleteOnlineSession($input: String!) {
        deleteOnlineSession(input: $input)
    }
`
