import { useParams, useSearchParams } from 'react-router-dom'
import { Box, Screen } from '../../components'
import './PdfDocument.scss'
import { Document } from '../../components/Document'
import { useState } from 'react'
import { type IHeaderOverride } from '@cyntler/react-doc-viewer'
import { IconArrowsMaximize, IconArrowsMinimize } from '@tabler/icons-react'

export const DocsViewer = () => {
  const { fileName, type, title } = useParams()
  const [searchParams] = useSearchParams()
  const canDownload = searchParams.get('isEnabled')

  const [isFullscren, setIsfullscreen] = useState(false)

  const MyHeader: IHeaderOverride = (state, previousDocument, nextDocument) => {
    if (!state.currentDocument || state.config?.header?.disableFileName) {
      return null
    }

    return (
      <>
        <div style={{ position: 'absolute', zIndex: 99999999 }}>
          <button onClick={() => setIsfullscreen((prev) => !prev)} className='fullscreen-doc'>
           {isFullscren ? <IconArrowsMinimize size={20} /> : <IconArrowsMaximize size={20}/>}
          </button>
        </div>
      </>
    )
  }

  if (isFullscren) {
    return <Document
            canDownload={canDownload === 'true'}
            uri={`https://crossfactory.s3.eu-south-2.amazonaws.com/${fileName}`}
            type={decodeURIComponent(type ?? '')}
            name={title ?? ''}
            header={MyHeader}
          />
  }

  return (
    <Screen id={'SubjectViewer'} title={title}>
        <Box>
          <Document
            canDownload={canDownload === 'true'}
            uri={`https://crossfactory.s3.eu-south-2.amazonaws.com/${fileName}`}
            type={decodeURIComponent(type ?? '')}
            name={title ?? ''}
            header={MyHeader}
          />
        </Box>
    </Screen>
  )
}
