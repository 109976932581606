import { gql } from '@apollo/client'

export const CREATE_TEST = gql`
mutation createTest ($input:TestInput!) {
    createTest(input: $input) {
      name
      questions {
        title
        branch {
          name
        }
        subject {
          name
        }
        topic {
          name
        }
      }
    }
  }
`

export const CREATE_TEST_BY_FILE = gql`
mutation createTestByFile ($input:TestInputByFile!) {
    createTestByFile(input: $input) {
      name
      questions {
        title
        branch {
          name
        }
        subject {
          name
        }
        topic {
          name
        }
      }
    }
  }
`

export const UPDATE_TEST = gql`
mutation updateTest ($input:UpdateTest!) {
    updateTest(input: $input) {
      name
      questions {
        title
        branch {
          name
        }
        subject {
          name
        }
        topic {
          name
        }
      }
    }
  }
`

export const REMOVE_TEST = gql`
mutation removeTest ($input: String!) {
    removeTest(input: $input) {
      name
      active
      questions {
        title
        branch {
          name
        }
        subject {
          name
        }
        topic {
          name
        }
      }
    }
  }
`
