import { Modal } from 'antd'
import { useBranch } from '../../../../hooks/useBranch'
import { Check, Input, InputFile, Select } from '../../../../components'
import { useEffect, useMemo, useState, type FC, type PropsWithChildren } from 'react'
import { type IContent } from '../../../../types/content'
import { useGetSubjectsByBranch } from '../../../../services/subject'
import { useGetTopicsBySubject } from '../../../../services/topic'
import { useCreateContent, useUpdateContent } from '../../../../services/content'
import { parseSelect } from '../../../../utils/parseSelect'

type Props = PropsWithChildren<{
    selectedSubject?: IContent
    isOpen: boolean
    title: string
    onCancel: () => void
    onFinish: () => Promise<void>
}>

const ContentModal: FC<Props> = ({ selectedSubject, isOpen, onCancel, title, onFinish }) => {
    const { branch } = useBranch()

    const [type, setType] = useState('link')
    const [subject, setSubject] = useState('')
    const [topic, setTopic] = useState('')
    const [name, setName] = useState('')
    const [url, setUrl] = useState('')
    const [folder, setFolder] = useState('')
    const [downloadable, setDownloadable] = useState(false)
    const [isLink, setIsLink] = useState<boolean>(false)

    const { subjects, loading: loadingSubjects } = useGetSubjectsByBranch(branch?._id as string)
    const { topics, loading: loadingTopics } = useGetTopicsBySubject(subject as string)

    const parsedSubjects = useMemo(() => parseSelect({
        array: subjects ?? [],
        labelKey: 'name',
        valueKey: '_id'
    }), [subjects, selectedSubject])

    const parsedTopics = useMemo(() => parseSelect({
        array: topics ?? [],
        labelKey: 'name',
        valueKey: '_id'
    }), [topics, selectedSubject])

    useEffect(() => {
        if (selectedSubject) {
            setType(selectedSubject.type)
            setName(selectedSubject.name)
            setUrl(selectedSubject.url)
            setFolder(selectedSubject.folder ?? '')
            setDownloadable(selectedSubject.downloadable)
            setSubject(selectedSubject.subject as string)
            setTopic(selectedSubject.topic as string)
            setIsLink(!selectedSubject.url?.includes('crossfactory.s3.eu-south-2.amazonaws'))
        }
    }, [selectedSubject])

    const { createContent, loading: creating } = useCreateContent()
    const { updateContent, loading: updating } = useUpdateContent()

    const resetForm = () => {
        setType('link')
        setName('')
        setUrl('')
        setDownloadable(false)
        setIsLink(false)
        setSubject('')
        setTopic('')
    }

    const getResourceUrl = (url: string, type: string) => {
        setUrl(url)
        setType(type || 'application/pdf')
    }

    const onSave = async () => {
        const payload = {
            name,
            type,
            url,
            branch: branch?._id || '',
            subject,
            topic,
            downloadable,
            folder
        }
        if (selectedSubject?._id) {
            await updateContent({ _id: selectedSubject?._id, ...payload })
        } else {
            await createContent(payload)
        }
        await onFinish()
        resetForm()
        onCancel()
    }

    const onCancelModal = () => {
        resetForm()
        onCancel()
    }

    return (
        <Modal
            open={isOpen}
            onOk={onSave}
            okText="Guardar"
            onCancel={onCancelModal}
            title={title}
            okButtonProps={{
                disabled: !name || !type || !branch || !subject || !topic,
                loading: creating || updating
            }}
        >
            {!isLink && <InputFile value={url} type="any" name={'Cargar'} fallbackUrl={getResourceUrl} />}
            <Check checked={isLink} name='Enlace' onChange={({ target }) => setIsLink(target.checked)} />
            <Input value={name} placeholder='Nombre' onChange={({ target }) => setName(target.value)} />
            <Check checked={downloadable} name='Descargable' onChange={({ target }) => setDownloadable(target.checked)} />
            {isLink && <Input value={url} placeholder='Url' onChange={({ target }) => setUrl(target.value)} />}
            <Select disabled={loadingSubjects} loading={loadingSubjects} value={subject} options={parsedSubjects} placeholder='Bloque' onChange={(id) => setSubject(id)} />
            <Select disabled={!subject || loadingTopics} loading={loadingTopics} value={topic} options={parsedTopics} placeholder='Tema' onChange={(id) => setTopic(id)} />
            <Input placeholder='Carpeta' onChange={(e) => setFolder(e.target.value)} value={folder} />
        </Modal>
    )
}

export default ContentModal
