import { type FC } from 'react'
import './SidebarUser.scss'
import { useUser } from '../../../../hooks/useUser'

interface Props {
  sidebarOpen: boolean
}

const SidebarUser: FC<Props> = ({ sidebarOpen }) => {
  const { user } = useUser()
  return (
    <div className="sidebar-user">
        <div className='user'>
            <p>{`${user?.name?.[0]}${user?.name?.[1]}`}</p>
        </div>
        {sidebarOpen && (
            <div className="user-info">
                <p className='user-text'>{user?.name}</p>
                <p className='user-text'>{user?.phone}</p>
            </div>
        )}
    </div>
  )
}

export { SidebarUser }
