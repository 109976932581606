import { useMutation, useQuery } from '@apollo/client'
import { GET_ALL_TESTS_ADMIN, GET_TESTS_BY_BRANCH } from './queries'
import { type ITest } from '../../types/test'
import { CREATE_TEST, CREATE_TEST_BY_FILE, REMOVE_TEST, UPDATE_TEST } from './mutations'
import { BranchContext } from '../../contexts/BranchContext'
import { useContext } from 'react'
import { useBranch } from '../../hooks/useBranch'
import { useUser } from '../../hooks/useUser'
import { type IQuestion } from '../../types/question'

export const getAllTests = () => {
  const { branch } = useBranch()
  const { data, loading, refetch } = useQuery(GET_ALL_TESTS_ADMIN, {
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        branch: branch?._id
      }
    }
  })

  return { refetch, tests: (data?.getAllTests || []) as ITest[], loading }
}

export const useGetTestsByBranch = (branch?: string) => {
  const { user } = useUser()
  const { branch: savedBranch } = useContext(BranchContext)
  const { data, loading, refetch } = useQuery(GET_TESTS_BY_BRANCH, {
    variables: {
      input: {
        branch: branch || savedBranch?._id,
        user: user?._id
      }
    },
    skip: !(branch || savedBranch)
  })

  return { refetch, tests: (data?.getAllTestsByBranch || []) as ITest[], loading }
}

interface ICreateTest {
  name: string
  questions: string[]
  subject?: string
  topic?: string
  time: number
  teacher: string
  visibleUntil: Date
}

interface ICreateTestByFile {
  active: boolean
  name: string
  questions: IQuestion[]
  subject?: string
  topic?: string
  time: number
  teacher: string
  visibleUntil: Date
}

interface IUpdateTest extends ICreateTest {
  _id: string
}

export const useCreateTest = () => {
  const { branch } = useBranch()
  const [action, { loading }] = useMutation(CREATE_TEST)

  const createTest = async (input: ICreateTest) => {
    const test = await action({
      variables: {
        input: {
          ...input,
          branch: branch?._id
        }
      }
    })

    return test
  }

  return { createTest, loading }
}

export const useCreateTestByFile = () => {
  const { branch } = useBranch()
  const [action, { loading }] = useMutation(CREATE_TEST_BY_FILE)

  const createTestByFile = async (input: ICreateTestByFile) => {
    const test = await action({
      variables: {
        input: {
          ...input,
          questions: input.questions.map(q => {
            q.branch = branch?._id
            return q
          }),
          branch: branch?._id
        }
      }
    })

    return test
  }

  return { createTestByFile, loading }
}

export const useUpdateTest = () => {
  const { branch } = useBranch()
  const [action, { loading }] = useMutation(UPDATE_TEST)

  const updateTest = async (input: IUpdateTest) => {
    const test = await action({
      variables: {
        input: {
          ...input,
          branch: branch?._id
        }
      }
    })

    return test
  }

  return { updateTest, loading }
}

export const useRemoveTest = () => {
  const [action, { loading }] = useMutation(REMOVE_TEST)

  const removeTest = async (input: string) => {
    const test = await action({
      variables: {
        input
      }
    })

    return test
  }

  return { removeTest, loading }
}
