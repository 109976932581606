import { useMutation, useQuery } from '@apollo/client'
import { useUser } from '../../hooks/useUser'
import { GET_BY_RATING } from './queries'
import { CREATE_RATED_QUESTION } from './mutations'

interface CreateParams {
  question: string
  rating: number
}

export const useCreateRatedQuestion = () => {
  const { user } = useUser()
  const [action, { loading }] = useMutation(CREATE_RATED_QUESTION)

  const createRatedQuestion = async (input: CreateParams) => {
    await action({ variables: { input: { ...input, user: user?._id } } })
  }

  return { createRatedQuestion, loading }
}

export const useGetRatedQuestionsByRating = (rating?: number) => {
  const { user } = useUser()
  const { data, loading } = useQuery(GET_BY_RATING, {
    variables: {
      input: {
        user: user?._id,
        rating
      }
    },
    skip: !rating
  })

  return { questions: data.getRatedQuestionByRating, loading }
}
