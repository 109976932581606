import { Divider, Modal } from 'antd'
import { type PropsWithChildren, type FC, useEffect } from 'react'
import { type TSession } from '../../../../types/session'
import { useState, useMemo } from 'react'
import { DatePicker, Input, Radio, Select } from '../../../../components'
import { useCreateSession, useRemoveSession, useUpdateSession } from '../../../../services/session'
import { type IBranch } from '../../../../types/branch'
import { type ISubject } from '../../../../types/subject'
import { type TTeacher } from '../../../../types/teacher'
import { parseSelect } from '../../../../utils/parseSelect'
import { kindOptions, sessionBase } from '../../utils/utils'
import dayjs from 'dayjs'
import { Record } from '../../../../types/meeting'

type Props = PropsWithChildren<{
  isOpen: boolean
  session?: TSession | undefined
  onCancel: () => void
  title: string
  branches?: IBranch[]
  subjects?: ISubject[]
  teachers?: TTeacher[]
}>

const ManageSessions: FC<Props> = ({ isOpen, onCancel, session, title, subjects, teachers }) => {
  const { updateSession, loading: updateLoading } = useUpdateSession()
  const { createSession, loading: createLoading } = useCreateSession()
  const [{ description, kind, date, spots, color, subject, teacher }, setEditedSession] = useState<TSession>(sessionBase)
  const [meeting, setMeeting] = useState({ duration: 60, record_type: 'none' })

  const parsedSubjects = useMemo(() => parseSelect({
    array: subjects ?? [],
    labelKey: 'name',
    valueKey: '_id'
  }), [subjects])

  const parsedTeachers = useMemo(() => parseSelect({
    array: teachers ?? [],
    labelKey: 'name',
    valueKey: '_id'
  }), [teachers])

  const parsedRecordOptions = useMemo(() => {
    return [{ label: 'Cloud', value: Record.cloud }, { label: 'Local', value: Record.local }, { label: 'Ninguno', value: Record.none }]
  }, [])

  const onChangeSession = (value: string | number, field: string) => {
    setEditedSession((prevTeacher) => {
      return { ...prevTeacher, [field]: value }
    })
  }

  const onSaveSession = async () => {
    if (session?._id) {
      await updateSession({ _id: session?._id, description, kind, date, spots, color, subject, teacher, meeting })
    } else {
      await createSession({ description, kind, date, spots, color, subject, teacher, meeting })
    }

    onCancelSession()
  }

  const onCancelSession = () => {
    setEditedSession(sessionBase)
    setMeeting({ duration: 60, record_type: 'none' })
    onCancel()
  }

  useEffect(() => {
    if (session?._id) {
      const formatedSession = { ...session, branch: (session.branch as IBranch)._id ?? '', subject: (session.subject as ISubject)._id ?? '', teacher: (session.teacher as TTeacher)._id ?? '' }
      setEditedSession(formatedSession)
      setMeeting({ duration: session.meeting?.duration ?? 60, record_type: session.meeting?.record_type ?? 'none' })
    }
  }, [session?._id])

  return (
    <Modal
      open={isOpen}
      onOk={onSaveSession}
      onCancel={onCancelSession}
      title={title}
      okButtonProps={{
        disabled: !description || isNaN(kind) || !date || !spots || !color || !subject || !teacher,
        loading: createLoading || updateLoading
      }}
    >
      <Input value={description} placeholder='Descripcion' onChange={({ target }) => onChangeSession(target.value, 'description')} />
      <Radio name='Tipo' options={kindOptions} onChange={({ target }) => onChangeSession(parseInt(target.value), 'kind')} value={kind} optionType="button" buttonStyle="solid" />
      <DatePicker value={date ? dayjs(date) : dayjs(new Date())} showTime placeholder='Fecha' onChange={(date, dateString) => onChangeSession(dateString, 'date')} />
      <Input value={spots} type='number' placeholder='Plazas' onChange={({ target }) => onChangeSession(parseInt(target.value), 'spots')} />
      <Select value={subject} options={parsedSubjects} placeholder='Bloque' onChange={(id) => setEditedSession((prev) => { return { ...prev, subject: id } })} />
      <Select value={teacher} options={parsedTeachers} placeholder='Profesor' onChange={(id) => setEditedSession((prev) => { return { ...prev, teacher: id } })} />
      <Input type='color' value={color} placeholder='Color' onChange={({ target }) => onChangeSession(target.value, 'color')} />
      <Divider orientation='left'>
        Configurar Zoom
      </Divider>
      <Input value={meeting.duration} type='number' placeholder='Tiempo' onChange={({ target }) => setMeeting((prev) => ({ ...prev, duration: parseInt(target.value) })) } />
      <Select value={meeting.record_type} options={parsedRecordOptions} placeholder='Tipo de auto grabado' onChange={(value) => {
        setMeeting((prev) => ({ ...prev, record_type: value }))
      } } />
    </Modal>
  )
}

type PropsRemove = PropsWithChildren<{
  isOpen: boolean
  session?: TSession
  onCancel: () => void
}>

const RemoveSessions: FC<PropsRemove> = ({ isOpen, onCancel, session }) => {
  const { removeSession, loading } = useRemoveSession()
  const onRemoveSession = async () => {
    await removeSession(session?._id ?? '')
    onCancel()
  }
  return (
    <Modal
      open={isOpen}
      onOk={onRemoveSession}
      onCancel={onCancel}
      title={'Eliminar session'}
      okButtonProps={{
        loading
      }}
    >
      {`Vas a eliminar la session "${session?.description}". ¿Estas seguro de que quieres continuar?`}
    </Modal>
  )
}
export { ManageSessions, RemoveSessions }
