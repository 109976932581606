export interface Role {
  _id: string
  name: string
}

export enum RoleEnum {
  user = 'user',
  admin = 'admin',
  superadmin = 'superadmin'
}
