import { Modal } from 'antd'
import { useState, type FC, useMemo } from 'react'
import {
  Accept,
  Input,
  InputFileGeneric,
  Radio,
  Select
} from '../../../../components'
import { parseSelect } from '../../../../utils/parseSelect'
import { type IQuestion } from '../../../../types/question'
import { type OperationVariables } from '@apollo/client/core/types'
import { useGetTopicsBySubject } from '../../../../services/topic'
import {
  basicFormat,
  moodleFormat
} from '../../../AdminQuestions/components/QuestionsModal/formats'
import { useGetSubjectsByBranch } from '../../../../services/subject'
import { type ITestFile } from '../../../../types/test'
import { type TTeacher } from '../../../../types/teacher'
import { useCreateTestByFile } from '../../../../services/test'
import { addDays } from 'date-fns'

interface Props {
  onClose: () => void
  open: boolean
  teachers: TTeacher[]
  refetch: () => Partial<OperationVariables> | undefined
}

// si hay que crear mas typos sacar a una carpeta
enum Format {
  Basico = 'Basico',
  Moodle = 'Moodle',
}

const AddTestModal: FC<Props> = ({ open, onClose, refetch, teachers }) => {
  const [subject, setSubject] = useState<string>('')
  const [topic, setTopic] = useState<string>('')
  const [questionForTest, setQuestionsForTest] = useState<IQuestion[]>([])
  const { subjects } = useGetSubjectsByBranch()
  const { topics } = useGetTopicsBySubject(subject)
  const [format, setFormat] = useState<string>(Format.Moodle)
  const [time, setTime] = useState(30)
  const [visibleUntil, setVisibleUntil] = useState(30)
  const [teacher, setTeacher] = useState('')
  const [name, setName] = useState('')
  const { createTestByFile, loading } = useCreateTestByFile()

  const handleClose = () => {
    onClose()
  }

  const handleSave = async () => {
    // TO DO
    // SAVE TEST
    const test: ITestFile = {
      active: true,
      name,
      teacher,
      time,
      subject,
      questions: questionForTest,
      visibleUntil: addDays(new Date(), visibleUntil)
    }

    await createTestByFile(test)
    refetch()
    handleClose()
  }

  const filesToTest = async (files: FileList) => {
    try {
      const qs: IQuestion[] = []

      const readFile = async (file: File): Promise<string> => {
        return await new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onload = (event) => {
            if (event.target) {
              resolve(event.target.result as string)
            } else {
              reject(new Error('Error al leer el archivo.'))
            }
          }
          reader.readAsText(file)
        })
      }

      for (let index = 0; index < files.length; index++) {
        const file = files[index]
        const fileContent = await readFile(file)
        if (format === Format.Basico) {
          basicFormat({
            fileContent,
            editedSubject: subject,
            editedTopic: topic,
            qs
          })
        } else {
          moodleFormat({
            fileContent,
            editedSubject: subject,
            editedTopic: topic,
            qs
          })
        }
      }

      setQuestionsForTest(qs)
    } catch (error) {
      console.error('Error al procesar archivos:', error)
    }
  }

  const handleTeacher = (teacherId: string) => {
    setTeacher(teacherId)
  }

  const parsedSubjects = useMemo(
    () =>
      parseSelect({
        array: subjects || [],
        labelKey: 'name',
        valueKey: '_id'
      }),
    [subjects]
  )

  const parsedTopics = useMemo(
    () =>
      parseSelect({
        array: topics || [],
        labelKey: 'name',
        valueKey: '_id'
      }),
    [topics]
  )

  const parsedTeacher = useMemo(
    () =>
      parseSelect({
        array: teachers ?? [],
        labelKey: 'name',
        valueKey: '_id'
      }),
    [teachers]
  )
  return (
    <Modal
      open={open}
      onCancel={handleClose}
      onOk={handleSave}
      okButtonProps={{
        disabled: !subject || !topic || !teacher || !questionForTest.length,
        loading
      }}
      okText="Crear Test"
      title={'Añadir Test a partir de archivo'}
    >
      <Input
        value={name}
        onChange={(e) => {
          setName(e.target.value)
        }}
        placeholder="Nombre"
      />
      <Select
        placeholder="Bloque"
        onChange={setSubject}
        options={parsedSubjects}
        value={subject}
      />
      <Select
        placeholder="Tema"
        onChange={setTopic}
        options={parsedTopics}
        value={topic}
      />
      <Input
        type="number"
        value={time}
        onChange={(e) => {
          setTime(Number(e.target.value))
        }}
        placeholder="Tiempo (min)"
      />
      <Input
        type="number"
        value={visibleUntil}
        onChange={(e) => {
          setVisibleUntil(Number(e.target.value))
        }}
        placeholder="Dias disponible (desde hoy)"
      />
      <Select
        placeholder="Profesor"
        onChange={handleTeacher}
        options={parsedTeacher}
        value={teacher}
      />
      <Radio
        name="Formato"
        value={format}
        options={['Moodle', 'Basico']}
        onChange={({ target }) => setFormat(target.value)}
      />
      <InputFileGeneric
        name="Preguntas para el test"
        returnFiles={filesToTest}
        accept={Accept.text}
        multiple
      />
    </Modal>
  )
}

export { AddTestModal }
