import { useGetUserFares } from '../../services/userFare'
import { useUser } from '../../hooks/useUser'

import './BranchSelectionPage.scss'
import { BranchItem } from './components/BranchItem/BranchItem'
import { Title } from '../../components'
import { useBranch } from '../../hooks/useBranch'
import { type IBranch } from '../../types/branch'
import { useGetBranches } from '../../services/branch'
import { BRANCH_KEY } from '../../constants/storageKeys'
import { useEffect, useState } from 'react'
import { LoadingScreen } from '../LoadingScreen'

const BranchSelectionPage = () => {
  const { userFares, loading } = useGetUserFares()
  const { user, isAdmin, isSuperAdmin } = useUser()
  const { branch, setBranch } = useBranch()
  const [loadingFromStorage, setLoadingFromStorage] = useState(true)

  const { branches, loading: loadingBranches } = useGetBranches()

  useEffect(() => {
    const branchStored = localStorage.getItem(BRANCH_KEY)

    if (branchStored) {
      setBranch(JSON.parse(branchStored))
      setLoadingFromStorage(false)
    }
    setLoadingFromStorage(false)
  }, [])

  const onSelectBranch = (branch: IBranch) => {
    localStorage.setItem(BRANCH_KEY, JSON.stringify(branch))

    setBranch(branch)
  }

  if (loading || loadingBranches || loadingFromStorage) {
    return <LoadingScreen />
  }

  return (
    <div id="branch-selection">
      <Title dark>MIS OPOSICIONES</Title>
      <div className="user-actions">
        <div className='user-info'>
          <div>
            <img src={user?.avatar} />
          </div>
          <div className='texts'>
            <p className='welcome'>Bienvenido</p>
            <p className='user-name'>{user?.name}</p>
          </div>
        </div>
      </div>
      <div className='branches-card'>
        {(isAdmin || isSuperAdmin)
          ? (
            branches?.map((branch) => (
              <BranchItem key={branch._id} branch={branch} onClick={onSelectBranch} />
            ))
          )
          : null}
        {!(isAdmin || isSuperAdmin)
          ? userFares.map((fare) => (
            <BranchItem key={fare._id} branch={fare.branch} onClick={onSelectBranch} />
          ))
          : null}

        {branch?.name}

      </div>
    </div>
  )
}

export { BranchSelectionPage }
