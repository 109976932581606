import { gql } from '@apollo/client'

export const CREATE_SESSIONS = gql`
mutation createSession($input: SessionInput!) {
    createSession(input:$input) {
      description
      kind
      active
      date
      spots
      color
    }
  }
`

export const UPDATE_SESSION = gql`
mutation updateSession ($input: UpdateSessionInput!) {
    updateSession(input:$input) {
      description
      kind
      active
      date
      spots
      color
      _id
    }
  }
`

export const REMOVE_SESSION = gql`
mutation removeSession ($input: String!) {
    removeSession(input:$input) {
        _id
    }
}
`
