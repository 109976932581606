import { Spin, Table } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { Screen } from '../../components'
import { getColumns } from './constants'
import { useGetSessions } from '../../services/session'
import { ManageSessions, RemoveSessions } from './components/modal/ManageSessions'
import { useCallback, useState } from 'react'
import { type TSession } from '../../types/session'
import { useGetBranches } from '../../services/branch'
import { useGetSubjectsByBranch } from '../../services/subject'
import { useGetTeachers } from '../../services/teacher'

const SessionsPage = () => {
  const [openManageSessions, setOpenManageSessions] = useState<boolean>(false)
  const [openRemoveSessions, setOpenRemoveSessions] = useState<boolean>(false)
  const [selectedSession, setSelectedSession] = useState<TSession | undefined>(undefined)
  const { loading, sessions, refetch } = useGetSessions()
  const { branches } = useGetBranches()
  const { subjects } = useGetSubjectsByBranch()
  const { teachers } = useGetTeachers()

  const toogleManageSessions = useCallback(() => setOpenManageSessions((prevOpenManageSessions) => !prevOpenManageSessions), [])
  const toogleRemoveSessions = useCallback(() => setOpenRemoveSessions((prevOpenRemoveSessions) => !prevOpenRemoveSessions), [])

  const closeManageSession = async () => {
    setSelectedSession(undefined)
    toogleManageSessions()
    await refetch()
  }

  const closeRemoveSession = async () => {
    setSelectedSession(undefined)
    toogleRemoveSessions()
    await refetch()
  }

  const EditSession = (session: TSession) => {
    setSelectedSession(session)
    toogleManageSessions()
  }

  const RemoveSession = (session: TSession) => {
    setSelectedSession(session)
    toogleRemoveSessions()
  }

  const columns = getColumns({ onEdit: EditSession, onRemove: RemoveSession })

  return (
    <Screen id="Sessions" title="Eventos" actions={[{ icon: <PlusOutlined />, onClick: toogleManageSessions }]}>
      {loading && <Spin tip="Loading" size="large" />}
      {!loading && (
        <Table locale={{ emptyText: 'No hay eventos que mostrar' }} columns={columns} dataSource={sessions} rowKey="_id" />
      )}

      <ManageSessions isOpen={openManageSessions} onCancel={closeManageSession} title={`${selectedSession ? 'Actualizar' : 'Crear'} Clase`} session={selectedSession} branches={branches} subjects={subjects} teachers={teachers} />
      <RemoveSessions isOpen={openRemoveSessions} onCancel={closeRemoveSession} session={selectedSession}/>
    </Screen>
  )
}

export { SessionsPage }
