import { Modal } from 'antd'
import { useState, type FC, useMemo } from 'react'
import { Accept, InputFileGeneric, Radio, Select } from '../../../../components'
import { parseSelect } from '../../../../utils/parseSelect'
import { type ITopic } from '../../../../types/topic'
import { type ISubject } from '../../../../types/subject'
import { type IQuestion } from '../../../../types/question'
import { useCreateQuestions } from '../../../../services/question'
import { type OperationVariables } from '@apollo/client/core/types'
import { useGetTopicsBySubject } from '../../../../services/topic'
import { basicFormat, moodleFormat } from './formats'

interface Props {
  onClose: () => void
  open: boolean
  subjects: ISubject[]
  topics: ITopic[]
  title: string
  refetch: () => Partial<OperationVariables> | undefined
}

// si hay que crear mas typos sacar a una carpeta
enum Format {
  Basico = 'Basico',
  Moodle = 'Moodle'
}

const AddQuestionsModal: FC<Props> = ({
  open,
  onClose,
  title,
  refetch,
  subjects
}) => {
  const { loading, createQuestions } = useCreateQuestions()
  const [editedSubject, setEditedSubject] = useState<string>('')
  const [editedTopic, setEditedTopic] = useState<string>('')
  const [questions, setQuestions] = useState<IQuestion[]>([])
  const { topics } = useGetTopicsBySubject(editedSubject)
  const [format, setFormat] = useState<string>(Format.Moodle)

  const handleClose = () => {
    setQuestions([])
    onClose()
  }

  const handleSave = async () => {
    for (let i = 0; i < questions.length; i += 150) {
      const segmento = questions.slice(i, i + 150)
      await createQuestions(segmento)
    }

    setQuestions([])
    refetch()
    handleClose()
  }

  const parsedSubjects = useMemo(() => parseSelect({
    array: subjects || [],
    labelKey: 'name',
    valueKey: '_id'
  }), [subjects])

  const parsedTopics = useMemo(() => parseSelect({
    array: topics || [],
    labelKey: 'name',
    valueKey: '_id'
  }), [topics])

  const filesToQuestions = async (files: FileList) => {
    try {
      const qs: IQuestion[] = []

      const readFile = async (file: File): Promise<string> => {
        return await new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onload = (event) => {
            if (event.target) {
              resolve(event.target.result as string)
            } else {
              reject(new Error('Error al leer el archivo.'))
            }
          }
          reader.readAsText(file)
        })
      }

      for (let index = 0; index < files.length; index++) {
        const file = files[index]
        const fileContent = await readFile(file)
        if (format === Format.Basico) {
          basicFormat({ fileContent, editedSubject, editedTopic, qs })
        } else {
          moodleFormat({ fileContent, editedSubject, editedTopic, qs })
        }
      }

      setQuestions(qs)
    } catch (error) {
      console.error('Error al procesar archivos:', error)
    }
  }

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      onOk={handleSave}
      okButtonProps={{
        disabled: !editedSubject || !editedTopic || !questions.length,
        loading
      }}
      okText="Guardar Preguntas"
      title={title}
    >
      <Select
        placeholder="Bloque"
        onChange={setEditedSubject}
        options={parsedSubjects}
        value={editedSubject}
      />
      <Select
        placeholder="Tema"
        onChange={setEditedTopic}
        options={parsedTopics}
        value={editedTopic}
      />
      <Radio name='Formato' value={format} options={['Moodle', 'Basico']} onChange={({ target }) => setFormat(target.value)} />
      <InputFileGeneric name='Preguntas en bloque' returnFiles={filesToQuestions} accept={Accept.text} multiple />

    </Modal>
  )
}

export { AddQuestionsModal }
