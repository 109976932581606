import { type FC, useMemo } from 'react'
import { useGetSubjectsByBranch } from '../../../../../../services/subject'
import { parseSelect } from '../../../../../../utils/parseSelect'
import { Input, Select } from '../../../../../../components'
import { useGetUserAlloweance } from '../../../../../../services/userAlloweance'
import { useAuth } from '../../../../../../hooks/useAuth'
import { Spin } from 'antd'
import { type ISubject } from '../../../../../../types/subject'

interface Props {
  selectedSubject?: string
  setSelectedSubject?: (val: string) => void
  numberOfQuestions: number
  setNumberOfQuestions: (val: number) => void
}

const SubjectsTest: FC<Props> = ({ numberOfQuestions, setNumberOfQuestions, selectedSubject, setSelectedSubject }) => {
  const { user } = useAuth()
  const { alloweance, loading: loadingAlloweance } = useGetUserAlloweance(user?._id)
  const { subjects, loading } = useGetSubjectsByBranch()

  const allowedSubjectIds = (alloweance?.subjects as unknown as ISubject[])?.map(({ _id }) => _id)
  const filteredSubjects = (subjects || []).filter(({ _id }) => allowedSubjectIds?.includes(_id!))

  const parsedSubjects = useMemo(() => parseSelect({
    array: filteredSubjects ?? [],
    labelKey: 'name',
    valueKey: '_id'
  }), [subjects, allowedSubjectIds, filteredSubjects])

  const isLoading = (loading || loadingAlloweance)

  return (
    <div>
      {isLoading && <Spin />}
      {!isLoading && subjects && subjects.length > 0 && (
        <>
          <Select
            placeholder="Bloque"
            onChange={setSelectedSubject}
            options={parsedSubjects}
            value={selectedSubject}
          />
          <Input
            placeholder='Número de preguntas'
            value={numberOfQuestions}
            onChange={(e) => {
              setNumberOfQuestions(Number(e.target.value))
            }}
            type="number"
          />
        </>
      )}
    </div>
  )
}

export { SubjectsTest }
