import { Box, Screen } from '../../components'

import './StatsPage.scss'

const StatsPage = () => (
    <Screen id="stats" title="Estadísticas">
        <div className='section-container'>
            <Box flex={3} title="Resultados en tests">

            </Box>
            <Box flex={1} title="Mejor materia">

            </Box>
            <Box flex={1} title="Peor materia">

            </Box>
        </div>
        <div className='section-container'>
            <Box flex={2} title="Tests nuevos">

            </Box>
            <Box flex={3} title="Preguntas a reforzar">

            </Box>
        </div>
    </Screen>
)

export { StatsPage }
