import { useCallback, useState } from 'react'

export const useModal = () => {
  const [visible, setVisible] = useState(false)

  const toggleModal = useCallback(() => {
    setVisible((prev) => !prev)
  }, [])

  const closeModal = () => setVisible(false)
  const openModal = () => setVisible(true)

  return { closeModal, openModal, toggleModal, visible }
}
