import { Modal, Spin } from 'antd'

import './FinishedModal.scss'
import { type FC } from 'react'
import { type IUserTest } from '../../../../types/userTest'

interface Props {
  loading: boolean
  onClose: () => void
  userTest: IUserTest
  penalty?: number
}

const FinishedTestModal: FC<Props> = ({ loading, onClose, userTest, penalty }) => {
  const getQualification = () => {
    // Usar formula
    return Math.max(0, ((userTest.correctQuestions - (userTest.failedQuestions * (penalty as number))) / userTest.numberOfquestions) * 10).toFixed(2)
  }

  return (
    <Modal
        className='finished-modal'
        cancelButtonProps={{ style: { display: 'none' } }}
        title="Resultados"
        open
        width={1200}
        onOk={onClose}
        onCancel={onClose}
        okText="Ver test"
    >
        {loading && (
            <div className='finished-test-loading'>
                <Spin tip="Loading" size="large" />
                <p className='loading-text'>Cargando resultados...</p>
            </div>
        )}
        {!loading && userTest && (
            <div className="user-test">
                <p>{`Preguntas correctas: ${userTest.correctQuestions}`}</p>
                <p>{`Preguntas fallidas: ${userTest.failedQuestions}`}</p>
                <p>{`Preguntas totales: ${userTest.numberOfquestions}`}</p>
                <div className='separate'/>
                <p>{`Nota: ${getQualification()}`}</p>
            </div>
        )}
    </Modal>
  )
}

export { FinishedTestModal }
