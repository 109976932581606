// import { useEffect, useState } from 'react'
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev'

// import { LoadingScreen } from '../LoadingScreen'
import { __DEV__ } from '@apollo/client/utilities/globals'
import { ApolloProvider } from '@apollo/client'

import apolloClient from '../../constants/apollo'
import { Routing } from './Routing'
import { AuthProvider } from '../../contexts/AuthContext'
import { BranchProvider } from '../../contexts/BranchContext'
import { ToastContainer } from 'react-toastify'
import { DeviceProvider } from '../../contexts/DeviceContext'
import { MaintenanceScreen } from '../MaintenanceScreen'

import './Main.scss'

const Main = () => {
  if (__DEV__) {
    loadDevMessages()
    loadErrorMessages()
  }

  const manteninance = false

  const isChrome = !!(window as any).chrome
  const iOSChrome = window.navigator.userAgent.match('CriOS')

  if (manteninance) {
    return <MaintenanceScreen />
  }

  return (
    <ApolloProvider client={apolloClient}>
        <AuthProvider>
          <DeviceProvider>
            <BranchProvider>
            <ToastContainer />
              <Routing />
            </BranchProvider>
          </DeviceProvider>
        </AuthProvider>
        {!isChrome && !iOSChrome && (
          <div className='chrome'>
            <p>Esta plataforma recomienda y solo da soporte a Google Chrome.</p>
            <a href="https://www.google.com/intl/es_es/chrome/" target="_blank" rel="noreferrer">
              Descargar google Chrome
            </a>
          </div>
        )}
    </ApolloProvider>
  )
}

export { Main }
