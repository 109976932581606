/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { Modal, Spin, Table } from 'antd'
import { Screen } from '../../components'
import { useGetImpugnatedQuestions, useRejectImpugnation } from '../../services/impugnatedQuestion'
import { getColumns } from './constants'
import { type IImpugnatedQuestion } from '../../types/impugnatedquestion'
import { useState } from 'react'
import { QuestionsModal } from '../AdminQuestions/components/QuestionsModal'
import { useGetSubjectsByBranch } from '../../services/subject'
import { useGetTopics } from '../../services/topic'

const ImpugnatedQuestionsPage = () => {
  const [editingQuestion, setEditingQuestion] = useState<IImpugnatedQuestion>()
  const [sureRemove, setSureRemove] = useState(false)
  const [selectedquestion, setSelectedQuestion] = useState<IImpugnatedQuestion>()

  const { subjects, loading: loadingSubjects } = useGetSubjectsByBranch()
  const { topics, loading: loadingTopics } = useGetTopics()

  const { questions, loading, refetch } = useGetImpugnatedQuestions()

  const { rejectImpugnation, loading: rejecting } = useRejectImpugnation()

  const onAccept = (q: IImpugnatedQuestion) => {
    setEditingQuestion(q)
  }

  const onReject = async (q: IImpugnatedQuestion) => {
    await rejectImpugnation(q._id!)
    refetch()
    setSureRemove(false)
    setSelectedQuestion(undefined)
  }

  const resolveImpugnation = (q: IImpugnatedQuestion) => {
    setSureRemove(true)
    setSelectedQuestion(q)
  }

  const columns = getColumns({ onAccept, onReject: resolveImpugnation })

  return (
    <Screen id="Impugnated" title="Preguntas impugnadas">
      {(loading || rejecting || loadingSubjects || loadingTopics) && <Spin tip="Loading" size="large" />}
      {!loading && (
        <Table
          locale={{ emptyText: 'No hay usuarios que mostrar' }}
          columns={columns} dataSource={questions} rowKey="_id"
        />
      )}
      <QuestionsModal
        topics={topics || []}
        subjects={subjects || []}
        title="Editar pregunta"
        selectedQuestion={editingQuestion?.question}
        open={!!editingQuestion}
        onClose={() => setEditingQuestion(undefined)}
      />
              <Modal
            open={sureRemove}
            onCancel={() => setSureRemove(false)}
            okButtonProps={{
              loading: rejecting
            }}
            onOk={async () => await onReject(selectedquestion!)}
            okText="Resolver impugnación"
            title="Resolver impugnación"
        >
            {'Vas a resolver esta impugnación. Esto la eliminará de esta pantalla y dará por concluida la modificación de la pregunta enviando el resultado de esta al usuario que la impugnó. ¿Estás seguro de que quieres continuar?'}
        </Modal>
    </Screen>

  )
}

export { ImpugnatedQuestionsPage }
