import { Modal } from 'antd'
import { type FC, useState } from 'react'
import { Input } from '../../../../components'
import { useCreateForumQuestion } from '../../../../services/forumQuestion'

interface Props {
  onClose: () => void
  open: boolean
  refetch: () => Promise<any>
}

const ForumQuestionModal: FC<Props> = ({ refetch, open, onClose }) => {
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')

  const { createForumQuestion, loading: createLoading } = useCreateForumQuestion()

  const handleClose = () => {
    onClose()
    setTitle('')
    setContent('')
  }
  const handleSave = async () => {
    await createForumQuestion({
      title,
      content
    })
    await refetch()
    onClose()
  }

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      onOk={handleSave}
      okButtonProps={{
        disabled: !title || !content,
        loading: createLoading
      }}
      okText="Guardar"
      title="Añadir tema"
    >
        <Input value={title} placeholder='Título' onChange={(e) => setTitle(e.target.value)}/>
        <Input value={content} placeholder='Contenido' onChange={(e) => setContent(e.target.value)}/>
    </Modal>
  )
}

export { ForumQuestionModal }
