import { IconInboxOff } from '@tabler/icons-react'
import { type FC } from 'react'

import './EmptyCase.scss'

interface Props {
  text: string
  size?: number
}

const EmptyCase: FC<Props> = ({ text, size }) => (
    <div className="empty-state">
        <IconInboxOff color="white" size={size || 60} />
        <p>{text}</p>
    </div>
)

export { EmptyCase }
