import DocViewer, { DocViewerRenderers, type IHeaderOverride } from '@cyntler/react-doc-viewer'

import './Document.scss'
import { memo, type FC } from 'react'
import clsx from 'clsx'

interface Props {
  canDownload: boolean
  uri: string
  type: string
  name: string
  header: IHeaderOverride

}

const Document: FC<Props> = ({ canDownload, header, uri, type, name }) => {
  const docs = [
    {
      uri,
      fileType: type,
      fileName: name
    }
  ]

  return (
    <div className={clsx('crossfactory-document', !canDownload && 'no-download')}>
      <DocViewer
        className='test-power'
        documents={docs}
        pluginRenderers={DocViewerRenderers}
        config={{
          header: {
            overrideComponent: header
          },
          pdfZoom: {
            defaultZoom: 0.6, // 1 as default,
            zoomJump: 0.1 // 0.1 as default,
          }
        }}
      />
    </div>
  )
}

const arePropsEqual = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.uri === nextProps.uri &&
    prevProps.type === nextProps.type &&
    prevProps.name === nextProps.name &&
    prevProps.canDownload === nextProps.canDownload &&
    prevProps.header === nextProps.header
  )
}

const memoizedComponent = memo(Document, arePropsEqual)

export { memoizedComponent as Document }
