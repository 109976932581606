import { IconCheck, IconEdit } from '@tabler/icons-react'
import { type IImpugnatedQuestion } from '../../types/impugnatedquestion'
import { format } from 'date-fns'

interface Params {
  onAccept: (q: IImpugnatedQuestion) => void
  onReject: (q: IImpugnatedQuestion) => void
}

export const getColumns = ({ onAccept, onReject }: Params) => [
  {
    title: 'Pregunta',
    dataIndex: ['question', 'title'],
    key: 'question_title'
  },
  {
    title: 'Usuario',
    dataIndex: ['user', 'name'],
    key: 'question_title'
  },
  {
    title: 'Motivo',
    dataIndex: 'reason',
    key: 'reason'
  },
  {
    title: 'Fecha',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (val: Date) => format(new Date(val), 'dd/MM/yyyy')
  },
  {
    title: '',
    dataIndex: '',
    key: 'x',
    width: 50,
    render: (q: IImpugnatedQuestion) => (
      <button className='table-action' onClick={() => { onAccept(q) }}>
        <IconEdit size={15} color="white"/>
      </button>
    )
  },
  {
    title: '',
    dataIndex: '',
    key: 'x',
    width: 50,
    render: (q: IImpugnatedQuestion) => (
      <button className='table-action' onClick={() => { onReject(q) }}>
        <IconCheck size={15} color="green"/>
      </button>
    )
  }
]
