import { Button, Modal } from 'antd'
import { useState, type FC, useEffect, useMemo } from 'react'
import { Check, Input, InputFile, Select } from '../../../../components'
import { parseSelect } from '../../../../utils/parseSelect'
import { type ITopic } from '../../../../types/topic'
import { type ISubject } from '../../../../types/subject'
import { type IQuestion } from '../../../../types/question'
import { useCreateQuestion, useUpdateQuestion } from '../../../../services/question'

interface Props {
  callback?: () => Promise<void>
  onClose: () => void
  open: boolean
  selectedQuestion?: IQuestion
  subjects: ISubject[]
  topics: ITopic[]
  title: string
}

const QuestionsModal: FC<Props> = ({
  callback,
  open,
  onClose,
  selectedQuestion,
  subjects,
  title,
  topics
}) => {
  const { createQuestion, loading: createLoading } = useCreateQuestion()
  const { updateQuestion, loading: updateLoading } = useUpdateQuestion()

  const [editedTopic, setEditedTopic] = useState<string>('')
  const [editedSubject, setEditedSubject] = useState<string>('')
  const [editedTitle, setEditedTitle] = useState<string>('')
  const [editedImage, setEditedImage] = useState<string>('')
  const [editedAnswers, setEditedAnswers] = useState<string[]>([])
  const [editedCorrect, setEditedCorrect] = useState<string>('')
  const [editedInfo, setEditedInfo] = useState<string | undefined>('')
  const [editedPastExam, setPastExam] = useState<boolean>(false)
  const [editedRating, setEditedRating] = useState('')

  useEffect(() => {
    if (selectedQuestion?.branch) {
      setEditedSubject((selectedQuestion.subject as ISubject)._id!)
      setEditedTopic((selectedQuestion.topic as ITopic)._id!)
      setEditedTitle(selectedQuestion?.title)
      setEditedImage(selectedQuestion?.image)
      setEditedAnswers(selectedQuestion.answers)
      setEditedCorrect(selectedQuestion.correctAnswer)
      setEditedInfo(selectedQuestion?.info)
      setPastExam(selectedQuestion?.pastexam)
      setEditedRating(selectedQuestion?.rating?.toString() || '')
    }
  }, [selectedQuestion])

  const handleClose = () => {
    setEditedTitle('')
    setEditedTopic('')
    setEditedSubject('')
    setEditedImage('')
    setEditedInfo('')
    setPastExam(false)
    onClose()
  }

  const handleSave = async () => {
    if (selectedQuestion?._id) {
      await updateQuestion({
        _id: selectedQuestion._id,
        topic: editedTopic,
        subject: editedSubject,
        title: editedTitle,
        image: editedImage,
        answers: editedAnswers,
        correctAnswer: editedCorrect,
        info: editedInfo,
        pastexam: editedPastExam,
        rating: Number(editedRating)
      })
    } else {
      await createQuestion({
        topic: editedTopic,
        subject: editedSubject,
        title: editedTitle,
        image: editedImage,
        answers: editedAnswers,
        correctAnswer: editedCorrect,
        info: editedInfo,
        pastexam: editedPastExam,
        rating: Number(editedRating)
      })
    }
    if (callback) {
      await callback()
    }
    handleClose()
  }

  const parsedTopics = useMemo(() => parseSelect({
    array: topics,
    labelKey: 'name',
    valueKey: '_id'
  }), [topics])

  const parsedSubjects = useMemo(() => parseSelect({
    array: subjects,
    labelKey: 'name',
    valueKey: '_id'
  }), [subjects])

  const parsedAnswers = useMemo(() => editedAnswers.map((ans) => ({
    label: ans,
    value: ans
  })), [editedAnswers])

  const onChangeAnswer = (val: string, index: number) => {
    setEditedAnswers((prev) => prev.map((a, idx) => {
      if (index === idx) {
        return val
      }
      return a
    }))
  }

  const addAnswer = () => {
    const newAnswers = [...editedAnswers, '']

    setEditedAnswers(newAnswers)
  }

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      onOk={handleSave}
      okButtonProps={{
        disabled: !editedTitle || !editedTopic,
        loading: createLoading || updateLoading
      }}
      okText="Guardar"
      title={title}
    >
      <Select
        placeholder="Bloque"
        onChange={setEditedSubject}
        options={parsedSubjects}
        value={editedSubject}
      />
      <Select
        placeholder="Tema"
        onChange={setEditedTopic}
        options={parsedTopics}
        value={editedTopic}
      />
      <Input value={editedTitle} onChange={(e) => { setEditedTitle(e.target.value) }} placeholder='Título' />
      <InputFile type='image' name='Imagen' fallbackUrl={(url, type) => setEditedImage(url)} value={editedImage}/>
      <Input type='number' value={editedRating} onChange={(e) => { setEditedRating(e.target.value) }} placeholder='Dificultad' />
      <Input value={editedInfo} onChange={(e) => { setEditedInfo(e.target.value) }} placeholder='Explicación' />
      <Check checked={editedPastExam} onChange={(e) => { setPastExam(e.target.checked) }} name='Oposición Pasada' />
      {editedAnswers.map((answer, index) => (
        <Input
          key={index}
          value={editedAnswers[index]}
          onChange={(e) => { onChangeAnswer(e.target.value, index) }}
          placeholder={`Respuesta ${index + 1}`}
        />
      ))}
      <Button type="primary" className='login-button' onClick={addAnswer}>
        Añadir opción
      </Button>
      <Select
        placeholder="Respuesta correcta"
        onChange={setEditedCorrect}
        options={parsedAnswers}
        value={editedCorrect}
      />
    </Modal>
  )
}

export { QuestionsModal }
