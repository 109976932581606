import { IconEdit, IconTrash } from '@tabler/icons-react'
import { type Kind, type TSession } from '../../types/session'
import { type Meeting } from '../../types/meeting'
import { kindOptions } from './utils/utils'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'

interface Params {
  onEdit: (teacher: TSession) => void
  onRemove: (teacher: TSession) => void
}

export const getColumns = ({ onEdit, onRemove }: Params) => [
  {
    title: '',
    dataIndex: 'color',
    key: 'color',
    render: (color: string) => <div style={{ background: color, width: 20, height: 20, borderRadius: '100%' }}/>,
    width: 80
  },
  {
    title: 'Descripción',
    dataIndex: 'description',
    key: 'description'
  },
  {
    title: 'Tipo',
    dataIndex: 'kind',
    render: (kind: Kind) => {
      return <span> {kindOptions.find(k => k.value === kind)?.label} </span>
    },
    key: 'kind'
  },
  {
    title: 'Fecha',
    dataIndex: 'date',
    key: 'date',
    render: (date: Date) => {
      return <span> {format(new Date(date), 'dd-M-y HH:mm', { locale: es })} </span>
    }
  },
  {
    title: 'Plazas',
    dataIndex: 'spots',
    key: 'spots'
  },
  {
    title: 'Link Alumnos',
    dataIndex: 'meeting',
    render: ({ join_url: joinUrl }: Meeting) => {
      return (
      <a href={joinUrl}>
      Ir a clase Online
    </a>
      )
    }
  },
  {
    title: 'Link Profesor',
    dataIndex: 'meeting',
    render: ({ start_url: startUrl }: Meeting) => {
      return (
      <a href={startUrl}>
      Empezar clase Online
    </a>
      )
    }
  },
  {
    title: 'Bloque',
    dataIndex: ['branch', 'name'],
    key: 'branch_name'
  },
  {
    title: 'Tema',
    dataIndex: ['subject', 'name'],
    key: 'subject_name'
  },
  {
    title: 'Profesor',
    dataIndex: ['teacher', 'name'],
    key: 'teacher_name'
  },
  {
    title: '',
    dataIndex: '',
    key: 'x',
    width: 50,
    render: (session: TSession) => (
      <button className='table-action' onClick={() => { onEdit(session) }}>
        <IconEdit size={15} color="white"/>
      </button>
    )
  },
  {
    title: '',
    dataIndex: '',
    key: 'x',
    width: 50,
    render: (session: TSession) => (
      <button className='table-action' onClick={() => { onRemove(session) }}>
        <IconTrash size={15} color="white"/>
      </button>
    )
  }
]
