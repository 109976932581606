import { nanoid } from 'nanoid'
import { useEffect, useState } from 'react'
import { useRegisterDevice } from '../services/user'

const DEVICE_CODE_KEY = 'crossfactory-device-code'

export const useDevice = () => {
  const [deviceCode, setDeviceCode] = useState('')
  const { registerDevice } = useRegisterDevice()

  useEffect(() => {
    const id = localStorage.getItem(DEVICE_CODE_KEY)
    setDeviceCode(id!)
  }, [])

  const getDeviceCode = () => localStorage.getItem(DEVICE_CODE_KEY)
  const saveDeviceCode = async (name: string) => {
    const randomId = nanoid()
    const newId = `${name}-${randomId}`

    localStorage.setItem(DEVICE_CODE_KEY, newId)
    await registerDevice(newId)
    setDeviceCode(newId)
  }

  return {
    get: getDeviceCode,
    save: saveDeviceCode,
    deviceCode
  }
}
