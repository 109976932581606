import { gql } from '@apollo/client'

export const SAVE_QUESTION = gql`
mutation saveUserQuestion($input: UserQuestionInput!) {
    saveUserQuestion(input: $input) {
      _id
      user {
        name
      }
      question {
        title
        correctAnswer
        subject {
          name
        }
      }
    }
  }
`
