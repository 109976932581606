import { IconEdit, IconTrash } from '@tabler/icons-react'
import { type ISubject } from '../../types/subject'

interface Params {
  onEdit: (subject: ISubject) => void
  onRemove: (subject: ISubject) => void
}

export const getColumns = ({ onEdit, onRemove }: Params) => [
  {
    title: '',
    dataIndex: 'image',
    key: 'image',
    render: (img: string) => <img style={{ width: 50 }} src={img} alt="image" />,
    width: 80
  },
  {
    title: '',
    dataIndex: 'color',
    key: 'color',
    render: (color: string) => <div style={{ background: color, width: 20, height: 20, borderRadius: '100%' }}/>,
    width: 80
  },
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Bloque',
    dataIndex: ['branch', 'name'],
    key: 'branch_name'
  },
  {
    title: '',
    dataIndex: '',
    key: 'x',
    width: 50,
    render: (subject: ISubject) => (
      <button className='table-action' onClick={() => { onEdit(subject) }}>
        <IconEdit size={15} color="white" />
      </button>
    )
  },
  {
    title: '',
    dataIndex: '',
    key: 'x',
    width: 50,
    render: (subject: ISubject) => (
      <button className='table-action' onClick={() => { onRemove(subject) }}>
        <IconTrash size={15} color="white" />
      </button>
    )
  }
]
