import { type FC } from 'react'
import { useGetTestsByBranch } from '../../../../../../services/test'

import './OwnTest.scss'
import clsx from 'clsx'
import { type ITest } from '../../../../../../types/test'
import { useBranch } from '../../../../../../hooks/useBranch'

interface Props {
  selectedTest?: ITest
  setSelectedTest: (test: ITest) => void
}
const OwnUserTest: FC<Props> = ({ selectedTest, setSelectedTest }) => {
  const { branch } = useBranch()
  const { tests, loading } = useGetTestsByBranch(branch?._id)
  return (
    <div>
      {!loading && (
        <div className='tests-container'>
          {
            tests.map((test) => (
              <button key={test._id} className={clsx(selectedTest?._id === test._id && 'selected-test')} onClick={() => setSelectedTest(test)}>
                <p>{test.name}</p>
                <p className='questions'>{`${test.questions.length} Preguntas`}</p>
              </button>
            ))
          }
        </div>
      )}
    </div>
  )
}

export { OwnUserTest }
