import { gql } from '@apollo/client'

export const GET_BRANCHES = gql`
query getAllActiveBranches {
    getAllActiveBranches {
      _id
      active
      penalty
      testtime
      image
      name
    }
}
`
