import { type FC } from 'react'
import './RatingTest.scss'
import clsx from 'clsx'
import { Input } from '../../../../../components'

interface Props {
  rating: number
  setRating: (num: number) => void
  numberOfQuestions: number
  setNumberOfQuestions: (val: number) => void
}

export const RatingTest: FC<Props> = ({ rating, setRating, numberOfQuestions, setNumberOfQuestions }) => {
  return (
        <>
          <div className='select-test-type'>
              <button className={clsx((rating < 3 && rating > 0) && 'selected')} onClick={() => setRating(2)}>Fácil (1 y 2 estrellas)</button>
              <button className={clsx((rating < 5 && rating > 2) && 'selected')} onClick={() => setRating(4)}>Normal (3 y 4 estrellas)</button>
              <button className={clsx((rating > 4) && 'selected')} onClick={() => setRating(5)}>Difícil (4 y 5 estrellas)</button>
              <Input
                placeholder='Número de preguntas'
                value={numberOfQuestions}
                onChange={(e) => {
                  setNumberOfQuestions(Number(e.target.value))
                }}
                type="number"
              />
          </div>
        </>
  )
}
