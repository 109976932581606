import { gql } from '@apollo/client'

export const GET_FAILED_QUESTIONS = gql`
query getAllUserFailedByUser($input: String!) {
    getAllUserFailedByUser(input: $input) {
      _id
      question {
        correctAnswer
        title
        subject {
            color
            name
        }
      }
      comment
    }
  }
`

export const GET_SAVED_QUESTIONS = gql`
query getAllQuestionsSavedByUser($input: String!) {
  getAllQuestionsSavedByUser(input: $input) {
      _id
      question {
        correctAnswer
        title
        subject {
            color
            name
        }
      }
      comment
    }
  }
`

export const GET_DASHBOARD_DATA = gql`
query getDashboardUserData($input:String!) {
  getDashboardUserData(input: $input) {
    failed
    comment
    question {
      title
      subject {
        name
      }
      topic {
        name
      }
    }
  }
}
`

export const GET_USER_QUESTIONS_BY_USER_TEST = gql`
  query getUserQuestionsByUserTest($input: String!) {
    getUserQuestionsByUserTest(input: $input) {
      failed
      comment
      question {
        correctAnswer
        answers
        title
        subject {
          name
        }
        topic {
          name
        }
      }
    }
  }
`
