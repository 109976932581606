import { IconEdit, IconFolder, IconFolderOpen, IconTrash } from '@tabler/icons-react'
import { type FC, useState } from 'react'
import { type IContent } from '../../../../types/content'
import { Link } from 'react-router-dom'
import { DownloadOutlined } from '@ant-design/icons'
import { useUser } from '../../../../hooks/useUser'
import { type ISubject } from '../../../../types/subject'
import { type ITopic } from '../../../../types/topic'
import { Spin } from 'antd'
import { type bool } from 'aws-sdk/clients/signer'

interface Props {
    content: IContent[]
    folder: string
    loading: boolean
    setSelectedContent: any
    setRemoveModal: (val: boolean) => void
    openModal: (val: bool) => void
}

const regex = /\.(docx|rtf|txt|pptx|pps|xlsx|xls|pdf)$/i

const Folder: FC<Props> = ({ content, openModal, folder, loading, setSelectedContent, setRemoveModal }) => {
    const [isOpen, setIsOpen] = useState(false)
    const { isAdmin } = useUser()

    const folderContent = content.filter(({ folder: fld, isHidden }) => (folder === fld) && !isHidden)

    return (
        <div className='folder-style'>
            <button className='folders' onClick={() => setIsOpen((prev) => !prev)}>
                {isOpen ? <IconFolderOpen /> : <IconFolder />}
                <p>{folder}</p>
            </button>
            {isOpen && (
                folderContent.map((it) => (
                    <div key={it.name} className='content-line' style={{ marginLeft: 30 }}>
                        {!regex.test(it.url) && <a href={it.url} target='_blank' rel="noreferrer">{it.name}</a>}
                        {regex.test(it.url) && <Link to={`/doc/${it.url.split('/')[3]}/${encodeURIComponent(it.type)}/${it.name}?isEnabled=${it.downloadable}`} >{it.name}</Link>}
                        {it.type === 'image' && <a href={it.url} target='_blank' rel="noreferrer">{it.name}</a>}
                        {it.downloadable && <a className='download-content' href={it.url}><DownloadOutlined /></a>}

                        {isAdmin && (
                            <div className='line-actions'>
                                <button>
                                    <IconEdit color="#4cae4c" size={20} onClick={() => {
                                        setSelectedContent({
                                            ...it,
                                            subject: (it.subject as ISubject)._id!,
                                            topic: (it.topic as ITopic)._id!
                                        })
                                        setTimeout(() => {
                                            openModal(true)
                                        }, 500)
                                    }} />
                                </button>
                                <button onClick={() => {
                                    setRemoveModal(true)
                                    setSelectedContent(it)
                                }}>
                                    <IconTrash color="#F47174" size={20} />
                                </button>
                                {loading && (
                                    <Spin />
                                )}
                            </div>
                        )}
                    </div>
                ))
            )}
        </div>
    )
}

export default Folder
