import { gql } from '@apollo/client'

export const GET_TESTS_BY_USER = gql`
query getAllUserTestsByUser($input: String!) {
    getAllUserTestsByUser(input: $input) {
      _id
      title
      date
      correctQuestions
      numberOfquestions
      failedQuestions
    }
  }
`
