import { useMutation, useQuery } from '@apollo/client'
import { type TSession } from '../../types/session'
import { GET_SESSIONS, GET_SESSIONS_DASHBOARD } from './queries'
import { CREATE_SESSIONS, REMOVE_SESSION, UPDATE_SESSION } from './mutations'
import { useBranch } from '../../hooks/useBranch'

interface GetSessionsResponse {
  getAllActiveSessions: TSession[]
  getAllActiveSessionsDashBoard: TSession[]
}
export const useGetSessions = () => {
  const { data, loading, refetch } = useQuery<GetSessionsResponse>(GET_SESSIONS)

  return {
    sessions: data?.getAllActiveSessions,
    loading,
    refetch
  }
}

export const useGetSessionsDashboard = () => {
  const { data, loading, refetch } = useQuery<GetSessionsResponse>(GET_SESSIONS_DASHBOARD, { fetchPolicy: 'no-cache' })

  return {
    sessions: data?.getAllActiveSessionsDashBoard,
    loading,
    refetch
  }
}

export const useCreateSession = () => {
  const { branch } = useBranch()
  const [action, { loading }] = useMutation(CREATE_SESSIONS)

  const createSession = async ({ description, kind, date, spots, color, subject, teacher, meeting }: TSession) => {
    await action({
      variables: {
        input: {
          active: true,
          description,
          kind,
          date,
          spots,
          color,
          branch: branch?._id,
          subject,
          teacher,
          meeting,
          executedBy: 'changeForUser'
        }
      }
    })
  }

  return { createSession, loading }
}

export const useUpdateSession = () => {
  const { branch } = useBranch()
  const [action, { loading }] = useMutation(UPDATE_SESSION)

  const updateSession = async ({ _id, description, kind, date, spots, color, subject, teacher, meeting }: TSession) => {
    await action({
      variables: {
        input: {
          _id,
          active: true,
          description,
          kind,
          date,
          spots,
          color,
          branch: branch?._id,
          subject,
          teacher,
          meeting,
          executedBy: 'changeForUser'
        }
      }
    })
  }

  return { updateSession, loading }
}

export const useRemoveSession = () => {
  const [action, { loading }] = useMutation(REMOVE_SESSION)

  const removeSession = async (id: string) => {
    await action({
      variables: {
        input: id
      }
    })
  }

  return { removeSession, loading }
}
