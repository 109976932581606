import { Modal, Spin } from 'antd'
import { useState } from 'react'
import { Input } from '../../../../components'
import { useGetTopics } from '../../../../services/topic'
import { useCreateEmptyFolder } from '../../../../services/content'
import { type IBranch } from '../../../../types/branch'
import { type ISubject } from '../../../../types/subject'
import { type ApolloQueryResult } from '@apollo/client'

interface Props {
    isOpen: boolean
    onClose: () => void
    selectedForFolder?: string
    refetch: () => Promise<ApolloQueryResult<any>>
}

const AddFolderModal = ({ refetch, isOpen, onClose, selectedForFolder }: Props) => {
    const { topics, loading } = useGetTopics()
    const [name, setName] = useState('')
    const { createEmptyFolder, loading: creating } = useCreateEmptyFolder()

    const onCancel = () => {
        onClose()
        setName('')
    }

    const onSave = async () => {
        const topic = (topics ?? []).find((t) => t._id?.toString() === selectedForFolder)
        const subject = (topic?.subject as ISubject)?._id
        const branch = (topic?.branch as IBranch)?._id

        if (topic && subject && branch) {
            await createEmptyFolder({
                name,
                type: 'none',
                url: 'none',
                downloadable: false,
                branch: branch.toString()!,
                subject: subject.toString()!,
                topic: topic._id!,
                folder: name
            })

            await refetch()
            onCancel()
        }
    }

    return (
        <Modal
            open={isOpen}
            onCancel={onCancel}
            onOk={onSave}
            okButtonProps={{
                disabled: !name,
                loading: creating
            }}
            okText="Crear"
            title="Crear carpeta"
        >
            {loading && <Spin />}
            {!loading && (
                <Input placeholder='Nombre de la carpeta' value={name} onChange={(e) => setName(e.target.value)} />
            )}
        </Modal>
    )
}

export { AddFolderModal }
