import { Checkbox, Modal, Spin } from 'antd'
import { useEffect, useMemo, useState, type FC } from 'react'

import './ManageAlloweance.scss'
import { useGetSubjectsByBranch } from '../../../../services/subject'
import { useGetTopics } from '../../../../services/topic'
import { type IUser } from '../../../../types/user'
import { useGetUserAlloweance, useModifyAlloweance } from '../../../../services/userAlloweance'
import { type IUserAlloweanceEdit } from '../../../../types/userAlloweance'
import { type ISubject } from '../../../../types/subject'

interface Props {
  visible: boolean
  toggleModal: () => void
  user?: IUser
}

const ManageAlloweance: FC<Props> = ({ user, visible, toggleModal }) => {
  const { alloweance, loading: loadingAlloweance, refetch } = useGetUserAlloweance(user?._id)
  const { modifyAlloweance, loading: saving } = useModifyAlloweance()

  const [editedAlloweance, setEditedAlloweance] = useState<IUserAlloweanceEdit>()

  useEffect(() => {
    if (alloweance && !editedAlloweance && visible && !loadingAlloweance) {
      setEditedAlloweance({
        ...alloweance,
        subjects: (alloweance?.subjects as any)?.map((s: any) => s._id),
        topics: alloweance?.topics?.map((t: any) => t._id),
        content: alloweance.content?.map((t: any) => t._id),
        tests: alloweance.tests?.map((t: any) => t._id)
      })
    }
  }, [alloweance, editedAlloweance, visible])

  const { loading: loadingSubjects, subjects } = useGetSubjectsByBranch()
  const { loading: loadingTopics, topics } = useGetTopics()

  const handleClose = async () => {
    toggleModal()
    setEditedAlloweance(undefined)
    await refetch()
  }

  const handleSave = async () => {
    await modifyAlloweance({
      _id: alloweance._id!,
      subjects: (editedAlloweance?.subjects as any),
      topics: (editedAlloweance?.topics as any),
      content: (editedAlloweance?.content as any),
      tests: (editedAlloweance?.tests as any),
      contentTopics: alloweance.contentTopics?.map((d) => d._id) as any,
      type: 'tests'
    })
    handleClose()
    await refetch()
  }

  const isLoading = useMemo(() => loadingSubjects || loadingTopics || loadingAlloweance, [loadingAlloweance, loadingSubjects, loadingTopics])

  const handleCheckSubject = (id: string) => {
    if (editedAlloweance?.subjects?.includes(id)) {
      setEditedAlloweance((prev) => ({
        ...prev,
        subjects: (editedAlloweance?.subjects || []).filter((sb) => sb !== id)
      }))
    } else {
      setEditedAlloweance((prev) => ({
        ...prev,
        subjects: [...(editedAlloweance?.subjects || []), id]
      }))
    }
  }

  const handleCheckTopic = (id: string) => {
    if (editedAlloweance?.topics?.includes(id)) {
      setEditedAlloweance((prev) => ({
        ...prev,
        topics: (editedAlloweance?.topics || []).filter((sb) => sb !== id)
      }))
    } else {
      setEditedAlloweance((prev) => ({
        ...prev,
        topics: [...(editedAlloweance?.topics || []), id]
      }))
    }
  }

  const handleSelectAll = (id: string) => {
    const ids = topics?.filter((topic) => (topic.subject as ISubject)._id === id).map((t) => t._id) as string[]

    const getNewTopicsArray = () => {
      const current: string[] = editedAlloweance?.topics || []

      ids.forEach((topic) => {
        if (!current?.includes(topic)) {
          current.push(topic)
        }
      })

      return current
    }

    setEditedAlloweance((prev) => ({
      ...prev,
      topics: getNewTopicsArray()
    }))
  }

  return (
        <Modal
            className='alloweance-modal'
            open={visible}
            onOk={handleSave}
            okText="Guardar"
            okButtonProps={{ loading: saving || loadingAlloweance }}
            onCancel={handleClose}
            title={`Editar accesos de usuario (${user?.name})`}
        >
            <p>Estos son los accesos del usuario. Modificalos según sus necesidades.</p>
            <div className='subjects-part'>
              {isLoading
                ? (
                <Spin />
                  )
                : (
                    subjects.map((sub) => {
                      const isChecked = ((editedAlloweance?.subjects || []) as unknown as string[]).includes(sub._id!)
                      return (
                        <div key={sub._id}>
                          <Checkbox
                            key={sub._id}
                            checked={isChecked}
                            className='check-subject'
                            onChange={() => handleCheckSubject(sub._id!)}
                          >
                            {sub.name}
                          </Checkbox>
                          {isChecked && (
                            <div>
                              <div className='title-select-all'>
                                <p className='topic-title'>Temas</p>
                                <button onClick={() => handleSelectAll(sub._id!)}>Seleccionar todos</button>
                              </div>
                              <div className='decorator'/>
                              {topics?.filter((tp: any) => tp.subject._id === sub._id).map((tp) => {
                                const topicChecked = ((editedAlloweance?.topics || []) as unknown as string[]).includes(tp._id!)

                                return (
                                  <Checkbox
                                    key={tp._id}
                                    checked={topicChecked}
                                    className='remember'
                                    onChange={() => handleCheckTopic(tp._id!)}
                                  >
                                    {tp.name}
                                  </Checkbox>
                                )
                              })}
                            </div>
                          )}
                        </div>
                      )
                    })
                  )}
            </div>
        </Modal>
  )
}

export { ManageAlloweance }
