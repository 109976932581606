import { gql } from '@apollo/client'

export const GET_ALL_TESTS_ADMIN = gql`
query getAllTests($input: ByBranch!) {
  getAllTests(input: $input) {
      time
      _id
      active
      name
      branch {
        name
        penalty
      }
      subject {
        _id
        name
      }
      teacher {
        _id
        name
      }
      topic {
        _id
        name
      }
      questions {
        _id
        info
        title
        image
        answers
        correctAnswer
      }
    }
  }
`

export const GET_TESTS_BY_BRANCH = gql`
  query getAllTestsByBranch($input: GetSuscribedTests!) {
    getAllTestsByBranch(input: $input) {
      time
      _id
      active
      name
      branch {
        name
        penalty
      }
      subject {
        _id
        name
      }
      teacher {
        _id
        name
      }
      questions {
        _id
        info
        title
        image
        answers
        correctAnswer
        topic {
          name
        }
        subject {
          name
        }
      }
    }
  }
`
