import { useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'
import { useLocalStorage } from './useLocalStorage'
import { AUTH_KEY } from '../constants/storageKeys'
import { type IUser } from '../types/user'
import { RoleEnum } from '../types/role'

export const useUser = () => {
  const { user, setUser } = useContext(AuthContext)
  const { setItem, removeItem } = useLocalStorage()

  const isAdmin = user && user.role?.name.includes(RoleEnum.admin)
  const isSuperAdmin = user && user.role?.name.includes(RoleEnum.superadmin)

  const addUser = (user: IUser) => {
    setUser(user)
    setItem(AUTH_KEY, user?.authToken ?? '')
  }

  const removeUser = () => {
    setUser(null)
    removeItem(AUTH_KEY)
  }

  return { user, addUser, removeUser, isAdmin, isSuperAdmin }
}
