import { useMutation, useQuery } from '@apollo/client'
import { type TTeacher } from '../../types/teacher'
import { GET_ALL_ACTIVE_TEACHERS } from './queries'
import { CREATE_TEACHER, DEACTIVATE_TEACHER, UPDATE_TEACHER } from './mutations'

interface GetTeachersResponse {
  getAllActiveTeachers: TTeacher[]
}
export const useGetTeachers = () => {
  const { data, loading, refetch } = useQuery<GetTeachersResponse>(GET_ALL_ACTIVE_TEACHERS)

  return {
    teachers: data?.getAllActiveTeachers,
    loading,
    refetch
  }
}

export const useUpdateTeacher = () => {
  const [action, { loading }] = useMutation(UPDATE_TEACHER)
  const updateTeacher = async ({ _id, name, phone, street, color }: TTeacher) => {
    await action({
      variables: {
        input: {
          _id,
          active: true,
          name,
          phone,
          street,
          color,
          executedBy: 'changeForUser'
        }
      }
    })
  }

  return { updateTeacher, loading }
}

export const useCreateTeacher = () => {
  const [action, { loading }] = useMutation(CREATE_TEACHER)
  const createTeacher = async ({ _id, name, phone, street, color }: TTeacher) => {
    await action({
      variables: {
        input: {
          active: true,
          name,
          phone,
          street,
          color,
          executedBy: 'changeForUser'
        }
      }
    })
  }

  return { createTeacher, loading }
}

export const useDeactivateTeacher = () => {
  const [action, { loading }] = useMutation(DEACTIVATE_TEACHER)
  const deactivateTeacher = async (_id: string) => {
    await action({
      variables: {
        input: _id
      }
    })
  }

  return { deactivateTeacher, loading }
}
