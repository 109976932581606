import { Modal } from 'antd'
import { type PropsWithChildren, type FC, useState, useEffect, useMemo } from 'react'
import { type IUser } from '../../../../types/user'
import { Input, Select } from '../../../../components'
import { parseSelect } from '../../../../utils/parseSelect'
import { useGetUserRoles } from '../../../../services/role'
import { useCreateUser, useRemoveUser, useUpdateUser } from '../../../../services/user'
import Checkbox from 'antd/es/checkbox/Checkbox'

type Props = PropsWithChildren<{
  isOpen: boolean
  user?: IUser | undefined
  onCancel: () => void
  title: string
  isCreating: boolean
}>

const ManageUsers: FC<Props> = ({ isOpen, onCancel, user, title, isCreating }) => {
  const [{ DNI, name, email, phone, password, role, street }, setUser] = useState<IUser>({ DNI: '', name: '', email: '', phone: '', street: '', password: '', role: { _id: '', name: '' }, temarySection: false, testSection: false })
  const { roles, loading } = useGetUserRoles()
  const { loading: loadingMutationCreate, createUser } = useCreateUser()
  const { loading: loadingMutationUpdate, updateUser } = useUpdateUser()

  const [testSection, setTestSection] = useState(false)
  const [temarySection, setTemarySection] = useState(false)

  const onSaveUser = async () => {
    if (isCreating) {
      await createUser({ DNI, name, email, phone, street, password: 'no-password', role, temarySection, testSection })
    } else {
      await updateUser({ DNI, name, email, phone, street, password, role, temarySection, testSection })
    }
    onCancelUser()
  }

  const onCancelUser = () => {
    setUser({ DNI: '', name: '', email: '', phone: '', street: '', password: '', role: { _id: '', name: '' }, testSection, temarySection })
    onCancel()
  }

  const onChangeUser = (value: string | number, field: string) => {
    setUser((prevUser) => {
      return { ...prevUser, [field]: value }
    })
  }

  const parsedRoles = useMemo(() => parseSelect({
    array: roles ?? [],
    labelKey: 'name',
    valueKey: '_id'
  }), [roles])

  useEffect(() => {
    if (user?._id) {
      setUser(user)
      setTemarySection(user.temarySection)
      setTestSection(user.testSection)
    }
  }, [user?._id])

  return (
    <Modal
      open={isOpen}
      onOk={onSaveUser}
      onCancel={onCancelUser}
      title={title}
      okButtonProps={{
        disabled: !DNI || !name || !email || !role,
        loading: loadingMutationCreate || loadingMutationUpdate
      }}
    >
      <Input placeholder='DNI' value={DNI} onChange={({ target }) => onChangeUser(target.value, 'DNI')} />
      <Input placeholder='Nombre' value={name} onChange={({ target }) => onChangeUser(target.value, 'name')} />
      <Input placeholder='Email' type='email' value={email} disabled={!isCreating} onChange={({ target }) => onChangeUser(target.value, 'email')} />
      <Input placeholder='Telefono' value={phone} onChange={({ target }) => onChangeUser(target.value, 'phone')} />
      <Input placeholder='Calle' value={street} onChange={({ target }) => onChangeUser(target.value, 'street')} />
      <Select placeholder='Rol' value={role?._id} loading={loading} options={parsedRoles} onChange={(id) => setUser(prevUser => ({ ...prevUser, role: roles?.find(role => role._id === id) }))} />
      <Checkbox
        checked={temarySection}
        className='check-subject'
        onChange={() => setTemarySection((prev) => !prev)}
      >
        Temario habilitado
      </Checkbox>
      <Checkbox
        checked={testSection}
        className='check-subject'
        onChange={() => setTestSection((prev) => !prev)}
      >
        Tests habilitados
      </Checkbox>

    </Modal>
  )
}

type PropsRemove = PropsWithChildren<{
  isOpen: boolean
  user?: IUser
  onCancel: () => void
}>

const RemoveUsers: FC<PropsRemove> = ({ isOpen, onCancel, user }) => {
  const { loading, removeUser } = useRemoveUser()

  const onRemoveUser = async () => {
    await removeUser(user?._id as string)
    onCancel()
  }
  return (
    <Modal
      open={isOpen}
      onOk={onRemoveUser}
      onCancel={onCancel}
      title={'Eliminar user'}
      okButtonProps={{
        loading,
        disabled: loading
      }}
    >
      {`Vas a eliminar el user "${user?.name}". ¿Estas seguro de que quieres continuar?`}
    </Modal>
  )
}
export { ManageUsers, RemoveUsers }
