import { Screen } from '../../components'
import { PlusOutlined } from '@ant-design/icons'
import { useGetAllUserFares, useRemoveUserFare } from '../../services/userFare'
import { Modal, Spin, Table } from 'antd'
import { getColumns } from './constants'
import { useCallback, useState } from 'react'
import { type IUserFare } from '../../types/userFare'
import { UserFareModal } from './components/UserFareModal'
import { useModal } from '../../hooks/useModal'

const AdminSuscriptions = () => {
  const [selectedSuscription, setSelectedSuscription] = useState<IUserFare | undefined>()

  const { userFares, loading, refetch } = useGetAllUserFares()
  const { removeUserFare, loading: removeLoading } = useRemoveUserFare()
  const [removeModal, setRemoveModal] = useState(false)
  const { toggleModal, visible } = useModal()

  const toggleRemoveModal = useCallback(() => { setRemoveModal((prev) => !prev) }, [])

  const onClose = async () => {
    toggleRemoveModal()
    await refetch()
  }

  const onCloseRemove = () => {
    setSelectedSuscription(undefined)
    toggleRemoveModal()
  }

  const onRemoveModal = (userFare: IUserFare) => {
    setSelectedSuscription(userFare)
    toggleRemoveModal()
  }

  const handleRemove = async () => {
    if (selectedSuscription?._id) {
      await removeUserFare(selectedSuscription?._id)
      await onClose()
    }
  }

  const onEditUserFare = (userFare: IUserFare) => {
    setSelectedSuscription(userFare)
    toggleModal()
  }

  const columns = getColumns({
    onEdit: onEditUserFare,
    onRemove: onRemoveModal
  })

  return (
    <Screen title='Suscripciones' id="suscriptions" actions={[{ icon: <PlusOutlined />, onClick: toggleModal }]}>
        {loading && <Spin tip="Loading" size="large" />}
        {!loading && (
            <Table locale={{ emptyText: 'No hay suscripciones que mostrar' }} columns={columns} dataSource={userFares} rowKey="_id" />
        )}
        <UserFareModal
          selectedSuscription={selectedSuscription}
          onClose={toggleModal}
          open={visible}
          refetch={refetch}
        />
        <Modal
            open={removeModal}
            onCancel={onCloseRemove}
            okButtonProps={{
              danger: true,
              loading: removeLoading
            }}
            onOk={handleRemove}
            okText="Eliminar suscripción"
            title="Eliminar suscripción"
        >
            {`Vas a eliminar la suscripción de ${selectedSuscription?.user.name} a ${selectedSuscription?.branch.name}. ¿Estas seguro de que quieres continuar? El alumno no podrá acceder hasta que tenga otra suscripción activa.`}
        </Modal>
    </Screen>
  )
}

export { AdminSuscriptions }
