import { IconEdit, IconTrash } from '@tabler/icons-react'
import { format } from 'date-fns'
import { type IUserFare } from '../../types/userFare'
import { DropDownFilterTitle } from '../AdminQuestions/components/DropDownFilterTitle'

import { SearchOutlined } from '@ant-design/icons'

interface Params {
  onEdit: (subject: IUserFare) => void
  onRemove: (subject: IUserFare) => void
}

export const getColumns = ({ onEdit, onRemove }: Params) => [
  {
    title: 'Usuario',
    dataIndex: ['user', 'name'],
    key: 'user_name',
    filterDropdown: DropDownFilterTitle,
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (title: string | number | boolean, suscription: IUserFare) => {
      const regex = new RegExp(`.*${title}.*`, 'ig')
      return regex.test(suscription.user.name!)
    }
  },
  {
    title: 'Rama',
    dataIndex: ['branch', 'name'],
    key: 'branch_name'
  },
  {
    title: 'Fecha inicio',
    dataIndex: 'startDate',
    key: 'startDate',
    render: (date: string) => (
      <p>{format(new Date(date), 'dd/MM/yyyy')}</p>
    )
  },
  {
    title: 'Fecha expiración',
    dataIndex: 'endDate',
    key: 'endDate',
    render: (date: string) => (
      <p>{format(new Date(date), 'dd/MM/yyyy')}</p>
    )
  },
  {
    title: '',
    dataIndex: '',
    key: 'x',
    width: 50,
    render: (userFare: IUserFare) => (
      <button className='table-action' onClick={() => { onEdit(userFare) }}>
        <IconEdit size={15} color="white"/>
      </button>
    )
  },
  {
    title: '',
    dataIndex: '',
    key: 'x',
    width: 50,
    render: (userFare: IUserFare) => (
      <button className='table-action' onClick={() => { onRemove(userFare) }}>
        <IconTrash size={15} color="white"/>
      </button>
    )
  }
]
