import clsx from 'clsx'
import './SidebarButton.scss'
import { type FC, type PropsWithChildren } from 'react'
import { Tooltip } from 'antd'
import { Link } from 'react-router-dom'

type Props = PropsWithChildren<{
  label: string
  link?: string
  onClick?: () => void
  sidebarOpen: boolean
}>

const SidebarButton: FC<Props> = ({ children, label, link, onClick, sidebarOpen }) => (
  <Tooltip placement="right" title={label}>
    {link
      ? (
      <Link to={link} className='sidebar-button-link'>
        <button className={clsx('sidebar-button', sidebarOpen && 'open')}>
            {children}
            <p>{label}</p>
        </button>
      </Link>
        )
      : (
      <button className={clsx('sidebar-button', sidebarOpen && 'open')} onClick={onClick}>
          {children}
          <p>{label}</p>
      </button>
        )}
  </Tooltip>
)

export { SidebarButton }
