import { type FC, useState } from 'react'

import '../../ManageTemary.scss'
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react'
import { AlloweanceTopics } from '../AlloweanceTopics'

interface Props {
  name: string
  topics: any
  toggleContent: (id: string) => void
  toggleTopic: (id: string) => void
  editedAlloweance?: string[]
  editedAllowedTopics?: string[]
  setEditedAlloweance: (val: string[]) => void
}

export const AlloweanceSubjects: FC<Props> = ({ setEditedAlloweance, editedAllowedTopics, editedAlloweance, name, topics, toggleContent, toggleTopic }) => {
  const [open, setOpen] = useState(true)

  const toggleOpen = () => {
    setOpen((prev) => !prev)
  }

  return (
        <div className='button-subject'>
            <div style={{ display: 'flex', margin: '10px' }}>
                <button className='style-button' onClick={toggleOpen}>
                {open
                  ? (
                        <IconChevronDown color='white' className='chevron'/>
                    )
                  : (
                        <IconChevronRight color='white' className='chevron'/>
                    )
                }
                <p>{name}</p>
                </button>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                {open
                  ? (
                      (topics || [])?.map((topic: any) => (
                        <AlloweanceTopics
                          editedAllowedTopics={editedAllowedTopics}
                            editedAlloweance={editedAlloweance}
                            key={topic._id}
                            name={topic[0]}
                            content={topic[1]}
                            toggleContent={toggleContent}
                            toggleTopic={toggleTopic}
                            setEditedAlloweance={setEditedAlloweance}
                        />
                      ))
                    )
                  : null}
            </div>
        </div>
  )
}
