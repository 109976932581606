import { Spin } from 'antd'
import Logo from '../../assets/cf-logo.png'
import { LoadingOutlined } from '@ant-design/icons'

import './Loader.scss'

interface Props {
  size: number
}

export const Loader = ({ size }: Props) => (
    <div className="loader">
        <img src={Logo} style={{ width: size }} />
        <Spin indicator={<LoadingOutlined spin />} size="large" />
    </div>
)
