import { gql } from '@apollo/client'

export const GET_ALL_ACTIVE_TEACHERS = gql`
query getAllActiveTeachers{
    getAllActiveTeachers{
      _id
      name
      street
      phone
      color
    }
  }
`
