import { gql } from '@apollo/client'

export const CREATE_USER_FARE = gql`
mutation createUserFare($input: UserFareInput!){
    createUserFare(input:$input) {
        _id
        user {
            name
            email
        }
        branch {
            name
        }
        endDate
        startDate
    }
  }
`

export const EXTEND_USER_FARE = gql`
mutation extendUserFare($input: ExtendUserFare!){
  extendUserFare(input:$input) {
    user {
      name
      email
    }
    branch {
      name
    }
    endDate
    startDate
  }
}
`

export const REMOVE_USER_FARE = gql`
mutation removeUserFare ($input: String!) {
    removeUserFare(input: $input) {
      _id
    }
  }
`
