import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { AUTH_KEY } from './storageKeys'

// export const BACKEND_URL = 'http://localhost:8080'
export const BACKEND_URL = 'https://crossfactory-back.unbrokenapps.com'

const httpLink = createHttpLink({ uri: `${BACKEND_URL}/graphql` })
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(AUTH_KEY)
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      accessToken: token ?? ''
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})

export default client
