import { useQuery } from '@apollo/client'
import { type Role } from '../../types/role'
import { GET_ROLES } from './queries'

interface GetRoleResponse {
  getAllUserRoles: Role[]
}

export const useGetUserRoles = () => {
  const { data, loading } = useQuery<GetRoleResponse>(GET_ROLES, { fetchPolicy: 'no-cache' })

  return {
    roles: data?.getAllUserRoles,
    loading
  }
}
