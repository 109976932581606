import { type FC } from 'react'
import { type IQuestion } from '../../../../types/question'
import './QuestionBox.scss'
import { type ISubject } from '../../../../types/subject'

interface Props {
  comment: string
  question: IQuestion
}

const QuestionBox: FC<Props> = ({ comment, question }) => {
  const subject = (question.subject as ISubject)

  return (
    <div className="question-box-container">
      <div className='question-decorator' style={{ backgroundColor: subject.color }}/>
        <div className="question-box">
            <div className='question-title'>
                <p>{`• ${question.title}`}</p>
            </div>
            <div className='question-subject'>
                <div className="color" style={{ background: subject.color }}/>
                <p>{subject.name}</p>
            </div>
        </div>
        <div className="separator" />
        <div className="question-box">
            <div className='question-title answer'>
                <p><span>Repuesta:</span>{question.correctAnswer}</p>
            </div>
        </div>
        {comment && comment !== '-' && (
          <div className="question-box">
              <div className='question-title answer'>
                  <p><span>Mi comentario:</span>{comment}</p>
              </div>
          </div>
        )}
    </div>
  )
}

export { QuestionBox }
