import clsx from 'clsx'
import { type IQuestion } from '../../../types/question'
import { type ReactElement, useRef, useEffect } from 'react'
interface Props {
  q: IQuestion
  selectedQuestions: string[]
  toggleQuestion: (id: string) => void
  observer: IntersectionObserver
  children: ReactElement
  isLastItem: boolean
}

const Button = ({ q, selectedQuestions, observer, children, toggleQuestion, isLastItem }: Props) => {
  const ref = useRef(null)
  useEffect(() => {
    if (ref.current && isLastItem) {
      observer.observe(ref.current)
    }
  }, [isLastItem])
  return (
        <button ref={ref} key={q._id} className={clsx('question-select', selectedQuestions.includes(q._id!) && 'is-selected')} onClick={() => toggleQuestion(q._id!)}>
                <p>{children}</p>
        </button>
  )
}

export { Button }
