import { gql } from '@apollo/client'

export const CREATE_BRANCH = gql`
mutation createBranch ($input: BranchInput!) {
    createBranch(input:$input) {
      name
      active
      penalty
      testtime
      image
      _id
    }
  }
`

export const UPDATE_BRANCH = gql`
mutation updateBranch ($input: UpdateBranchInput!) {
  updateBranch(input:$input) {
    name
    active
    penalty
    testtime
    image
    _id
  }
}
`

export const REMOVE_BRANCH = gql`
mutation removeBranch ($input: String!) {
  removeBranch(input:$input) {
    name
    active
    penalty
    image
    _id
  }
}
`
