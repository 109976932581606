import { Spin } from 'antd'
import { Box, EmptyCase, Screen } from '../../components'
import { useGetFailedQuestions, useGetSavedQuestions } from '../../services/userQuestion'
import { QuestionBox } from '../DashboardPage/components/QuestionBox'

import './SavedQuestionsPage.scss'

const SavedQuestionsPage = () => {
  const { questions: failedQuestions, loading: loadingFailed } = useGetFailedQuestions()
  const { questions: savedQuestions, loading: loadingSaved } = useGetSavedQuestions()

  return (
    <Screen id="start-test" title="Preguntas destacadas">
      {(loadingFailed || loadingSaved)
        ? (
          <Spin tip="Loading" size="large" />
        )
        : null}
      {!(loadingFailed || loadingSaved)
        ? (
          <div className='saved-questions'>
            <Box title="Preguntas guardadas" className="saved-questions-box">
              {savedQuestions && savedQuestions.length > 0 && (
                (savedQuestions || []).map((question) => (
                  <QuestionBox key={question._id} question={question.question} comment={question.comment} />
                ))
              )}
              {(!savedQuestions || savedQuestions.length === 0) && (
                <EmptyCase text='No hay preguntas que mostrar' />
              )}
            </Box>
            <Box title="Preguntas fallidas" className="saved-questions-box">
              {failedQuestions && failedQuestions.length > 0 && (
                (failedQuestions || []).map((question) => (
                  <QuestionBox key={question._id} question={question.question} comment="" />
                ))
              )}
              {(!failedQuestions || failedQuestions.length === 0) && (
                <EmptyCase text='No hay preguntas que mostrar' />
              )}
            </Box>
          </div>
        )
        : null}
    </Screen>
  )
}

export { SavedQuestionsPage }
