import { useContext } from 'react'
import { BranchContext } from '../contexts/BranchContext'
import { BRANCH_KEY } from '../constants/storageKeys'

export const useBranch = () => {
  const { branch, setBranch } = useContext(BranchContext)

  const removeBranch = () => {
    localStorage.removeItem(BRANCH_KEY)
    setBranch(null)
  }

  return { branch, setBranch, removeBranch }
}
