import { gql } from '@apollo/client'

export const SAVE_TEST_RESULTS = gql`
mutation createUserTest($input: UserTestInput!) {
    createUserTest(input:$input){
      _id
      failedQuestions
      correctQuestions
      numberOfquestions
      user {
        name
      }
      date
      title
    }
  }
`
