import { useMutation, useQuery } from '@apollo/client'
import { useAuth } from '../../hooks/useAuth'
import { GET_TESTS_BY_USER } from './queries'
import { type IUserTest } from '../../types/userTest'
import { SAVE_TEST_RESULTS } from './mutations'

export interface SolvedQuestion {
  question: string
  failed: boolean
  correct: boolean
}

export const useCorrectTest = () => {
  const { user } = useAuth()
  const [saveTest, { loading, data }] = useMutation(SAVE_TEST_RESULTS)

  const onSaveTest = async (questions: SolvedQuestion[]) => {
    const userTest = await saveTest({
      variables: {
        input: {
          user: user?._id,
          questions
        }
      }
    })

    return userTest as IUserTest
  }

  return { saveTest: onSaveTest, loading, userTest: data?.createUserTest }
}

interface UserTestResponse {
  getAllUserTestsByUser: IUserTest[]
}

export const useGetUserTests = () => {
  const { user } = useAuth()

  const { data, loading } = useQuery<UserTestResponse>(GET_TESTS_BY_USER, {
    fetchPolicy: 'no-cache',
    variables: {
      input: user?._id
    }
  })

  return { userTests: data?.getAllUserTestsByUser ?? [], loading }
}

export const useGetTestQuestions = () => {
  const { data, loading } = useQuery<UserTestResponse>(GET_TESTS_BY_USER, {
    fetchPolicy: 'no-cache',
    variables: {
      // input: user?._id
    }
  })

  return { userTests: data?.getAllUserTestsByUser ?? [], loading }
}
