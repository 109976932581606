import { Button } from 'antd'
import { Screen } from '../../components'
import { PROTECTED_ROUTES } from '../../constants/routes'

import './StartTestPage.scss'
import { Link } from 'react-router-dom'

const StartTestPage = () => (
    <Screen id="start-test-page" title="Iniciar test">
        <div className='start-test-content'>
            <div className='page-content'>
                <p className='title'>Seleccionar test</p>
                <p className='st-description'>Selecciona uno de los tests que hemos preparado para ti. Los tienes por bloque, tema, dificultad y generales.</p>
                <Link to={`${PROTECTED_ROUTES.TEST}?type=manual`}>
                    <Button type="primary">
                    Empezar test
                    </Button>
                </Link>
            </div>
            <div className='page-content'>
                <p className='title'>Generar test aleatorio</p>
                <p className='st-description'>Genera un test con preguntas aleatorias. Puedes aplicar filtros para acotar el tipo de preguntas que saldrán en el test y así, adaptarlo a lo que necesitas en este momento.</p>
                <Link to={`${PROTECTED_ROUTES.TEST}?type=random`}>
                    <Button type="primary">
                    Generar test
                    </Button>
                </Link>
            </div>
        </div>
    </Screen>
)

export { StartTestPage }
