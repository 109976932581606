import { format } from 'date-fns'
import { type IUserTest } from '../../../../types/userTest'
import { type FC } from 'react'

import './ResultBox.scss'
import { IconCheck, IconCircleX } from '@tabler/icons-react'
import { Link } from 'react-router-dom'
import { PROTECTED_ROUTES } from '../../../../constants/routes'

interface Props {
  result: IUserTest
}

const ResultBox: FC<Props> = ({ result }) => {
  const isApproved = result.correctQuestions > (result.numberOfquestions / 2)

  return (
    <Link to={`${PROTECTED_ROUTES.TEST}?testId=${result._id}`} className='link-result'>
      <div className="result-box-container">
          <div key={result.title}>
              <p className='result-box-title'>{result.title}</p>
              <p className='result-box-date'>{format(new Date(result.date), 'dd/MM/yyyy')}</p>
          </div>
          <div className='result-content-item'>
              <p>{`${result.correctQuestions}/${result.numberOfquestions} `}</p>
              {isApproved
                ? (
                  <IconCheck color="green" fill='green'/>
                  )
                : (
                  <IconCircleX color="red" />
                  )}
          </div>
      </div>
    </Link>
  )
}

export { ResultBox }
