import { type PropsWithChildren, type FC } from 'react'
import { Input } from 'antd'

type Props = PropsWithChildren<{
  fieldKey: string
  isEditing: boolean
  value: string
  setField: (key: string, value: string) => void
}>
export const ProfileEdit: FC<Props> = ({ fieldKey, children, isEditing, setField, value }) => {
  return (
  <div style={{ display: 'flex', gap: '1%', alignItems: 'center' }}>
    <div style={{ width: '50%', maxWidth: '50%', textOverflow: 'ellipsis', display: 'inline' }}>
      {children}
    </div>
    <Input
      style={{
        width: '50%',
        maxWidth: '50%',
        height: 'fit-content',
        transition: 'opacity 1s 0.6s ease-in-out',
        opacity: `${isEditing ? '100' : '0'}`
      }}
      onChange={(event) => setField(fieldKey, event.target.value)}
      defaultValue={value}
      type='text'
    />
  </div>
  )
}
