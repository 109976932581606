import { Box, Screen } from '../../components'
import { PROTECTED_ROUTES } from '../../constants/routes'

import './AdminTemaryPage.scss'

const AdminTemaryPage = () => (
    <Screen id="start-test" title="Administración de temario">
        <div className='start-content'>
            <Box
                actions={[
                  { label: 'Administrar preguntas', onClick: () => true, type: 'primary', link: `${PROTECTED_ROUTES.TEST}?type=manual` },
                  { label: 'Administrar tests', onClick: () => true, type: 'primary', link: `${PROTECTED_ROUTES.TEST}?type=manual` }
                ]}
                description='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quam, accusantium sit deserunt explicabo vel et modi consequatur repudiandae eius! Illum maiores dolorem repellendus, consequuntur magni ex illo dolorum sapiente suscipit.'
                title='Administración de tests'
            >

            </Box>
            <Box
                actions={[{ label: 'Administrar', onClick: () => true, type: 'primary', link: `${PROTECTED_ROUTES.TEST}?type=random` }]}
                description='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quam, accusantium sit deserunt explicabo vel et modi consequatur repudiandae eius! Illum maiores dolorem repellendus, consequuntur magni ex illo dolorum sapiente suscipit.'
                title='Administración de temario'
            >

            </Box>
        </div>
    </Screen>
)

export { AdminTemaryPage }
