import { IconEdit, IconTrash } from '@tabler/icons-react'
import { type IBranch } from '../../types/branch'

interface Params {
  onEdit: (branch: IBranch) => void
  onRemove: (branch: IBranch) => void
}

export const getColumns = ({ onEdit, onRemove }: Params) => [
  {
    title: '',
    dataIndex: 'image',
    key: 'image',
    render: (img: string) => <img style={{ width: 50 }} src={'https://csoapps-app-configs.s3.eu-south-2.amazonaws.com/crossfactoryapp.png'} alt="image" />,
    width: 80
  },
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Penalización',
    dataIndex: 'penalty',
    key: 'penalty'
  },
  {
    title: 'Tiempo Test (min)',
    dataIndex: 'testtime',
    key: 'testtime'
  },
  {
    title: '',
    dataIndex: '',
    key: 'x',
    width: 50,
    render: (branch: IBranch) => (
      <button className='table-action' onClick={() => { onEdit(branch) }}>
        <IconEdit size={15} color='white' />
      </button>
    )
  },
  {
    title: '',
    dataIndex: '',
    key: 'x',
    width: 50,
    render: (branch: IBranch) => (
      <button className='table-action' onClick={() => { onRemove(branch) }}>
        <IconTrash size={15} color='white' />
      </button>
    )
  }
]
