import { Modal } from 'antd'
import { type PropsWithChildren, type FC, useEffect } from 'react'
import { type TTeacher } from '../../../../types/teacher'
import { useState } from 'react'
import { Input } from '../../../../components'
import { useCreateTeacher, useDeactivateTeacher, useUpdateTeacher } from '../../../../services/teacher'

type Props = PropsWithChildren<{
  isOpen: boolean
  teacher?: TTeacher | undefined
  onCancel: () => void
  title: string
}>

const ManageTeachers: FC<Props> = ({ isOpen, onCancel, teacher, title }) => {
  const { updateTeacher, loading: updateLoading } = useUpdateTeacher()
  const { createTeacher, loading: createLoading } = useCreateTeacher()
  const [{ name, street, color, phone }, setEditedTeacher] = useState<TTeacher>({ name: '', street: '', color: '', phone: '' })

  const onChangeTeacher = (value: string, field: string) => {
    setEditedTeacher((prevTeacher) => {
      return { ...prevTeacher, [field]: value }
    })
  }

  const onSaveTeacher = async () => {
    if (teacher?._id) {
      await updateTeacher({ _id: teacher?._id, name, street, color: color || '#000000', phone })
    } else {
      await createTeacher({ name, street, color: color || '#000000', phone })
    }

    onCancelTeacher()
  }

  const onCancelTeacher = () => {
    setEditedTeacher({ name: '', street: '', color: '', phone: '' })
    onCancel()
  }

  useEffect(() => {
    if (teacher?._id) {
      setEditedTeacher(teacher)
    }
  }, [teacher?._id])

  return (
    <Modal
      open={isOpen}
      onOk={onSaveTeacher}
      onCancel={onCancelTeacher}
      title={title}
      okButtonProps={{
        disabled: !name || !street || !phone || !color,
        loading: createLoading || updateLoading
      }}
    >
      <Input value={name} placeholder='Nombre' onChange={({ target }) => onChangeTeacher(target.value, 'name')} />
      <Input value={street} placeholder='Calle' onChange={({ target }) => onChangeTeacher(target.value, 'street')} />
      <Input value={phone} placeholder='Teléfono' onChange={({ target }) => onChangeTeacher(target.value, 'phone')} />
      <Input type='color' value={color} placeholder='Color' onChange={({ target }) => onChangeTeacher(target.value, 'color')} />
    </Modal>
  )
}

type PropsRemove = PropsWithChildren<{
  isOpen: boolean
  teacher?: TTeacher
  onCancel: () => void
}>

const RemoveTeachers: FC<PropsRemove> = ({ isOpen, onCancel, teacher }) => {
  const { deactivateTeacher, loading } = useDeactivateTeacher()
  const onRemoveTeacher = async () => {
    await deactivateTeacher(teacher?._id ?? '')
    onCancel()
  }
  return (
    <Modal
      open={isOpen}
      onOk={onRemoveTeacher}
      onCancel={onCancel}
      title={'Eliminar profesor'}
      okButtonProps={{
        loading
      }}
    >
      {`Vas a eliminar al profesor "${teacher?.name}". ¿Estas seguro de que quieres continuar?`}
    </Modal>
  )
}
export { ManageTeachers, RemoveTeachers }
