import { Modal, Spin, Table } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { getColumns } from './constants'
import { Screen } from '../../components'
import { useCallback, useState } from 'react'
import { TopicsModal } from './components/TopicsModal'
import { useGetSubjectsByBranch } from '../../services/subject'
import { useGetTopics, useRemoveTopic } from '../../services/topic'
import { type ITopic } from '../../types/topic'
import { useGetQuestionsInfo } from '../../services/question/utils'

const AdminTopics = () => {
  const { subjects } = useGetSubjectsByBranch()
  const { topics, loading, refetch } = useGetTopics()
  const { removeTopic, loading: removeLoading } = useRemoveTopic()
  const { questionsInfo, loading: loadingInfo } = useGetQuestionsInfo()

  const [modal, setModal] = useState(false)
  const [removeModal, setRemoveModal] = useState(false)
  const [selectedTopic, setSelectedTopic] = useState<ITopic | undefined>()

  const toggleModal = useCallback(() => { setModal((prev) => !prev) }, [])
  const toggleRemoveModal = useCallback(() => { setRemoveModal((prev) => !prev) }, [])

  const onClose = async () => {
    setSelectedTopic(undefined)
    setModal(false)
    setRemoveModal(false)
    await refetch()
  }

  const onCloseRemove = () => {
    setSelectedTopic(undefined)
    toggleRemoveModal()
  }

  const onEditTopic = (topic: ITopic) => {
    setSelectedTopic(topic)
    toggleModal()
  }

  const onRemoveModal = (topic: ITopic) => {
    setSelectedTopic(topic)
    toggleRemoveModal()
  }

  const handleRemove = async () => {
    if (selectedTopic?._id) {
      await removeTopic(selectedTopic?._id)
      await onClose()
    }
  }

  const columns = getColumns({
    onEdit: onEditTopic,
    onRemove: onRemoveModal,
    info: questionsInfo
  })

  return (
    <Screen title='Temas' id="topics" actions={[{ icon: <PlusOutlined />, onClick: toggleModal }]}>
        {(loading || loadingInfo) && <Spin tip="Loading" size="large" />}
        {!(loading || loadingInfo) && (
            <Table locale={{ emptyText: 'No hay temas que mostrar' }} columns={columns} dataSource={topics} rowKey="_id" />
        )}
        <TopicsModal
            selectedTopic={selectedTopic}
            subjects={subjects ?? []}
            open={modal}
            onClose={onClose}
            title={`${selectedTopic ? 'Actualizar' : 'Crear'} Tema`}
        />
        <Modal
            open={removeModal}
            onCancel={onCloseRemove}
            okButtonProps={{
              danger: true,
              loading: removeLoading
            }}
            onOk={handleRemove}
            okText="Eliminar tema"
            title="Eliminar tema"
        >
            {`Vas a eliminar el tema "${selectedTopic?.name}". ¿Estas seguro de que quieres continuar? No podrás acceder al tema, ni a sus preguntas, etc...`}
        </Modal>
    </Screen>
  )
}

export { AdminTopics }
