import { type FC } from 'react'
import './SubjectResult.scss'
import clsx from 'clsx'

interface Props {
  name: string
  score: number
  type: 'danger' | 'success'
}

const SubjectResult: FC<Props> = ({ name, score, type }) => (
    <div className="subject-result">
        <div className='percentage'>
            <div className={clsx('result-score', type)}>
                <p>{`${score}%`}</p>
            </div>
            <span className="result-description">De preguntas acertadas</span>
        </div>
        <p className="subject-name">{name}</p>
    </div>
)

export { SubjectResult }
