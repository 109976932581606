import { gql } from '@apollo/client'

export const GET_IMPUGNATED_QUESTIONS = gql`
    query getAllImpugnatedquestionsByBranch($input: String!) {
        getAllImpugnatedquestionsByBranch(input: $input) {
            _id
            question {
                _id
                title
                image
                answers
                correctAnswer
                pastexam
                info
                topic {
                    name
                    _id
                    color
                }
                subject {
                    name
                    _id
                    color
                }
                branch {
                    name
                    _id
                }
                rating
            }
            user {
                name
            }
            reason
            createdAt
        }
    }
`
