import { type IQuestion } from '../../../../types/question'

interface Params {
  fileContent: string
  editedSubject: string
  editedTopic: string
  qs: IQuestion[]
}

const capitalizeSentence = (sentence: string): string => {
  let titleFormated = sentence.trim().toLowerCase()
  const letter = titleFormated.charAt(0)
  if (letter === '¿') {
    const firstLetter = titleFormated.charAt(1)
    titleFormated = titleFormated.replace(firstLetter, firstLetter.toUpperCase())
  } else {
    titleFormated = titleFormated.replace(letter, letter.toUpperCase())
  }

  return titleFormated
}

const basicFormat = ({ fileContent, editedSubject, editedTopic, qs }: Params): void => {
  const bloques: string[] = fileContent.trim().split('\n\n')

  bloques.forEach((bloque) => {
    const question: IQuestion = {
      active: true,
      title: '',
      answers: [],
      correctAnswer: '',
      image: '',
      info: '',
      reviewers: 0,
      rating: 0,
      pastexam: false,
      subject: editedSubject,
      topic: editedTopic
    }

    bloque.split('\n').forEach((segment: string, i: number) => {
      if (i === 0) {
        question.title = capitalizeSentence(segment)
      } else {
        let s = segment.replaceAll('{', '').replaceAll('}', '')
        if (s.includes('~%100%')) {
          s = s.replaceAll('~%100%', '').trim()
          question.answers.push(s)
          question.correctAnswer = s
        } else {
          s = s.replaceAll('~%-50%', '').trim()
          question.answers.push(s)
        }
      }
    })

    qs.push(question)
  })
}

const moodleFormat = ({ fileContent, editedSubject, editedTopic, qs }: Params) => {
  const correctAnswers = fileContent.trim().matchAll(/^ANSWER: .+/gm)
  const bloques: string[] = fileContent.trim().split(/^ANSWER: .+/gm)

  bloques.forEach((bloque) => {
    const question: IQuestion = {
      active: true,
      title: '',
      answers: [],
      correctAnswer: '',
      image: '',
      info: '',
      reviewers: 0,
      rating: 0,
      pastexam: false,
      subject: editedSubject,
      topic: editedTopic
    }
    const { value, done } = correctAnswers.next()
    if (!done) {
      const correctAnswer = `${value[0].split(/^ANSWER: /gm)[1]}) `
      bloque.split('\n').forEach((row) => {
        const answerRegex = /^[A-Z]\){1}/gm
        if (answerRegex.test(row)) {
          const answer = row.split(answerRegex)[1].trim()
          question.answers.push(answer)
          const isCorrectAnswer = row.includes(correctAnswer)
          if (isCorrectAnswer) {
            question.correctAnswer = answer
          }
        } else if (row) {
          question.title = capitalizeSentence(row)
        }
      })
      qs.push(question)
    }
  })
}

export { basicFormat, moodleFormat }
