import { type FC } from 'react'
import { useGetTestsByBranch } from '../../../../../../services/test'

import './OwnTest.scss'
import { type ITest } from '../../../../../../types/test'
import clsx from 'clsx'
import { useBranch } from '../../../../../../hooks/useBranch'
import { EmptyCase } from '../../../../../../components'

interface Props {
  selectedTest?: ITest
  setSelectedTest: (test: ITest) => void
}

const OwnTest: FC<Props> = ({ selectedTest, setSelectedTest }) => {
  const { branch } = useBranch()
  const { tests, loading } = useGetTestsByBranch(branch?._id)

  if (!loading && (!tests || tests.length === 0)) {
    return (
      <div className='margined-own-tests'>
        <EmptyCase text='No se ha encontrado ningún test. Se añadirán más adelante.'/>
      </div>
    )
  }

  return (
    <div>
      {!loading && (
        <div className='tests-container'>
          {
            tests.map((test) => (
                <button key={test._id} className={clsx(selectedTest?._id === test._id && 'selected-test')} onClick={() => setSelectedTest(test)}>
                    <p>{test.name}</p>
                    <p className='questions'>{`${test.questions.length} Preguntas`}</p>
                </button>
            ))
          }
          </div>
      )}
    </div>
  )
}

export { OwnTest }
