import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'
import { useBranch } from '../../hooks/useBranch'
import { BranchSelectionPage } from '../BranchSelectionPage'
import { useUser } from '../../hooks/useUser'
import { DeviceRegisterScreen } from '../DeviceRegisterScreen'
import { useContext, useMemo } from 'react'
import { DeviceContext } from '../../contexts/DeviceContext'
import { UnauthorizedScreen } from '../UnauthorizedScreen'

const Routing = () => {
  const { user, isAdmin, isSuperAdmin } = useUser()
  const { branch } = useBranch()
  const { unathorized } = useContext(DeviceContext)

  const userDoesntHaveCode = useMemo(() => user && user.devices?.length === 0, [user])

  if (!isAdmin && unathorized === true) {
    return <UnauthorizedScreen />
  }

  if (userDoesntHaveCode && !isAdmin) {
    return <DeviceRegisterScreen />
  }

  if (user?._id && !branch) {
    return <BranchSelectionPage />
  }

  return (
    <BrowserRouter>
      <Routes>
        {(user) && (
          ROUTES.protected.map(({ component, route }) => (
            <Route
              element={component}
              key={route}
              path={route}
            />
          ))
        )}
        {user && (isAdmin || isSuperAdmin) && (
          ROUTES.admin.map(({ component, route }) => (
            <Route
              element={component}
              key={route}
              path={route}
            />
          ))
        )}
        {!user && (
          ROUTES.public.map(({ component, route }) => (
            <Route
              element={component}
              key={route}
              path={route}
            />
          ))
        )}
      </Routes>
    </BrowserRouter>
  )
}

export { Routing }
