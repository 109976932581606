import { IconEdit, IconTrash } from '@tabler/icons-react'
import { type TTeacher } from '../../types/teacher'

interface Params {
  onEdit: (teacher: TTeacher) => void
  onRemove: (teacher: TTeacher) => void
}

export const getColumns = ({ onEdit, onRemove }: Params) => [
  {
    title: '',
    dataIndex: 'image',
    key: 'image',
    render: (img: string) => <img style={{ width: 50 }} src="https://csoapps-app-configs.s3.eu-south-2.amazonaws.com/crossfactoryapp.png" alt="image" />,
    width: 80
  },
  {
    title: '',
    dataIndex: 'color',
    key: 'color',
    render: (color: string) => <div style={{ background: color, width: 20, height: 20, borderRadius: '100%' }}/>,
    width: 80
  },
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Calle',
    dataIndex: 'street',
    key: 'street'
  },
  {
    title: 'Teléfono',
    dataIndex: 'phone',
    key: 'phone'
  },
  {
    title: '',
    dataIndex: '',
    key: 'x',
    width: 50,
    render: (teacher: TTeacher) => (
      <button className='table-action' onClick={() => { onEdit(teacher) }}>
        <IconEdit size={15} color="white" />
      </button>
    )
  },
  {
    title: '',
    dataIndex: '',
    key: 'x',
    width: 50,
    render: (teacher: TTeacher) => (
      <button className='table-action' onClick={() => { onRemove(teacher) }}>
        <IconTrash size={15} color="white" />
      </button>
    )
  }
]
