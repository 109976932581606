import { gql } from '@apollo/client'

export const CREATE_SUBJECT = gql`
mutation createSubject ($input: SubjectInput!) {
    createSubject(input:$input) {
      name
      active
      color
      image
      _id
    }
  }
`

export const UPDATE_SUBJECT = gql`
mutation updateSubject ($input: UpdateSubjectInput!) {
    updateSubject(input:$input) {
      name
      active
      color
      image
      _id
    }
  }
`

export const REMOVE_SUBJECT = gql`
mutation removeSubject ($input: String!) {
    removeSubject(input:$input) {
        name
        active
        color
        image
        _id
    }
}
`
