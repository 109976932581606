import { gql } from '@apollo/client'

export const ACCEPT_IMPUGANTION = gql`
    mutation acceptImpugnateRequest($input: String!) {
        acceptImpugnateRequest(input: $input)
    }
`

export const REJECT_IMPUGNATION = gql`
mutation removeImpugnatedQuestion($input: String!) {
    removeImpugnatedQuestion(input: $input)
}
`

export const IMPUGNATE_QUESTION = gql`
    mutation createImpugnatedQuestion($input: ImpugnatedQuestionInput!) {
        createImpugnatedQuestion(input: $input) {
            _id
        }
    }
`
