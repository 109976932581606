import { Collapse, Spin } from 'antd'
import { format } from 'date-fns'

import { Screen } from '../../components'
import { TestResult } from './components'
import { useGetUserTests } from '../../services/userTest'
import { type IUserTest } from '../../types/userTest'
import { es } from 'date-fns/locale'
const Panel = Collapse.Panel

function callback (key: any) {
  console.log(key)
}

interface Parsed {
  date: string
  tests: IUserTest[]
}

const ResultsPage = () => {
  const { loading, userTests } = useGetUserTests()

  const transformedArray = (userTests || []).reduce((acc: Parsed[], obj: IUserTest) => {
    // Format the date as "month day" (e.g., "January 23")
    const formattedDate = format(new Date(obj.date), 'MMMM yyyy', { locale: es })

    // Check if there is an existing entry with the same date
    const existingEntry = acc.find(entry => entry.date === formattedDate)

    // If there is an existing entry, add the current test to its tests array
    if (existingEntry) {
      existingEntry.tests.push({
        title: obj.title,
        date: obj.date,
        correctQuestions: obj.correctQuestions,
        numberOfquestions: obj.numberOfquestions,
        failedQuestions: obj.failedQuestions
      })
    } else {
      // If there is no existing entry, create a new entry with the current test
      acc.push({
        date: formattedDate,
        tests: [{
          title: obj.title,
          date: obj.date,
          correctQuestions: obj.correctQuestions,
          numberOfquestions: obj.numberOfquestions,
          failedQuestions: obj.failedQuestions
        }]
      })
    }

    return acc
  }, [])

  return (
    <Screen id="results" title="Mis resultados">
        {loading && (
            <Spin />
        )}
        {(!loading && transformedArray && transformedArray.length > 0) && (

            <Collapse onChange={callback}>
                {transformedArray.map((item, index) => (
                    <Panel header={item.date} key={index} style={{ textTransform: 'capitalize' }}>
                        {item.tests.map(({ title, numberOfquestions, correctQuestions }, idx) => (
                            <TestResult key={idx} name={title} questions={numberOfquestions} answered={correctQuestions} success={correctQuestions > (numberOfquestions / 2)}/>
                        ))}
                    </Panel>
                ))}
            </Collapse>
        )}
    </Screen>
  )
}

export { ResultsPage }
