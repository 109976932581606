import { type SelectProps, Select as AntSelect } from 'antd'

import './Select.scss'

const Select = ({ placeholder, ...props }: SelectProps) => (
    <div className='select'>
        <p className='select-label'>{placeholder}</p>
        <AntSelect {...props} className='ant-select' optionFilterProp='name'/>
    </div>
)

export { Select }
