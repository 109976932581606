import { MoreOutlined } from '@ant-design/icons'
import React, { type FC, useState, type ReactNode } from 'react'
import { FloatButton } from 'antd'

interface Props {
  actions: Array<{
    icon: ReactNode
    onClick: () => void
    disabled?: boolean
  }>
}

const Actions: FC<Props> = ({ actions }) => {
  const [open, setOpen] = useState(false)

  const toggleMenu = () => {
    setOpen((prev) => !prev)
  }

  if (actions && actions.length === 1) {
    return <FloatButton icon={actions[0].icon} onClick={actions[0].onClick} />
  }

  return (
    <>
      <FloatButton.Group
        open={open}
        onClick={toggleMenu}
        trigger="click"
        style={{ right: 24 }}
        icon={<MoreOutlined />}
      >
        {actions.filter(({ disabled }) => !disabled).map(({ icon, onClick }, idx) => (
            <FloatButton key={idx} icon={icon} onClick={onClick} />
        ))}
      </FloatButton.Group>
    </>
  )
}

export { Actions }
