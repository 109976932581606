import { Modal } from 'antd'
import { useState, type FC, useEffect } from 'react'
import { Input, InputFile } from '../../../../components'
import { useCreateSubject, useUpdateSubject } from '../../../../services/subject'
import { type ISubject } from '../../../../types/subject'

interface Props {
  onClose: () => void
  open: boolean
  selectedSubject?: ISubject
  title: string
}

const SubjectsModal: FC<Props> = ({ open, onClose, selectedSubject, title }) => {
  const { createSubject, loading: createLoading } = useCreateSubject()
  const { updateSubject, loading: updateLoading } = useUpdateSubject()

  const [editedName, setEditedName] = useState<string>('')
  const [editedImage, setEditedImage] = useState<string>('')
  const [editedColor, setEditedColor] = useState<string>('#000000')

  useEffect(() => {
    if (selectedSubject) {
      setEditedName(selectedSubject?.name)
      setEditedImage(selectedSubject?.image)
      setEditedColor(selectedSubject?.color)
    }
  }, [selectedSubject])

  const handleClose = () => {
    setEditedName('')
    setEditedImage('')
    onClose()
  }

  const handleSave = async () => {
    if (selectedSubject?._id) {
      await updateSubject({
        _id: selectedSubject._id,
        color: editedColor,
        image: editedImage,
        name: editedName
      })
    } else {
      await createSubject({
        color: editedColor,
        image: editedImage,
        name: editedName
      })
    }
    handleClose()
  }

  return (
    <Modal
        open={open}
        onCancel={handleClose}
        onOk={handleSave}
        okButtonProps={{
          disabled: !editedImage || !editedName,
          loading: createLoading || updateLoading
        }}
        okText="Guardar"
        title={title}
    >
        <Input value={editedName} onChange={(e) => { setEditedName(e.target.value) }} placeholder='Nombre'/>
        <InputFile reset={open} type='image' name='Imagen' fallbackUrl={(url, type) => setEditedImage(url)} />
        <Input type='color' value={editedColor} onChange={(e) => { setEditedColor(e.target.value) }} placeholder='Color'/>
    </Modal>
  )
}

export { SubjectsModal }
