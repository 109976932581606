import { type FC, useState } from 'react'
import { type IForumQuestion } from '../../../../types/forumQuestion'
import { Box } from '../../../../components'

import './ForumQuestion.scss'
import { ForumAnswer } from '../ForumAnwer'

import { MessageOutlined } from '@ant-design/icons'
import { useCreateForumAnswer, useRemoveForumQuestion } from '../../../../services/forumQuestion'
import { Spin } from 'antd'
import { IconTrash } from '@tabler/icons-react'
import { useAuth } from '../../../../hooks/useAuth'
import { RoleEnum } from '../../../../types/role'

interface Props {
  question: IForumQuestion
  refetch: () => Promise<any>
}

const ForumQuestion: FC<Props> = ({ question, refetch }) => {
  const { user } = useAuth()

  const isAdmin = (user?.role?.name === RoleEnum.superadmin || user?.role?.name === RoleEnum.admin)

  const { createForumAnswer, loading } = useCreateForumAnswer()
  const { deleteForumQuestion, loading: removing } = useRemoveForumQuestion()

  const [showComments, setShowComments] = useState(false)
  const [addingResponse, setAddingResponse] = useState(false)

  const [response, setResponse] = useState('')

  const toggleComments = () => {
    setShowComments((prev) => !prev)
  }
  const toggleAddingResposne = () => {
    setAddingResponse((prev) => !prev)
  }

  const handleAddAnswer = async () => {
    await createForumAnswer({
      content: response,
      forumQuestion: question._id!
    })
    await refetch()
    setResponse('')
    toggleAddingResposne()
  }

  const handleDeleteQuestion = async () => {
    await deleteForumQuestion(question._id!)
    await refetch()
  }

  const canRemove = isAdmin || question.user._id === user?._id

  return (
    <div className='forum-question'>
        <Box
            defaultCollpsed
            title={question.title}
            description={question.content}
        >
            <div className='user'>
                <img className='avatar' src={question.user.avatar}/>
                <p>{question.user.name}</p>
            </div>

              {
                !addingResponse
                  ? (
                          <div className='buttons'>
                            <button onClick={toggleComments}>
                              <MessageOutlined />
                              <p style={{ marginLeft: 3 }}>{`${showComments ? 'Ocultar' : 'Ver'} respuestas`}</p>
                            </button>
                            <button onClick={toggleAddingResposne}>
                              <p>Añadir respuesta</p>
                            </button>
                          </div>
                    )
                  : null
              }
              {addingResponse
                ? (
                    <div>
                      <textarea placeholder='Respuesta' onChange={(e) => setResponse(e.target.value)} value={response} />
                      <div className='textarea-actions'>
                        <button onClick={handleAddAnswer}>
                          Comentar
                        </button>
                        {loading && (
                          <Spin />
                        )}
                        <button onClick={toggleAddingResposne}>
                          Cancelar
                        </button>
                      </div>
                    </div>
                  )
                : null}

            {showComments
              ? question.answers?.map((answer) => (
                <ForumAnswer key={answer._id} user={answer.user.name!} content={answer.content} date={answer.date} />
              ))
              : null}
            {canRemove && (
              <button disabled={removing} className='remove-action' onClick={handleDeleteQuestion}>
                <IconTrash size={15} color='white' />
              </button>
            )}
        </Box>
    </div>
  )
}

export { ForumQuestion }
