import { Button, Modal, Spin } from 'antd'
import { useState, type FC, useMemo, useEffect } from 'react'
import { type ITest } from '../../../../types/test'
import { useCreateTest, useUpdateTest } from '../../../../services/test'
import { parseSelect } from '../../../../utils/parseSelect'
import { Input, Select } from '../../../../components'
import { useGetSubjectsByBranch } from '../../../../services/subject'
import { useBranch } from '../../../../hooks/useBranch'
import { addDays } from 'date-fns'
import { type TTeacher } from '../../../../types/teacher'
import { useGetTopics } from '../../../../services/topic'
import { type ITopic } from '../../../../types/topic'
import { type ISubject } from '../../../../types/subject'

interface Props {
  onClose: () => void
  open: boolean
  openQuestionsModal: () => void
  selectedTest?: ITest
  title: string
  refetch: () => void
  selectedQuestions: string[]
  setSubject: (s: string) => void
  teachers: TTeacher[]
}

const TestModal: FC<Props> = ({ selectedQuestions, openQuestionsModal, open, onClose, selectedTest, title, refetch, setSubject, teachers }) => {
  const { createTest, loading: createLoading } = useCreateTest()
  const { updateTest, loading: updateLoading } = useUpdateTest()
  const { subjects, loading: loadingSubjects } = useGetSubjectsByBranch()
  const { topics, loading: loadingTopics } = useGetTopics()

  const { branch } = useBranch()

  const [name, setName] = useState('')
  const [editedSubject, setEditedSubject] = useState<string>('')
  const [editedTopic, setEditedTopic] = useState<string>('')
  const [editedTeacher, setEditedTeacher] = useState<string>('')
  const [time, setTime] = useState(30)
  const [visibleUntil, setVisibleUntil] = useState(30)

  const handleClose = () => {
    setName('')
    setEditedSubject('')
    setEditedTeacher('')
    setTime(30)
    setVisibleUntil(30)
    onClose()
  }

  const handleSave = async () => {
    if (selectedTest) {
      await updateTest({
        _id: selectedTest._id,
        name,
        subject: editedSubject,
        questions: selectedQuestions,
        teacher: editedTeacher,
        topic: editedTopic,
        time,
        visibleUntil: addDays(new Date(), visibleUntil)
      })
    } else {
      await createTest({
        name,
        subject: editedSubject,
        questions: selectedQuestions,
        teacher: editedTeacher,
        topic: editedTopic,
        time,
        visibleUntil: addDays(new Date(), visibleUntil)
      })
    }

    handleClose()
    refetch()
  }

  const handleSubject = (subjectId: string) => {
    setEditedSubject(subjectId)
    setSubject(subjectId)
  }

  const handleTeacher = (teacherId: string) => {
    setEditedTeacher(teacherId)
  }

  const parsedTeacher = useMemo(() => parseSelect({
    array: teachers ?? [],
    labelKey: 'name',
    valueKey: '_id'
  }), [teachers])

  const parsedSubjects = useMemo(() => parseSelect({
    array: subjects ?? [].filter(({ branch: br }) => br === branch?._id),
    labelKey: 'name',
    valueKey: '_id'
  }), [subjects, branch])

  const parsedTopics = useMemo(() => parseSelect({
    array: ((topics ?? []) as ITopic[]).filter(({ subject }) => (subject as ISubject)._id === editedSubject),
    labelKey: 'name',
    valueKey: '_id'
  }), [editedSubject, topics])

  useEffect(() => {
    if (selectedTest?.subject?._id && selectedTest?.teacher?._id) {
      setName(selectedTest?.name)
      setEditedSubject(selectedTest?.subject?._id)
      setEditedTeacher(selectedTest?.teacher?._id)
      setTime(selectedTest?.time)
      setSubject(selectedTest?.subject?._id)
    }
  }, [
    selectedTest?.name,
    selectedTest?.subject?._id,
    selectedTest?.teacher?._id,
    selectedTest?.time
  ])

  const isLoading = loadingSubjects || loadingTopics

  return (
    <Modal
        open={open}
        onCancel={handleClose}
        onOk={handleSave}
        okButtonProps={{
          disabled: !name,
          loading: createLoading || updateLoading
        }}
        okText="Guardar"
        title={title}
    >
      {isLoading
        ? (
        <Spin />
          )
        : (
        <>
          <Input value={name} onChange={(e) => { setName(e.target.value) }} placeholder='Nombre'/>
          <Input type='number' value={time} onChange={(e) => { setTime(Number(e.target.value)) }} placeholder='Tiempo (min)'/>
          <Select
              placeholder="Profesor"
              onChange={handleTeacher}
              options={parsedTeacher}
              value={editedTeacher}
            />

          {branch?._id
            ? (
            <Select
              placeholder="Bloque"
              onChange={handleSubject}
              options={parsedSubjects}
              value={editedSubject}
            />
              )
            : null}
          {editedSubject
            ? (
            <Select
              placeholder="Tema"
              onChange={setEditedTopic}
              options={parsedTopics}
              value={editedTopic}
            />
              )
            : null}
          <Input type='number' value={visibleUntil} onChange={(e) => { setVisibleUntil(Number(e.target.value)) }} placeholder='Dias disponible (desde hoy)'/>
          <Button onClick={openQuestionsModal} disabled={!editedSubject}>
            {`Seleccionar preguntas (${selectedQuestions.length} seleccionadas)`}
          </Button>
        </>
          )}
    </Modal>
  )
}

export { TestModal }
