import { Checkbox, Modal } from 'antd'
import { useState, type FC } from 'react'
import { Input } from '../../../../components'

import './GenerateTestModal.scss'
import { useGetSubjectsByBranch } from '../../../../services/subject'
import { type Filter } from '../../../../services/question'
import { useUser } from '../../../../hooks/useUser'
import { useGetTopics } from '../../../../services/topic'

interface Props {
  onCancel: () => void
  onStart: (filters: Filter) => void
  visible: boolean
}

const GenerateTestModal: FC<Props> = ({ onCancel, onStart, visible }) => {
  const { user } = useUser()
  const [numberOfQuestions, setNumberOfQuestions] = useState(30)
  const [onlyFailed, setOnlyFailed] = useState(false)
  const [bySubject, setBySubject] = useState(false)
  const [byTopic, setByTopic] = useState(false)
  const [pastExam, setPastExam] = useState(false)
  const [time, setTime] = useState(30)

  const { subjects, loading } = useGetSubjectsByBranch()
  const [selectedSubjects, setSelectedSubjects] = useState<string[]>([])

  const { topics, loading: loadingTopics } = useGetTopics()
  const [selectedTopics, setSelectedTopics] = useState<string[]>([])

  const handleSelectSubject = (subject: string) => {
    setSelectedSubjects((prev) => {
      const included = prev.includes(subject)
      if (included) {
        return selectedSubjects.filter((e) => e !== subject)
      }

      return [...prev, subject]
    })
  }

  const handleSelectTopic = (topic: string) => {
    setSelectedTopics((prev) => {
      const included = prev.includes(topic)
      if (included) {
        return selectedSubjects.filter((e) => e !== topic)
      }

      return [...prev, topic]
    })
  }

  const handleStart = () => {
    const u: { user: string } = {
      user: ''
    }
    if (onlyFailed || pastExam) {
      u.user = user?._id as string
    }
    onStart({
      ...(u.user && u),
      numberOfQuestions,
      onlyFailed,
      pastExam,
      subjects: selectedSubjects,
      topics: selectedTopics,
      time
    })
  }

  return (
    <Modal title="Generar test" open={visible} onOk={handleStart} onCancel={onCancel} okText="Empezar" className='generate-test-modal'>
        <Input placeholder='Número de preguntas' type='number' value={numberOfQuestions} onChange={(e) => setNumberOfQuestions(Number(e.target.value))}/>
        <Input placeholder='Tiempo (min)' type='number' value={time} onChange={(e) => setTime(Number(e.target.value))}/>
        <div className='params'>
          <Checkbox
              disabled={loading}
              className='remember'
              checked={bySubject}
              onChange={(e) => setBySubject(e.target.checked)}
          >
              Seleccionar bloques
          </Checkbox>
          {bySubject && (
            <div className="select-subjects">
              {subjects?.map((subject) => (
                <Checkbox
                  key={subject._id}
                  className='remember'
                  checked={selectedSubjects.includes(subject._id!)}
                  onChange={(e) => handleSelectSubject(subject._id!)}
              >
                {subject.name}
              </Checkbox>
              ))}
            </div>
          )}
          <Checkbox
              disabled={loadingTopics}
              className='remember'
              checked={byTopic}
              onChange={(e) => setByTopic(e.target.checked)}
          >
              Seleccionar temas
          </Checkbox>
          {byTopic && (
            <div className="select-subjects">
              {topics?.map((topic) => (
                <Checkbox
                  key={topic._id}
                  className='remember'
                  checked={selectedTopics.includes(topic._id!)}
                  onChange={(e) => handleSelectTopic(topic._id!)}
              >
                {topic.name}
              </Checkbox>
              ))}
            </div>
          )}
          <Checkbox
              className='remember'
              checked={onlyFailed}
              onChange={(e) => setOnlyFailed(e.target.checked)}
          >
              Usar solo preguntas falladas
          </Checkbox>
          <Checkbox
              className='remember'
              checked={pastExam}
              onChange={(e) => setPastExam(e.target.checked)}
          >
              Preguntas de oposiciones pasadas
          </Checkbox>
        </div>
    </Modal>
  )
}

export { GenerateTestModal }
