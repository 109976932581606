import { Modal, Spin, Table } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { getColumns } from './constants'
import { Screen } from '../../components'
import { useCallback, useState } from 'react'
import { SubjectsModal } from './components/SubjectsModal'
import { useGetSubjectsByBranch, useRemoveSubject } from '../../services/subject'
import { type ISubject } from '../../types/subject'

const AdminSubjects = () => {
  const { subjects, loading, refetch } = useGetSubjectsByBranch()
  const { removeSubject, loading: removeLoading } = useRemoveSubject()

  const [modal, setModal] = useState(false)
  const [removeModal, setRemoveModal] = useState(false)
  const [selectedSubject, setSelectedSubject] = useState<ISubject | undefined>()

  const toggleModal = useCallback(() => { setModal((prev) => !prev) }, [])
  const toggleRemoveModal = useCallback(() => { setRemoveModal((prev) => !prev) }, [])

  const onClose = async () => {
    setSelectedSubject(undefined)
    setModal(false)
    setRemoveModal(false)
    await refetch()
  }

  const onCloseRemove = () => {
    setSelectedSubject(undefined)
    toggleRemoveModal()
  }

  const onEditSubject = (subject: ISubject) => {
    setSelectedSubject(subject)
    toggleModal()
  }

  const onRemoveModal = (subject: ISubject) => {
    setSelectedSubject(subject)
    toggleRemoveModal()
  }

  const handleRemove = async () => {
    if (selectedSubject?._id) {
      await removeSubject(selectedSubject?._id)
      await onClose()
    }
  }

  const columns = getColumns({
    onEdit: onEditSubject,
    onRemove: onRemoveModal
  })

  return (
    <Screen title='Bloques' id="subjects" actions={[{ icon: <PlusOutlined />, onClick: toggleModal }]}>
        {loading && <Spin tip="Loading" size="large" />}
        {!loading && (
            <Table locale={{ emptyText: 'No hay bloques que mostrar' }} columns={columns} dataSource={subjects} rowKey="_id" />
        )}
        <SubjectsModal
            selectedSubject={selectedSubject}
            open={modal}
            onClose={onClose}
            title={`${selectedSubject ? 'Actualizar' : 'Crear'} Bloque`}
        />
        <Modal
            open={removeModal}
            onCancel={onCloseRemove}
            okButtonProps={{
              danger: true,
              loading: removeLoading
            }}
            onOk={handleRemove}
            okText="Eliminar bloque"
            title="Eliminar bloque"
        >
            {`Vas a eliminar el bloque "${selectedSubject?.name}". ¿Estas seguro de que quieres continuar? No podrás acceder al bloque, ni sus temas, preguntas, etc...`}
        </Modal>
    </Screen>
  )
}

export { AdminSubjects }
