import { Countdown, Screen } from '../../components'
import { useCallback, useEffect, useState } from 'react'
import { GenerateTestModal } from './components/GenerateTestModal'
import { SelectTestModal } from './components/SelectTestModal'
import { Navigate, useSearchParams } from 'react-router-dom'
import { PROTECTED_ROUTES } from '../../constants/routes'
import { Question } from './components/Question'
import { type Filter, useGetFilteredQuestions } from '../../services/question'

import './TestPage.scss'
import { useCountdown } from '../../hooks/useCountdown'
import { Button } from 'antd'
import { FinishedTestModal } from './components/FinishedTestModal'
import { type IQuestion } from '../../types/question'
import { type SolvedQuestion, useCorrectTest } from '../../services/userTest'
import { type ITest } from '../../types/test'
import { useBranch } from '../../hooks/useBranch'
import { useGetUserQuestionsBtUserTest } from '../../services/userQuestion'
import { Loader } from '../../components/Loader/Loader'

export interface IQuestionAndAnswer extends IQuestion {
  userAnswer?: string
}

export interface SetAnswer {
  question: string
  value?: string
}

const TestPage = () => {
  const { branch } = useBranch()
  const [searchParams] = useSearchParams()

  const doneTestId = searchParams.get('testId')

  const { questions: solvedQuestions, loading: loadingSolved } = useGetUserQuestionsBtUserTest(doneTestId)

  const [testFilters, setTestFilters] = useState<Filter>()
  const [testId, setTestId] = useState<string>()
  const [showResult, setShowResult] = useState(false)

  const [selectedTest, setSelectedTest] = useState<ITest>()
  const { timeLeft, start } = useCountdown(selectedTest?.time || testFilters?.time || branch?.testtime || 60, showResult)
  const { questions, loading: loadingQuestions } = useGetFilteredQuestions(testFilters as Filter)
  const { saveTest, loading, userTest } = useCorrectTest()

  const [userQuestions, setUserQuestions] = useState<IQuestionAndAnswer[]>([])

  const [testQuestions, setTestQuestions] = useState<IQuestion[]>([])

  useEffect(() => {
    if ((!userQuestions || userQuestions.length === 0) && (testQuestions && testQuestions.length > 0)) {
      const withAnswer: IQuestionAndAnswer[] = testQuestions.map((question) => ({
        ...question,
        userAnswer: undefined
      }))

      setUserQuestions(withAnswer)
    }
  }, [testQuestions])

  const [showFinishedModal, setShowFinishedModal] = useState(false)

  const toggleFinishedModal = useCallback(() => setShowFinishedModal((prev) => !prev), [])

  const [modalType, setModalType] = useState<string>()
  const [goBack, setGoBack] = useState(false)

  const queryParameters = new URLSearchParams(window.location.search)
  const paramType = queryParameters.get('type')

  useEffect(() => {
    if (paramType) {
      setModalType(paramType)
    }
  }, [paramType])

  const getSolvedQuestions = () => userQuestions.map((ques) => ({
    question: ques._id!,
    failed: !ques.userAnswer ? false : !(ques.correctAnswer === ques.userAnswer),
    correct: ques.correctAnswer === ques.userAnswer
  }))

  const handleCorrectTest = async () => {
    const solvedQuestions: SolvedQuestion[] = getSolvedQuestions()

    const test: any = await saveTest(solvedQuestions)

    setTestId(test.data.createUserTest._id as string)
  }

  const startFromModal = (filters: Filter) => {
    setTestFilters({ ...filters, time: filters.time! })
    setModalType(undefined)
  }

  const onSelectTest = (test: ITest) => {
    setSelectedTest(test)
    setModalType(undefined)
  }

  useEffect(() => {
    const resolveTest = async () => {
      if (!timeLeft) {
        setShowResult(true)
        setShowFinishedModal(true)
        await handleCorrectTest()
      }
    }

    resolveTest()
      .catch(console.log)
  }, [timeLeft])

  useEffect(() => {
    if (questions && questions.length > 0) {
      setTestQuestions(questions)
      start()
    }
  }, [questions])

  useEffect(() => {
    if (selectedTest?.questions && selectedTest.questions.length > 0) {
      setTestQuestions(selectedTest.questions)
      start()
    }
  }, [selectedTest])

  const onGoBack = () => {
    setGoBack(true)
  }

  const setAnswer = ({ question, value }: SetAnswer) => {
    setUserQuestions((prev) => prev.map((userQuestion) => {
      if (userQuestion._id === question) {
        return ({
          ...userQuestion,
          userAnswer: value
        })
      }
      return userQuestion
    }))
  }

  if (goBack) {
    return <Navigate to={PROTECTED_ROUTES.START_TEST} />
  }

  return (
    <Screen hideLabel id="testpage" title={`Test ${paramType === 'random' ? 'Aleatorio' : ''}`}>
        <div>
          {!doneTestId && (
            <div className='topbar-info'>
            {timeLeft
              ? (
                  <Countdown timeLeft={timeLeft}/>
                )
              : null}
              {!showResult
                ? (
                <Button type='primary' onClick={() => setShowResult(true)}>
                  Corregir examen
                </Button>
                  )
                : null}

            </div>
          )}
          {(doneTestId && loadingSolved)
            ? (
              <div className='loading-state'>
              <Loader size={100}/>
            </div>
              )
            : null}
          {loadingQuestions && (
            <div className='loading-state'>
              <Loader size={100}/>
            </div>
          )}
          <div className='questions'>
            {userQuestions && userQuestions.length > 0
              ? (
                  userQuestions.map((question, idx) => (
                    <Question
                      userTest={testId}
                      onChange={setAnswer}
                      showAnswer={showResult}
                      showCorrect={!!doneTestId}
                      index={idx + 1}
                      question={question}
                      key={question._id}
                    />
                  ))
                )
              : null}
          </div>
          <div className='questions'>
            {solvedQuestions && solvedQuestions.length > 0
              ? (
                  solvedQuestions.map((question, idx) => (
                    <Question
                      failed={question.failed}
                      userTest={testId}
                      showCorrect={!!doneTestId}
                      onChange={setAnswer}
                      showAnswer={!!(showResult)}
                      index={idx + 1}
                      question={question.question}
                      key={question._id}
                    />
                  ))
                )
              : null}
          </div>
        </div>
        <GenerateTestModal visible={modalType === 'random'} onCancel={onGoBack} onStart={startFromModal} />
        <SelectTestModal visible={modalType === 'manual'} onCancel={onGoBack} onStart={startFromModal} onSelectTest={onSelectTest} />
        {showFinishedModal
          ? (
          <FinishedTestModal onClose={toggleFinishedModal} loading={loading} userTest={userTest} penalty={selectedTest?.branch?.penalty ?? branch?.penalty}/>
            )
          : null}
    </Screen>
  )
}

export { TestPage }
