import { Spin, Table } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { Screen } from '../../components'
import { getColumns } from './constants'
import { useGetTeachers } from '../../services/teacher'
import { ManageTeachers, RemoveTeachers } from './components/modal/ManageTeachers'
import { useCallback, useState } from 'react'
import { type TTeacher } from '../../types/teacher'

const TeachersPage = () => {
  const [openManageTeachers, setOpenManageTeachers] = useState<boolean>(false)
  const [openRemoveTeachers, setOpenRemoveTeachers] = useState<boolean>(false)
  const [selectedTeacher, setSelectedTeacher] = useState<TTeacher | undefined>(undefined)
  const { loading, teachers, refetch } = useGetTeachers()

  const toogleManageTeachers = useCallback(() => setOpenManageTeachers((prevOpenManageTeachers) => !prevOpenManageTeachers), [])
  const toogleRemoveTeachers = useCallback(() => setOpenRemoveTeachers((prevOpenRemoveTeachers) => !prevOpenRemoveTeachers), [])

  const closeManageTeacher = async () => {
    setSelectedTeacher(undefined)
    toogleManageTeachers()
    await refetch()
  }

  const closeRemoveTeacher = async () => {
    setSelectedTeacher(undefined)
    toogleRemoveTeachers()
    await refetch()
  }

  const EditTeacher = (teacher: TTeacher) => {
    setSelectedTeacher(teacher)
    toogleManageTeachers()
  }

  const RemoveTeacher = (teacher: TTeacher) => {
    setSelectedTeacher(teacher)
    toogleRemoveTeachers()
  }

  const columns = getColumns({ onEdit: EditTeacher, onRemove: RemoveTeacher })

  return (
    <Screen id="Teachers" title="Maestros" actions={[{ icon: <PlusOutlined />, onClick: toogleManageTeachers }]}>
      {loading && <Spin tip="Loading" size="large" />}
      {!loading && (
        <Table locale={{ emptyText: 'No hay maestros que mostrar' }} columns={columns} dataSource={teachers} rowKey="_id" />
      )}

      <ManageTeachers isOpen={openManageTeachers} onCancel={closeManageTeacher} title={`${selectedTeacher ? 'Actualizar' : 'Crear'} Profesor`} teacher={selectedTeacher} />
      <RemoveTeachers isOpen={openRemoveTeachers} onCancel={closeRemoveTeacher} teacher={selectedTeacher}/>
    </Screen>
  )
}

export { TeachersPage }
