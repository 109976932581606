import { useMutation, useQuery } from '@apollo/client'
import { GET_BRANCH_FORUM_QUESTIONS } from './queries'
import { useBranch } from '../../hooks/useBranch'
import { type IForumQuestion } from '../../types/forumQuestion'
import { CREATE_FORUM_ANSWER, CREATE_FORUM_QUESTION, DELETE_FORUM_QUESTION } from './mutations'
import { useUser } from '../../hooks/useUser'

export const useGetAllBranchForumQuestions = () => {
  const { branch } = useBranch()

  const { data, loading, refetch } = useQuery(GET_BRANCH_FORUM_QUESTIONS, {
    variables: {
      input: branch?._id
    },
    skip: !branch?._id
  })

  return {
    questions: data?.getAllForumQuestionsByBranch as IForumQuestion[],
    loading,
    refetch
  }
}

interface CreateFQParams {
  title: string
  content: string
}

export const useCreateForumQuestion = () => {
  const { branch } = useBranch()
  const { user } = useUser()

  const [action, { loading }] = useMutation(CREATE_FORUM_QUESTION)

  const createForumQuestion = async (params: CreateFQParams) => {
    await action({
      variables: {
        input: {
          branch: branch?._id,
          title: params.title,
          content: params.content,
          user: user?._id,
          important: false
        }
      }
    })
  }

  return {
    createForumQuestion,
    loading
  }
}

export const useRemoveForumQuestion = () => {
  const [action, { loading }] = useMutation(DELETE_FORUM_QUESTION)

  const deleteForumQuestion = async (input: string) => {
    await action({
      variables: { input }
    })
  }

  return {
    deleteForumQuestion,
    loading
  }
}

interface CreateFAParams {
  content: string
  forumQuestion: string
}

export const useCreateForumAnswer = () => {
  const { user } = useUser()

  const [action, { loading }] = useMutation(CREATE_FORUM_ANSWER)

  const createForumAnswer = async (params: CreateFAParams) => {
    await action({
      variables: {
        input: {
          content: params.content,
          user: user?._id,
          forumQuestion: params.forumQuestion
        }
      }
    })
  }

  return {
    createForumAnswer,
    loading
  }
}
