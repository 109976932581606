import { gql } from '@apollo/client'

export const UPDATE_TEACHER = gql`
mutation updateTeacher($input:UpdateTeacherInput!){
    updateTeacher(input:$input){
      _id
      active
      name
      street
      color
      phone
    }
  }
`

export const CREATE_TEACHER = gql`
mutation createTeacher($input:TeacherInput!){
    createTeacher(input:$input){
      active
      name
      street
      color
      phone
    }
  }
`

export const DEACTIVATE_TEACHER = gql`
mutation removeTeacher($input:String!){
  removeTeacher(input:$input){
   _id
  }
}
`
