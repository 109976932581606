import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { type IQuestion } from '../../types/question'
import { GET_FILTERED_QUESTIONS, GET_QUESTIONS, GET_QUESTIONS_PREPARED_TEST } from './queries'
import { CREATE_QUESTION, CREATE_QUESTIONS, REMOVE_QUESTION, UPDATE_QUESTION } from './mutations'
import { useBranch } from '../../hooks/useBranch'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useState } from 'react'
import { useAuth } from '../../hooks/useAuth'

interface GetQuestionResponse {
  getQuestionsByBranch: IQuestion[]
}
export const useGetQuestions = () => {
  const { branch } = useBranch()
  const { data, loading, refetch } = useQuery<GetQuestionResponse>(GET_QUESTIONS, {
    fetchPolicy: 'network-only',
    variables: {
      input: branch?._id
    }
  })

  return {
    questions: data?.getQuestionsByBranch,
    loading,
    refetch
  }
}
interface GetFilteredResponse {
  getQuestionsForTest: IQuestion[]
  getQuestionsForPreparedTest: IQuestion[]
}
export interface Filter {
  rating?: number
  ownRating?: number
  user?: string
  pastExam?: boolean
  onlyFailed?: boolean
  subjects?: string[]
  topics?: string[]
  numberOfQuestions: number
  time?: number
}
export const useGetFilteredQuestions = (input: Filter) => {
  const { branch } = useBranch()
  const { user } = useAuth()
  const { data, loading, refetch } = useQuery<GetFilteredResponse>(GET_FILTERED_QUESTIONS, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        ownRating: input?.ownRating,
        rating: input?.rating,
        branch: branch?._id,
        numberOfQuestions: input?.numberOfQuestions,
        onlyFailed: input?.onlyFailed,
        pastExam: input?.pastExam,
        subjects: input?.subjects || [],
        topics: input?.topics || [],
        user: user?._id
      }
    },
    skip: !input
  })

  return {
    questions: data?.getQuestionsForTest,
    loading,
    refetch
  }
}

interface PreparedTestInput {
  title?: string
  skip: number
  limit: number
  subject: string
}

export const useGetQuestionsPreparedTest = () => {
  const { branch } = useBranch()
  const [getQuestions, { loading: loadingQuestions, error }] = useLazyQuery<GetFilteredResponse>(GET_QUESTIONS_PREPARED_TEST)
  const [questions, setQuestions] = useState<IQuestion[]>([])

  const getQuestionsParams = async (input: PreparedTestInput) => {
    const request = {
      variables: {
        input: {
          title: input.title,
          skip: input.skip,
          limit: input.limit,
          branch: branch?._id,
          subject: input?.subject
        }
      }
    }
    const result = await getQuestions(request)
    const options = [...result.data?.getQuestionsForPreparedTest as IQuestion[]]

    setQuestions((prev) => [...prev, ...options])
  }

  return {
    getQuestionsParams,
    loadingQuestions,
    questions,
    error
  }
}

export const useCreateQuestion = () => {
  const { branch } = useBranch()
  const [action, { loading }] = useMutation(CREATE_QUESTION)

  const createQuestion = async (input: IQuestion) => {
    await action({ variables: { input: { ...input, branch: branch?._id, active: true } } })
  }

  return { createQuestion, loading }
}

export const useCreateQuestions = () => {
  const { branch } = useBranch()
  const [action, { loading }] = useMutation(CREATE_QUESTIONS)

  const createQuestions = async (input: IQuestion[]) => {
    await action({
      variables: {
        input: input.map((i) => ({ ...i, branch: branch?._id }))
      }
    }).then(({ data }) => {
      const guardadas: number = data.createQuestions.length
      toast.success(`Se han guardado ${guardadas} preguntas!`, {
        position: 'top-right',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      })
    }).catch(err => {
      toast.error(`${err}`, {
        position: 'top-right',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
      })
    })
  }

  return { createQuestions, loading }
}

export const useUpdateQuestion = () => {
  const { branch } = useBranch()
  const [action, { loading }] = useMutation(UPDATE_QUESTION)

  const updateQuestion = async (input: IQuestion) => {
    await action({
      variables: {
        input: {
          ...input,
          branch: branch?._id,
          active: true
        }
      }
    })
  }

  return { updateQuestion, loading }
}

export const useRemoveQuestion = () => {
  const [action, { loading }] = useMutation(REMOVE_QUESTION)

  const removeQuestion = async (input: string) => {
    await action({ variables: { input } })
  }

  return { removeQuestion, loading }
}
