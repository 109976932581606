import { Button, Checkbox, Modal } from 'antd'
import { Box, Input } from '../../../../components'

import './Question.scss'
import { useState, type FC } from 'react'
import clsx from 'clsx'
import { type IQuestionAndAnswer, type SetAnswer } from '../../TestPage'
import { IconInfoCircle, IconStar, IconStarFilled } from '@tabler/icons-react'
import { useSaveQuestion } from '../../../../services/userQuestion'
import { useModal } from '../../../../hooks/useModal'
import { useCreateRatedQuestion } from '../../../../services/ratedQuestion'
import { useCreateImpugnatedQuestion } from '../../../../services/impugnatedQuestion'
import { useHideQuestion } from '../../../../services/user'

interface Props {
  failed?: boolean
  index: number
  onChange: (val: SetAnswer) => void
  question: IQuestionAndAnswer
  showAnswer?: boolean
  showCorrect?: boolean
  userTest?: string
}

const Question: FC<Props> = ({ failed, index, onChange, question, showAnswer, showCorrect, userTest }) => {
  const [rated, setRated] = useState(false)
  const { saveQuestion, loading } = useSaveQuestion()
  const { createRatedQuestion, loading: loadingRate } = useCreateRatedQuestion()
  const { createImpugnation, loading: impugnating } = useCreateImpugnatedQuestion()
  const { hideQuestion, loading: hidding } = useHideQuestion()

  const [hidden, setHidden] = useState(false)
  const [saved, setSaved] = useState(false)
  const [selectedRating, setSelectedRating] = useState(0)
  const [comment, setComment] = useState('')
  const [impugReason, setImpugReason] = useState('')
  const [impugDone, setImpugDone] = useState(false)

  const { visible, toggleModal } = useModal()
  const { visible: modalComment, toggleModal: toggleModalComment } = useModal()
  const { visible: modalImpugnate, toggleModal: toggleModalImpugnate } = useModal()

  const handleChange = (checked: boolean, answer: string) => {
    if (checked) {
      onChange({
        question: question._id!,
        value: answer
      })
    } else {
      onChange({
        question: question._id!,
        value: undefined
      })
    }
  }

  const handleSaveQuestion = async () => {
    if (userTest) {
      const savedQuestion = await saveQuestion({
        question: question._id!,
        userTest,
        comment: comment || '-'
      })

      if (savedQuestion?._id) {
        toggleModalComment()
        setSaved(true)
      }
    }
  }

  const handleRateQuestion = async () => {
    await createRatedQuestion({
      question: question._id!,
      rating: selectedRating
    })

    setRated(true)
    toggleModal()
  }

  const handleImpugQuestion = async () => {
    await createImpugnation({
      reason: impugReason,
      question: question._id!
    })
    toggleModalImpugnate()
    setImpugDone(true)
  }

  const getCheckboxClassname = (anw: string) => {
    const isCorrect = anw === question.correctAnswer

    if (!showAnswer) return ''
    if (isCorrect) return 'correct-answer'
    return 'wrong-answer'
  }

  const handleHideQuestion = async () => {
    await hideQuestion(question._id!)

    setHidden(true)
  }

  const getShowCorrect = (answer: string) => {
    if (showCorrect) return answer === question.correctAnswer

    return question.userAnswer === answer
  }

  return (
    <div className="question">
        <Box>
          {(showAnswer) && (
              <div className={clsx('is-correct', ((question.userAnswer === question.correctAnswer)) ? 'correct' : 'wrong')} />
          )}
          {(failed !== undefined) && (
              <div className={clsx('is-correct', (!failed) ? 'correct' : 'wrong')} />
          )}

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className='answers'>
              <div className='question-number'>
                <div><p>{`${index}.- `}</p></div>
                <p className='question-title'>{question.title}</p>
            </div>
                  {question.answers.map((anw, index) => (
                      <Checkbox
                          className={getCheckboxClassname(anw)}
                          disabled={showAnswer || failed !== undefined}
                          key={anw}
                          checked={getShowCorrect(anw)}
                          onChange={(e) => handleChange(e.target.checked, anw)}
                      >
                          {`${index + 1}.- ${anw}`}
                      </Checkbox>
                  ))}
              </div>
              <div>
                <img className='question-image' src={question.image} />
              </div>
            </div>
            {question.info && showAnswer && (
              <div className="info">
                <IconInfoCircle color="white" />
                <p>{question.info}</p>
              </div>
            )}
            {showAnswer && (
              <div className="test-actions">
                <Button disabled={impugDone} type='primary' loading={loadingRate} onClick={toggleModalImpugnate} className='test-action'>
                  Inpugnar pregunta
                </Button>
                <Button type='primary' loading={loadingRate} onClick={toggleModal} className='test-action' disabled={rated}>
                  {rated ? 'Valorada' : 'Valorar dificultad'}
                </Button>
                <Button type='primary' disabled={saved} onClick={toggleModalComment} className='test-action ' loading={loading}>
                  {saved ? 'Pregunta guardada' : 'Guardar pregunta'}
                </Button>
                <Button type='primary' disabled={hidden} onClick={handleHideQuestion} className='test-action ' loading={hidding}>
                  {hidden ? 'Pregunta oculta' : 'Ocultar pregunta'}
                </Button>
              </div>
            )}
        </Box>
        <Modal
            open={modalImpugnate}
            onCancel={toggleModalImpugnate}
            onOk={handleImpugQuestion}
            okButtonProps={{ loading: impugnating, disabled: !impugReason }}
            okText="Enviar"
            title="Impugnar pregunta"
        >
            <p style={{ marginBottom: 20 }}>Escribe el motivo por el cual crees que esta pregunta debería ser impugnada para que nuestros profesores puedas valorarlo. Muchas gracias por tu ayuda!</p>
              <Input placeholder='Comentario' value={impugReason} onChange={(e) => setImpugReason(e.target.value)} />
        </Modal>
        <Modal
            open={modalComment}
            onCancel={toggleModalComment}
            okButtonProps={{ loading }}
            onOk={handleSaveQuestion}
            okText="Guardar"
            title="Añadir comentario"
        >
            <p style={{ marginBottom: 20 }}>Añade un comentario con información que pueda serte útil de cara al futuro (no es obligatorio)</p>
              <Input placeholder='Comentario' value={comment} onChange={(e) => setComment(e.target.value)} />
        </Modal>
        <Modal
            open={visible}
            onCancel={toggleModal}
            okButtonProps={{
              loading: loadingRate
            }}
            onOk={handleRateQuestion}
            okText="Guardar valoración"
            title="Valorar pregunta"
        >
            <p>Valora del 1 al 5 la pregunta teniendo en cuenta su dificultad (1 más fácil, 5 más dificil)</p>
            <div className='rating-content'>
              {[0, 1, 2, 3, 4].map((_, index) => (
                <button onClick={() => setSelectedRating(index + 1)} key={index}>
                  { index + 1 <= selectedRating ? <IconStarFilled/> : <IconStar />}
                </button>
              ))}
            </div>
        </Modal>
    </div>
  )
}

export { Question }
