import { Modal } from 'antd'
import { useState, type FC, useEffect } from 'react'
import { type IBranch } from '../../../../types/branch'
import { Input, InputFile } from '../../../../components'
import { useCreateBranch, useUpdateBranch } from '../../../../services/branch'

interface Props {
  selectedBranch?: IBranch
  open: boolean
  onClose: () => void
  title: string
}

const BranchModal: FC<Props> = ({ open, onClose, selectedBranch, title }) => {
  const { createBranch, loading: createLoading } = useCreateBranch()
  const { updateBranch, loading: updateLoading } = useUpdateBranch()

  const [editedName, setEditedName] = useState<string>('')
  const [editedPenalty, setEditedPenalty] = useState<number>(0.5)
  const [editedTestTime, setEditedTestTime] = useState<number>(0)
  const [editedImage, setEditedImage] = useState<string>('')

  useEffect(() => {
    if (selectedBranch) {
      setEditedName(selectedBranch?.name)
      setEditedImage(selectedBranch?.image)
      setEditedPenalty(selectedBranch?.penalty)
      setEditedTestTime(selectedBranch?.testtime)
    }
  }, [selectedBranch])

  const handleClose = () => {
    setEditedName('')
    setEditedImage('')
    setEditedPenalty(0.5)
    setEditedTestTime(0)
    onClose()
  }

  const handleSave = async () => {
    if (selectedBranch?._id) {
      await updateBranch({
        _id: selectedBranch._id,
        name: editedName,
        penalty: editedPenalty,
        image: editedImage,
        testtime: editedTestTime
      })
    } else {
      await createBranch({
        name: editedName,
        penalty: editedPenalty,
        image: editedImage,
        testtime: editedTestTime
      })
    }
    handleClose()
  }

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      onOk={handleSave}
      okButtonProps={{
        disabled: !editedImage || !editedName,
        loading: createLoading || updateLoading
      }}
      okText="Guardar"
      title={title}
    >
      <Input value={editedName} onChange={(e) => { setEditedName(e.target.value) }} placeholder='Nombre' />
      <InputFile type='image' name='Imagen' fallbackUrl={(url, type) => setEditedImage(url)} />
      <Input value={editedPenalty} type='number' min={0.5} max={1} step={0.5} onChange={(e) => { setEditedPenalty(parseFloat(e.target.value)) }} placeholder='Penalización' />
      <Input value={editedTestTime} type='number' min={30} max={60} step={30} onChange={(e) => { setEditedTestTime(parseInt(e.target.value)) }} placeholder='Tiempo Tests' />
    </Modal>
  )
}

export { BranchModal }
