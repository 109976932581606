import { useMutation, useQuery } from '@apollo/client'
import { useBranch } from '../../hooks/useBranch'
import { GET_IMPUGNATED_QUESTIONS } from './queries'
import { type IImpugnatedQuestion } from '../../types/impugnatedquestion'
import { ACCEPT_IMPUGANTION, IMPUGNATE_QUESTION, REJECT_IMPUGNATION } from './mutations'
import { useAuth } from '../../hooks/useAuth'

export const useGetImpugnatedQuestions = () => {
  const { branch } = useBranch()

  const { data, loading, refetch } = useQuery(GET_IMPUGNATED_QUESTIONS, {
    fetchPolicy: 'no-cache',
    variables: {
      input: branch?._id
    }
  })

  return { refetch, questions: (data?.getAllImpugnatedquestionsByBranch ?? []) as IImpugnatedQuestion[], loading }
}

export const useAcceptImpugnation = () => {
  const [action, { data, loading }] = useMutation(ACCEPT_IMPUGANTION)

  const acceptImpugnation = async (input: string) => {
    const response = await action({ variables: { input } })

    return response.data.acceptImpugnateRequest as string
  }

  return { loading, acceptImpugnation, data }
}

interface ImpugnateQuestion {
  question: string
  reason: string
}

export const useCreateImpugnatedQuestion = () => {
  const { user } = useAuth()
  const { branch } = useBranch()
  const [action, { data, loading }] = useMutation(IMPUGNATE_QUESTION)

  const createImpugnation = async (input: ImpugnateQuestion) => {
    const response = await action({
      variables: {
        input: {
          ...input,
          user: user?._id,
          branch: branch?._id
        }
      }
    })

    return response.data.acceptImpugnateRequest as string
  }

  return { loading, createImpugnation, data }
}

export const useRejectImpugnation = () => {
  const [action, { data, loading }] = useMutation(REJECT_IMPUGNATION)

  const rejectImpugnation = async (input: string) => {
    const response = await action({ variables: { input } })

    return response.data.removeImpugnatedQuestion as string
  }

  return { loading, rejectImpugnation, data }
}
