import { useEffect, useState } from 'react'

export const useCountdown = (time: number, end?: boolean) => {
  const [started, setStarted] = useState(false)
  const [timeLeft, setTimeLeft] = useState(time * 60)

  useEffect(() => {
    setTimeLeft(time * 60)
  }, [time])

  useEffect(() => {
    let interval: NodeJS.Timer

    if (started) {
      interval = setInterval(() => {
        setTimeLeft((prev) => prev - 1)
      }, 1000)

      if (timeLeft === 0 || end) {
        clearInterval(interval)
        setTimeLeft(0)
      }
    }

    return () => clearInterval(interval)
  }, [end, started, timeLeft])

  return {
    start: () => setStarted(true),
    timeLeft
  }
}
