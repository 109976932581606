import { Modal } from 'antd'
import { useState, type FC, useEffect, useMemo } from 'react'
import { Input, Select } from '../../../../components'
import { parseSelect } from '../../../../utils/parseSelect'
import { type ITopic } from '../../../../types/topic'
import { useCreateTopic, useUpdateTopic } from '../../../../services/topic'
import { type ISubject } from '../../../../types/subject'

interface Props {
  onClose: () => void
  open: boolean
  selectedTopic?: ITopic
  subjects: ISubject[]
  title: string
}

const TopicsModal: FC<Props> = ({ open, onClose, selectedTopic, subjects, title }) => {
  const { createTopic, loading: createLoading } = useCreateTopic()
  const { updateTopic, loading: updateLoading } = useUpdateTopic()

  const [editedSubject, setEditedSubject] = useState<string>('')
  const [editedName, setEditedName] = useState<string>('')
  const [editedColor, setEditedColor] = useState<string>('#000000')

  useEffect(() => {
    if (selectedTopic?.branch) {
      setEditedSubject((selectedTopic.subject as ISubject)._id!)
      setEditedName(selectedTopic?.name)
      setEditedColor(selectedTopic?.color)
    }
  }, [selectedTopic])

  const handleClose = () => {
    setEditedName('')
    setEditedSubject('')
    setEditedColor('')
    onClose()
  }

  const handleSave = async () => {
    if (selectedTopic?._id) {
      await updateTopic({
        _id: selectedTopic._id,
        subject: editedSubject,
        color: editedColor || '#000000',
        name: editedName
      })
    } else {
      await createTopic({
        subject: editedSubject,
        color: editedColor || '#000000',
        name: editedName
      })
    }
    handleClose()
  }

  const parsedSubjects = useMemo(() => parseSelect({
    array: subjects,
    labelKey: 'name',
    valueKey: '_id'
  }), [subjects])

  return (
    <Modal
        open={open}
        onCancel={handleClose}
        onOk={handleSave}
        okButtonProps={{
          disabled: !editedName,
          loading: createLoading || updateLoading
        }}
        okText="Guardar"
        title={title}
    >
        <Select
          placeholder="Bloque"
          onChange={setEditedSubject}
          options={parsedSubjects}
          value={editedSubject}
        />
        <Input value={editedName} onChange={(e) => { setEditedName(e.target.value) }} placeholder='Nombre'/>
        <Input type='color' value={editedColor} onChange={(e) => { setEditedColor(e.target.value) }} placeholder='Color'/>
    </Modal>
  )
}

export { TopicsModal }
