import { useMutation, useQuery } from '@apollo/client'
import { useAuth } from '../../hooks/useAuth'
import { GET_DASHBOARD_DATA, GET_FAILED_QUESTIONS, GET_SAVED_QUESTIONS, GET_USER_QUESTIONS_BY_USER_TEST } from './queries'
import { type IUser } from '../../types/user'
import { type IQuestion } from '../../types/question'
import { SAVE_QUESTION } from './mutations'
import { type IUserQuestion } from '../../types/userQuestion'

interface FailedQuestionsResponse {
  getAllUserFailedByUser: Array<{
    _id: string
    user: IUser
    question: IQuestion
  }>
}

export const useGetFailedQuestions = () => {
  const { user } = useAuth()
  const { data, loading } = useQuery<FailedQuestionsResponse>(GET_FAILED_QUESTIONS, {
    fetchPolicy: 'no-cache',
    variables: {
      input: user?._id
    }
  })

  return { questions: data?.getAllUserFailedByUser ?? [], loading }
}

export const getPercentageCorrect = (questions: any) => {
  const totalA = questions.failed.length + questions.correct.length

  return Math.floor((questions.correct.length * 100) / totalA)
}

export const useGetDashboardData = () => {
  const { user } = useAuth()
  const { data, loading } = useQuery<any>(GET_DASHBOARD_DATA, {
    fetchPolicy: 'no-cache',
    variables: {
      input: user?._id
    }
  })

  const groupedData = (data?.getDashboardUserData || []).reduce((acc: any, item: any) => {
    if (!acc[item.question.topic.name]) {
      acc[item.question.topic.name] = { failed: [], correct: [] }
    }
    if (item.failed) {
      acc[item.question.topic.name].failed.push(item)
    } else {
      acc[item.question.topic.name].correct.push(item)
    }

    return acc
  }, {})

  const dashboardData = Object.entries(groupedData).sort((a: any, b: any) => {
    const totalA = a[1].failed.length + a[1].correct.length
    const totalB = b[1].failed.length + b[1].correct.length

    const correctA = (a[1].correct.length * 100) / totalA
    const correctB = (b[1].correct.length * 100) / totalB

    const x = correctA
    const y = correctB
    // eslint-disable-next-line no-nested-ternary
    return ((x < y) ? -1 : ((x > y) ? 1 : 0))
  })

  return { dashboardData, loading }
}

export const useGetSavedQuestions = () => {
  const { user } = useAuth()
  const { data, loading } = useQuery(GET_SAVED_QUESTIONS, {
    fetchPolicy: 'no-cache',
    variables: {
      input: user?._id
    }
  })

  return { questions: (data?.getAllQuestionsSavedByUser ?? []) as IUserQuestion[], loading }
}

interface SaveQuestionParams {
  question: string
  userTest: string
  comment: string
}

export const useSaveQuestion = () => {
  const { user } = useAuth()
  const [action, { data, loading }] = useMutation(SAVE_QUESTION)

  const saveQuestion = async ({ comment, question, userTest }: SaveQuestionParams) => {
    const response = await action({
      variables: {
        input: {
          comment,
          user: user?._id,
          question,
          userTest,
          failed: false,
          saved: true
        }
      }
    })

    return response.data.saveUserQuestion as IUserQuestion
  }

  return { loading, saveQuestion, data }
}

export const useGetUserQuestionsBtUserTest = (input: string | null) => {
  const { data, loading } = useQuery(GET_USER_QUESTIONS_BY_USER_TEST, {
    fetchPolicy: 'no-cache',
    variables: {
      input
    },
    skip: !input
  })

  return { questions: (data?.getUserQuestionsByUserTest ?? []) as IUserQuestion[], loading }
}
