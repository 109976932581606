import { useContext, type FC, type PropsWithChildren } from 'react'
import clsx from 'clsx'

import './Screen.scss'
import { Sidebar } from '../Sidebar'
import { Title } from '../Title'
import { Actions } from '../Actions'
import { ConfigProvider, theme } from 'antd'

import esES from 'antd/locale/es_ES'
import { AuthContext, Theme } from '../../contexts/AuthContext'

interface Action {
  disabled?: boolean
  icon: any
  onClick: () => void
}

type Props = PropsWithChildren<{
  actions?: Action[]
  dark?: boolean
  id: string
  sidebar?: boolean
  title?: string
  hideLabel?: boolean
}>

const Screen: FC<Props> = ({ hideLabel, actions, children, dark = false, id, sidebar = true, title }) => {
  const { theme: th } = useContext(AuthContext)

  return (
    <ConfigProvider
      locale={esES}
      theme={{
        algorithm: theme.darkAlgorithm,
        components: {
          Pagination: {
            algorithm: true,
            itemBg: '#ffff'
          }
        }
      }}
    >
      <div className={clsx('screen', dark && 'dark', th === Theme.light ? 'light-mode' : '')} id={id}>
        {sidebar && (
          <Sidebar />
        )}
        <div className={clsx('content', sidebar && 'with-sidebar')}>
          {title && (
            <Title>{title}</Title>
          )}
          <div className="screen-content">
            {children}
          </div>
        </div>
        {!hideLabel
          ? (
            <div className={clsx('csoapps', dark && 'is-dark')}>
              <p><span>CrossFactory</span> powered by <a href="https://unbrokenapps.com" target='_blank' rel="noreferrer">UNBROKEN</a></p>
            </div>
            )
          : null}
        {actions && actions.length > 0
          ? (
            <div className='actions'>
              <Actions actions={actions} />
            </div>
            )
          : null}
      </div>
    </ConfigProvider>

  )
}

export { Screen }
