import { Box, Screen } from '../../components'
import { PROTECTED_ROUTES } from '../../constants/routes'
import { useUser } from '../../hooks/useUser'

import './AdminPage.scss'

const AdminPage = () => {
  const { isAdmin } = useUser()
  return <Screen id="admin-page" title="Administración">
        <div className='start-content'>
            <div className='admin-content'>
                {isAdmin && <Box
                    actions={[{ label: 'Administrar', onClick: () => true, type: 'primary', link: PROTECTED_ROUTES.ADMIN_BRANCHES }]}
                    description='Administración de ramas. Cada rama es individual y contiene sus propios bloques.'
                    title='Ramas'
                />}
                {isAdmin && <Box
                    actions={[{ label: 'Administrar', onClick: () => true, type: 'primary', link: PROTECTED_ROUTES.ADMIN_SUBJECTS }]}
                    description='Administración de bloques. Cada bloque es individual y contiene sus propios temas.'
                    title='Bloques'
                >
                </Box>}
                <Box
                    actions={[{ label: 'Administrar', onClick: () => true, type: 'primary', link: PROTECTED_ROUTES.ADMIN_TOPICS }]}
                    description="Administración de temas. Contiene sus propias preguntas."
                    title='Temas'
                />

            </div>
            <div className='admin-content'>
                <Box
                    actions={[{ label: 'Administrar', onClick: () => true, type: 'primary', link: PROTECTED_ROUTES.ADMIN_QUESTIONS }]}
                    description='Administración de preguntas. Crealas para asiganarlas después a tests. También podrán salir en tests aleatorios.'
                    title='Preguntas'
                />
                <Box
                    actions={[{ label: 'Administrar', onClick: () => true, type: 'primary', link: PROTECTED_ROUTES.ADMIN_SESSIONS }]}
                    description='Administración de eventos'
                    title='Eventos'
                />
                {isAdmin && <Box
                    actions={[{ label: 'Administrar', onClick: () => true, type: 'primary', link: PROTECTED_ROUTES.ADMIN_TEACHERS }]}
                    description='Administración de maestros. Permite asignarlos a clases para que los alumnos sepan quien la impartirá.'
                    title='Maestros'
                >
                </Box>}
            </div>
            <div className='admin-content'>
                {isAdmin && <Box
                    actions={[{ label: 'Administrar', onClick: () => true, type: 'primary', link: PROTECTED_ROUTES.USERS }]}
                    description='Administración de usuarios. Son los que consumirán el contenido de la plataforma.'
                    title='Usuarios'
                />}
                {(isAdmin || isAdmin) && <Box
                    actions={[{ label: 'Administrar', onClick: () => true, type: 'primary', link: PROTECTED_ROUTES.ADMIN_SUSCRIPTIONS }]}
                    description='Administración de suscripciones. Dale acceso al contenido a tus alumnos'
                    title='Suscripciones'
                />}
                <Box
                    actions={[{ label: 'Administrar', onClick: () => true, type: 'primary', link: PROTECTED_ROUTES.ADMIN_TESTS }]}
                    description='Aquí podrás preparar tests para que los hagan tus alumnos.'
                    title='Tests preparados'
                />
            </div>
        </div>
    </Screen>
}

export { AdminPage }
