import { gql } from '@apollo/client'

export const GET_ALL_ONLINE_SESSIONS = gql`
    query getAllOnlineSessions($input: String!) {
        getAllOnlineSessions(input: $input) {
            _id
            title
            url
            createdAt
            subject {
                name
            }
            topic {
                name
            }
        }
    }
`
