import { Modal, Spin, Table } from 'antd'
import { PlusOutlined, FileAddOutlined } from '@ant-design/icons'

import { getColumns } from './constants'
import { Input, Screen } from '../../components'
import { useEffect, useMemo, useState } from 'react'
import { getAllTests, useRemoveTest } from '../../services/test'
import { type ITest } from '../../types/test'
import { TestModal } from './components/TestModal'
import { useModal } from '../../hooks/useModal'
import { useGetQuestionsPreparedTest } from '../../services/question'

import './AdminTestsPage.scss'
import { Button } from './components/Button'
import { useGetTeachers } from '../../services/teacher'
import { AddTestModal } from './components/AddModal/AddTestModal'

let observer: IntersectionObserver
const AdminTestsPage = () => {
  const { tests, loading, refetch } = getAllTests()
  const { removeTest, loading: removeLoading } = useRemoveTest()

  const [selectedSubject, setSelectedSubject] = useState('')
  const [skip, setSkip] = useState(0)
  const [limit] = useState(100)
  const [title, setTitle] = useState('')
  const [selectedTest, setSelectedTest] = useState<ITest>()

  const [selectedQuestions, setSelectedQuestions] = useState<string[]>([])

  const { getQuestionsParams, questions } = useGetQuestionsPreparedTest()
  const { teachers } = useGetTeachers()

  const {
    closeModal: closeRemoveModal,
    toggleModal: toggleRemoveModal,
    visible: removeModal
  } = useModal()
  const { toggleModal, visible: modal, closeModal } = useModal()
  const { toggleModal: toggleQuestionsModal, visible: questionsModal } = useModal()
  const { toggleModal: addTestModalToggle, visible: addTestModalOpen } = useModal()

  const onClose = async () => {
    setSelectedTest(undefined)
    setSelectedQuestions([])
    closeModal()
    closeRemoveModal()
    await refetch()
  }

  const toggleQuestion = (questionId: string) => {
    setSelectedQuestions((prev) => {
      if (prev.includes(questionId)) {
        return prev.filter((q) => q !== questionId)
      }
      return [...prev, questionId]
    })
  }

  const onCloseRemove = () => {
    setSelectedTest(undefined)
    closeRemoveModal()
  }

  const onEditTest = (test: ITest) => {
    setSelectedTest(test)
    setSelectedQuestions(test.questions.map((q) => q._id!))
    toggleModal()
  }

  const onRemoveModal = (test: ITest) => {
    setSelectedTest(test)
    toggleRemoveModal()
  }

  const handleRemove = async () => {
    if (selectedTest?._id) {
      await removeTest(selectedTest?._id)
      await onClose()
    }
  }

  const teacherFilter = useMemo(() => teachers?.map(s => ({ text: s.name, value: s._id as string })), [teachers])

  const columns = getColumns({
    onEdit: onEditTest,
    onRemove: onRemoveModal,
    columnFilter: {
      teacher: teacherFilter ?? []
    }
  })

  const handleSubject = (s: string) => {
    setSelectedSubject(s)
  }

  const handleSearch = (event: { target: HTMLInputElement }) => {
    setTitle(event.target.value)
  }

  useEffect(() => {
    const options = {
      root: document.querySelector('.questions-container'),
      rootMargin: '0px',
      threshold: 1.0
    }

    observer = new IntersectionObserver((entries, observer) => {
      const entry = entries[0]
      if (entry.intersectionRatio > 0.5 && selectedSubject) {
        setSkip((prev) => {
          return prev + limit
        })
        observer.unobserve(entry.target)
      }
    }, options)
  }, [selectedSubject])
  useEffect(() => {
    if (selectedSubject) {
      getQuestionsParams({
        subject: selectedSubject,
        skip, // Cambiar a un numero mas grande o paginar
        limit
      })
    }
  }, [selectedSubject, skip, limit])

  return (
    <Screen
      title="Tests"
      id="admin-tests"
      actions={[
        { icon: <PlusOutlined />, onClick: toggleModal },
        {
          icon: <FileAddOutlined />,
          onClick: addTestModalToggle
        }]}
    >
      {loading && <Spin tip="Loading" size="large" />}
      {!loading && (
        <Table
          locale={{ emptyText: 'No hay tests que mostrar' }}
          columns={columns}
          dataSource={tests}
          rowKey="_id"
        />
      )}
      <TestModal
        openQuestionsModal={toggleQuestionsModal}
        refetch={refetch}
        selectedTest={selectedTest}
        open={modal}
        onClose={onClose}
        title={`${selectedTest ? 'Actualizar' : 'Crear'} Test`}
        selectedQuestions={selectedQuestions}
        setSubject={handleSubject}
        teachers={teachers ?? []}
      />
      <Modal
        open={removeModal}
        onCancel={onCloseRemove}
        okButtonProps={{
          danger: true,
          loading: removeLoading
        }}
        onOk={handleRemove}
        okText="Eliminar Test"
        title="Eliminar tests"
      >
        {`Vas a eliminar el test "${selectedTest?.name}". ¿Estas seguro de que quieres continuar? No se podrá acceder más a él.`}
      </Modal>
      <Modal
        title="Seleccionar preguntas para el test"
        open={questionsModal}
        onCancel={toggleQuestionsModal}
        cancelButtonProps={{
          style: {
            display: 'none'
          }
        }}
        onOk={toggleQuestionsModal}
      >
        <Input placeholder="Buscar pregunta" onChange={handleSearch} />
        <div className="questions-container">
          {selectedSubject &&
            questions
              .filter((q) => {
                if (title) {
                  const regex = new RegExp(title, 'gi')
                  return regex.test(q.title)
                }
                return true
              })
              ?.map((q, i) => (
                <Button
                  key={q._id}
                  observer={observer}
                  q={q}
                  selectedQuestions={selectedQuestions}
                  toggleQuestion={toggleQuestion}
                  isLastItem={questions.length - 1 === i}
                >
                  <p>{q.title}</p>
                </Button>
              ))}
        </div>
      </Modal>
      <AddTestModal onClose={addTestModalToggle} open={addTestModalOpen} teachers={teachers ?? []} refetch={refetch} />
    </Screen>
  )
}

export { AdminTestsPage }
