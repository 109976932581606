import { gql } from '@apollo/client'

export const GETACTIVEUSERS = gql`
query GetActiveUsers{
    getActiveUsers{
      _id
      name
      DNI
      email
      phone
      avatar
      street
      active
      deleted
      role { 
        _id 
        name 
      }
      temarySection
      testSection
    }
  }  
`
