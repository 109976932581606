import { IconCheck, IconX } from '@tabler/icons-react'
import { type FC } from 'react'

import './TestResult.scss'
import clsx from 'clsx'

interface Props {
  name: string
  questions: number
  answered: number
  success: boolean
}

const TestResult: FC<Props> = ({ name, questions, answered, success }) => (
    <div className='test-result'>
        <div className='test-name'>
            <p>{name}</p>
        </div>
        <div className='result'>
            <p className={clsx(success ? 'success' : 'failed')}>{`${answered}/${questions}`}</p>
            {success ? <IconCheck color="green" /> : <IconX color='red'/>}
        </div>
    </div>
)

export { TestResult }
