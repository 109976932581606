import {
  Input as AntInput, type InputProps,
  DatePicker as AntDatePicker, type DatePickerProps,
  Radio as AntRadio, type RadioGroupProps, Button, Checkbox, type CheckboxProps,
  Spin
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import locale from 'antd/es/date-picker/locale/es_ES'
import 'dayjs/locale/es'
import './Input.scss'
import { useRef, type SyntheticEvent } from 'react'
import { useUploadToS3 } from '../../hooks/useUploadToS3'

const Input = ({ placeholder, ...props }: InputProps) => (
  <div className='input'>
    <p className='input-label'>{placeholder}</p>
    <AntInput {...props} />
  </div>
)

const DatePicker = ({ placeholder, ...props }: DatePickerProps) => (
  <div className='input'>
    <p className='input-label'>{placeholder}</p>
    <AntDatePicker {...props} locale={locale} />
  </div>
)

const Radio = ({ name, ...props }: RadioGroupProps) => (
  <div className='input'>
    <p className='input-label'>{name}</p>
    <AntRadio.Group {...props} />
  </div>
)

const Check = ({ name, ...props }: CheckboxProps) => (
  <div className='input'>
    <p className='input-label'>{name}</p>
    <Checkbox {...props} />
  </div>
)

interface InputFileProps {
  reset?: boolean
  name: string
  fallbackUrl: (url: string, type: string) => void
  type: 'image' | 'pdf' | 'any' | 'doc' | 'ppt'
  value?: string
}

const ACCEPTS = {
  pdf: '.pdf',
  image: 'image/*',
  doc: '.doc',
  ppt: '.ppt',
  any: 'image/*,.pdf,.ppt,.pptx,.doc,.docx'
}

const InputFile = ({ value, name, fallbackUrl, type }: InputFileProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const { uploadFile, error, uploading } = useUploadToS3()

  const onClick = (event: SyntheticEvent) => {
    event.stopPropagation()
    event.preventDefault()
    fileInputRef.current?.click()
  }

  const onFileUpload = async (event: SyntheticEvent) => {
    const files = (event.target as HTMLInputElement).files
    if (files) {
      const file = files[0]
      const url = await uploadFile(file)
      fallbackUrl(url!, files[0].type)
    }
  }

  const accepts = ACCEPTS[type]

  return (
    <div className='input'>
      <p className='input-label'>{name}</p>
      <div className="file-uploader">
        <Button onClick={onClick} disabled={uploading}>
          <UploadOutlined />
        </Button>
        {uploading && (
          <div style={{ margin: 5 }}>
            <Spin />
          </div>
        )}
        <input ref={fileInputRef} className='hidden-input-file' type='file' accept={accepts} multiple={false} onChange={onFileUpload} />
        {value && <p>{value}</p>}
      </div>
      {!error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  )
}

export enum Accept {
  text = '.txt',
}
interface InputFilePropsGeneric {
  name: string
  accept: Accept
  multiple: boolean
  returnFiles: (files: FileList) => void
}
const InputFileGeneric = ({ name, returnFiles, accept, multiple }: InputFilePropsGeneric) => {
  const fileInputRef = useRef<HTMLInputElement>(null)

  const onClick = (event: SyntheticEvent) => {
    event.stopPropagation()
    event.preventDefault()
    fileInputRef.current?.click()
  }

  const onFileUpload = async (event: SyntheticEvent) => {
    const files = (event.target as HTMLInputElement).files
    if (files) {
      returnFiles(files)
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
    }
  }

  return (
    <div className='input'>
      <p className='input-label'>{name}</p>
      <Button onClick={onClick}>
        <UploadOutlined />
      </Button>
      <input ref={fileInputRef} className='hidden-input-file' type='file' accept={accept} multiple={multiple} onChange={onFileUpload} />
      {/* <div className='file-loading-bar' style={{ width: `${progress}%` }}></div> */}
    </div>
  )
}

export { Input, DatePicker, Radio, InputFile, Check, InputFileGeneric }
