import { Button, Input, Spin } from 'antd'
import {
  type FC, useEffect, useMemo, useState
} from 'react'
import { useSearchParams } from 'react-router-dom'
import { useResetPassword, useResolveToken } from '../../services/user'

import Logo from '../../assets/cf-logo.png'
import { Screen } from '../../components'

const RestorePasswordScreen: FC = () => {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')

  const { resolveToken } = useResolveToken()
  const { resetPassword, loading: actionLoading } = useResetPassword()

  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [requestError, setRequestError] = useState(false)
  const [userId, setUserId] = useState<string>('')
  const [loading, setLoading] = useState(true)
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  useEffect(() => {
    const checkToken = async () => {
      if (token == null) {
        window.location.href = '/'
      } else {
        const result = await resolveToken(token)

        if (result?._id) {
          setUserId(result?._id)
          setLoading(false)
        }
      }
    }

    checkToken()
  }, [token])

  const handleSave = async () => {
    if (newPassword !== confirmPassword) {
      setError(true)
    } else if (userId) {
      try {
        const user = await resetPassword(userId)

        if (user?._id) {
          setSuccess(true)
          setRequestError(false)
          setError(false)
        }
      } catch (err) {
        setRequestError(true)
      }
    }
  }

  const resetErrors = () => {
    setRequestError(false)
    setError(false)
  }

  useEffect(() => {
    resetErrors()
  }, [newPassword, confirmPassword])

  const disabled = useMemo(() => loading ||
    !newPassword ||
    !confirmPassword, [loading, newPassword, confirmPassword])

  return (
    <Screen id="login-page" sidebar={false} dark>
      <img src={Logo} className='sidebar-img'/>
      {!loading && (
        <>
          {!success && (
            <>
              <Input.Password
                placeholder="Contraseña"
                className='login-input'
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <Input.Password
                placeholder="Confirmar contraseña"
                className='login-input'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <Button
                disabled={disabled}
                loading={actionLoading}
                onClick={handleSave}
              >
                Guardar
              </Button>
            </>
          )}
          {error && (
            <p className="error">
              Las contraseñas no coinciden
            </p>
          )}
          {requestError && (
            <p className="error">
              Algo ha fallado, inténtalo más tarde
            </p>
          )}
          {success && (
            <p className="success">
              Contraseña cambiada con éxito
            </p>
          )}
        </>
      )}
      {loading && <Spin />}
    </Screen>
  )
}

export default RestorePasswordScreen
