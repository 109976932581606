import { type FC, type PropsWithChildren, createContext, useState } from 'react'
import { type IBranch } from '../types/branch'

interface IBranchContext {
  branch: IBranch | null
  setBranch: (branch: IBranch | null) => void
}

export const BranchContext = createContext<IBranchContext>({
  branch: null,
  setBranch: () => {}
})

export const BranchProvider: FC<PropsWithChildren> = ({ children }) => {
  const [branch, setBranch] = useState<IBranch | null>(null)

  return (
    <BranchContext.Provider value={{ branch, setBranch }}>
      {children}
    </BranchContext.Provider>

  )
}
