import { type FC } from 'react'
import { type IBranch } from '../../../../types/branch'

import './BranchItem.scss'

interface Props {
  branch: IBranch
  onClick: (branch: IBranch) => void
}

const BranchItem: FC<Props> = ({ branch, onClick }) => (
    <button className='branch-button' onClick={() => onClick(branch)} type="button">
        <div className="branch-item">
            <img src={branch.image} aria-label="branch"/>
            <div className="branch-name">
                <p>{branch.name}</p>
            </div>
        </div>
    </button>
)

export { BranchItem }
