import { Button, Modal, Spin, Table } from 'antd'
import { PlusOutlined, FileAddOutlined } from '@ant-design/icons'

import { getColumns } from './constants'
import { Screen } from '../../components'
import { useCallback, useMemo, useState } from 'react'
import { QuestionsModal } from './components/QuestionsModal'
import { useGetSubjectsByBranch } from '../../services/subject'
import { useGetTopics } from '../../services/topic'
import { useGetQuestions, useRemoveQuestion } from '../../services/question'
import { type IQuestion } from '../../types/question'
import { AddQuestionsModal } from './components/QuestionsModal/AddQuestionsModal'
import { type ITopic } from '../../types/topic'

import './AdminQuestions.scss'

const AdminQuestions = () => {
  const { loading, questions, refetch } = useGetQuestions()

  const { subjects, loading: loadingSubjects } = useGetSubjectsByBranch()
  const { topics, loading: loadingTopics } = useGetTopics()
  const { removeQuestion, loading: removeLoading } = useRemoveQuestion()

  const [modal, setModal] = useState(false)
  const [modalAddQuestions, setModalAddQuestions] = useState(false)
  const [removeModal, setRemoveModal] = useState(false)
  const [infoModal, setInfoModal] = useState(false)
  const [selectedQuestion, setSelectedQuestion] = useState<IQuestion | undefined>()

  const toggleModal = useCallback(() => { setModal((prev) => !prev) }, [])
  const toggleModalAddQuestions = useCallback(() => { setModalAddQuestions((prev) => !prev) }, [])
  const toggleRemoveModal = useCallback(() => { setRemoveModal((prev) => !prev) }, [])

  const onClose = async () => {
    setSelectedQuestion(undefined)
    setModal(false)
    setRemoveModal(false)
    await refetch()
  }

  const onCloseRemove = () => {
    setSelectedQuestion(undefined)
    toggleRemoveModal()
  }

  const onCloseAddQuestions = () => {
    toggleModalAddQuestions()
  }

  const onEditTopic = (question: IQuestion) => {
    setSelectedQuestion(question)
    toggleModal()
  }

  const onRemoveModal = (question: IQuestion) => {
    setSelectedQuestion(question)
    toggleRemoveModal()
  }

  const handleRemove = async () => {
    if (selectedQuestion?._id) {
      await removeQuestion(selectedQuestion?._id)
      await onClose()
    }
  }

  const subjectFilters = useMemo(() => subjects?.map(s => ({ text: s.name, value: s._id as string })), [subjects])
  const topicFilters = useMemo(() => topics?.map(t => ({ text: t?.name, value: t._id as string })), [topics])

  const columns = getColumns({
    onEdit: onEditTopic,
    onRemove: onRemoveModal,
    columnFilter: {
      subject: subjectFilters ?? [],
      topic: topicFilters ?? []
    }
  })

  const questionsInfo = (questions || []).reduce((acc: any, item) => {
    if (!acc[(item.topic as ITopic).name!]) {
      acc[(item.topic as ITopic).name!] = []
    }

    acc[(item.topic as ITopic).name!].push(item)

    return acc
  }, {})

  return (
    <Screen
      title='Preguntas'
      id="topics"
      actions={[{
        disabled: loadingSubjects || loadingTopics,
        icon: <PlusOutlined />,
        onClick: toggleModal
      },
      {
        disabled: loadingSubjects || loadingTopics,
        icon: <FileAddOutlined />,
        onClick: toggleModalAddQuestions
      }
      ]}>
        {questionsInfo && !loading && (
          <div className="more-container">
            <Button className='more-info' onClick={() => setInfoModal(true)}>
              Información
              </Button>
          </div>
        )}
        {loading && <Spin tip="Loading" size="large" />}
        {!loading && (
            <Table locale={{ emptyText: 'No hay preguntas que mostrar' }} columns={columns || []} dataSource={questions || []} rowKey="_id" />
        )}
        <QuestionsModal
            onClose={onClose}
            open={modal}
            selectedQuestion={selectedQuestion}
            subjects={subjects ?? []}
            title={`${selectedQuestion ? 'Actualizar' : 'Crear'} Pregunta`}
            topics={topics ?? []}
        />
        <Modal
            open={removeModal}
            onCancel={onCloseRemove}
            okButtonProps={{
              danger: true,
              loading: removeLoading
            }}
            onOk={handleRemove}
            okText="Eliminar pregunta"
            title="Eliminar pregunta"
        >
            {`Vas a eliminar la pregnuta "${selectedQuestion?.title}". ¿Estas seguro de que quieres continuar?`}
        </Modal>
        <Modal
            open={infoModal}
            onCancel={() => setInfoModal(false)}
            onOk={() => setInfoModal(false)}
            okText="Información"
            title="Información"
        >
          {questionsInfo && Object.entries(questionsInfo).map((it: any, idx) => (
            <div key={idx} className='questions-info'>
              <p>{it[0]}</p>
              <p>{`${it[1]?.length} Preguntas`}</p>
            </div>
          ))}
        </Modal>
        <AddQuestionsModal open={modalAddQuestions} onClose={onCloseAddQuestions} subjects={subjects ?? []} topics={topics ?? []} title='Añadir paquete de preguntas' refetch={refetch} />
    </Screen>
  )
}

export { AdminQuestions }
