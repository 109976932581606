import { Box, Screen } from '../../components'
import { useState, useCallback, useContext } from 'react'
import './ProfilePage.scss'
import { AuthContext } from '../../contexts/AuthContext'
import { Descriptions } from 'antd'
import { type IUser } from '../../types/user'
import { useUpdateUserProfile } from '../../services/user'
import { getDescriptionColumns } from './constants'

const ProfilePage = () => {
  const { user, setUser } = useContext(AuthContext)
  const [userEdit, setUserEdit] = useState<IUser>(user as IUser)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const { updateUserProfile, loading } = useUpdateUserProfile()

  const setEditing = useCallback(() => {
    setIsEditing(!isEditing)
  }, [isEditing])

  const updateUserCallback = useCallback(async () => {
    setUser(userEdit)
    await updateUserProfile(userEdit)
    setIsEditing(false)
  }, [userEdit])

  const setField = useCallback((key: string, value: string) => {
    setUserEdit((prev) => {
      return { ...prev, [key]: value }
    })
  }, [])

  const items = getDescriptionColumns({
    userEdit,
    isEditing,
    setField
  })

  return (
    <Screen id="profile" title="Mi perfil">
      <div className='profile-edit'>
        <Box title="Mis datos" actions={[{ label: isEditing ? 'Guardar' : 'Editar', type: 'default', onClick: isEditing ? updateUserCallback : setEditing, danger: false, loading }]}>
          <Descriptions items={items} column={1} bordered={true} />
        </Box>
      </div>
    </Screen>
  )
}

export { ProfilePage }
