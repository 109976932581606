import { useLazyQuery } from '@apollo/client'
import { COMPLETE_MULTIPART_UPLOAD, GET_MULTIPART_UPLOAD_ID, GET_PRESIGNED_URLS, GET_SIGNED_URL } from './queries'

export const useGetSignedUrl = () => {
    const [getSignedUrl, { loading }] = useLazyQuery(GET_SIGNED_URL, { fetchPolicy: 'network-only' })

    return {
        getSignedUrl,
        loading
    }
}

export const useGetMultipartUploadId = () => {
    const [action, { loading }] = useLazyQuery(GET_MULTIPART_UPLOAD_ID, { fetchPolicy: 'network-only' })

    const getMultipartUploadId = async (input: string) => {
        const response = await action({
            variables: {
                input
            }
        })

        return response?.data?.getMultipartUploadId as string
    }

    return {
        getMultipartUploadId,
        loading
    }
}

interface PresignedInput {
    filename: string
    uploadId: string
    numberOfParts: number
}

export const useGetPresignedUrls = () => {
    const [action, { loading }] = useLazyQuery(GET_PRESIGNED_URLS, { fetchPolicy: 'network-only' })

    const getPresignedUrls = async ({ filename, uploadId, numberOfParts }: PresignedInput) => {
        const response = await action({
            variables: {
                input: {
                    filename,
                    numberOfParts,
                    uploadId
                }
            }
        })

        return response?.data?.getPresignedUrls
    }

    return {
        getPresignedUrls,
        loading
    }
}

interface CompleteMultiUpload {
    filename: string
    uploadId: string
    parts: Array<{
        etag: string
        PartNumber: number
    }>
}

export const useCompleteMultipartUpload = () => {
    const [action, { loading }] = useLazyQuery(COMPLETE_MULTIPART_UPLOAD, { fetchPolicy: 'network-only' })

    const completeMultipartUploadId = async ({ filename, uploadId, parts }: CompleteMultiUpload) => {
        const response = await action({
            variables: {
                input: {
                    filename,
                    uploadId,
                    parts
                }
            }
        })

        return response?.data?.completeMultiPartUpload
    }

    return {
        completeMultipartUploadId,
        loading
    }
}
