import { useMutation, useQuery } from '@apollo/client'
import { useBranch } from '../../hooks/useBranch'
import { GET_ALL_ONLINE_SESSIONS } from './queries'
import { type IOnlineSession } from '../../types/onlineSession'
import { CREATE_ONLINE_SESSION, DELETE_ONLINE_SESSION } from './mutations'
import { useMemo } from 'react'

const mapper = (onlineSessions: IOnlineSession[]) => {
  const mapped = onlineSessions.reduce<any>((acc, item) => {
    const subject = item?.subject?.name as string
    const topic = item?.topic?.name as string

    if (!acc[subject]) {
      acc[subject] = {}
    }

    if (!acc[subject][topic]) {
      acc[subject][topic] = []
    }

    acc[subject][topic].push(item)

    return acc
  }, {})

  return mapped
}

export const useGetAllOnlineSessions = () => {
  const { branch } = useBranch()

  const { data, loading, refetch } = useQuery(GET_ALL_ONLINE_SESSIONS, {
    variables: {
      input: branch?._id
    },
    skip: !branch?._id
  })

  const onlineSessions = useMemo(() => mapper(data?.getAllOnlineSessions ?? []), [data])

  console.log(onlineSessions)

  return {
    onlineSessions,
    loading,
    refetch
  }
}

interface CreateOS {
  url: string
  title: string
  topic: string
  subject: string
}

export const useCreateOnlineSession = () => {
  const { branch } = useBranch()

  const [action, { loading }] = useMutation(CREATE_ONLINE_SESSION)

  const createOnlineSession = async (params: CreateOS) => {
    await action({
      variables: {
        input: {
          branch: branch?._id,
          title: params.title,
          url: params.url,
          topic: params.topic,
          subject: params.subject
        }
      }
    })
  }

  return {
    createOnlineSession,
    loading
  }
}

export const useDeleteOnlineSession = () => {
  const [action, { loading }] = useMutation(DELETE_ONLINE_SESSION)

  const deleteOnlineSession = async (input: string) => {
    await action({
      variables: {
        input
      }
    })
  }

  return {
    deleteOnlineSession,
    loading
  }
}
