import { useGetUserTests } from '../../../../services/userTest'
import { ResultBox } from '../ResultBox'

import './TestResults.scss'
import { Loader } from '../../../../components/Loader/Loader'

const TestResults = () => {
  const { loading, userTests } = useGetUserTests()

  if (loading) {
    return (
      <div className='test-result-loading'>
        <Loader size={150}/>
      </div>
    )
  }

  return (
    <div className='result-content'>
        {userTests && userTests.length > 0 && userTests?.map((userTest) =>
            <ResultBox key={userTest._id} result={userTest}/>
        )}
    </div>
  )
}

export { TestResults }
