import { IconEdit, IconTrash, IconCheck, IconX } from '@tabler/icons-react'
import { type IQuestion } from '../../types/question'
import { type ColumnFilterItem } from 'antd/es/table/interface'
import { type ITopic } from '../../types/topic'
import { type ISubject } from '../../types/subject'
import { SearchOutlined } from '@ant-design/icons'
import { type TableColumnsType } from 'antd'
import { DropDownFilterTitle } from './components/DropDownFilterTitle'

interface Params {
  onEdit: (question: IQuestion) => void
  onRemove: (question: IQuestion) => void
  columnFilter?: Filters
}

interface Filters {
  subject: ColumnFilterItem[]
  topic: ColumnFilterItem[]
}

export const getColumns = ({ onEdit, onRemove, columnFilter }: Params): TableColumnsType<IQuestion> => [
  {
    title: '',
    dataIndex: 'color',
    key: 'color',
    render: (color: string) => <div style={{ background: color, width: 20, height: 20, borderRadius: '100%' }}/>,
    width: 80
  },
  {
    title: 'Título',
    dataIndex: 'title',
    key: 'title',
    ellipsis: true,
    filterDropdown: DropDownFilterTitle,
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (title: any, question: IQuestion) => {
      const regex = new RegExp(`.*${title}.*`, 'ig')
      return regex.test(question.title)
    }
  },
  {
    title: 'Rama',
    dataIndex: ['branch', 'name'],
    key: 'branch_name'
  },
  {
    title: 'Bloque',
    dataIndex: ['subject', 'name'],
    key: 'subject_name',
    filters: columnFilter?.subject,
    filterSearch: true,
    onFilter: (value: any, question: IQuestion) => {
      return value === (question.subject as ISubject)._id
    }
  },
  {
    title: 'Tema',
    dataIndex: ['topic', 'name'],
    key: 'topic_name',
    filters: columnFilter?.topic,
    filterSearch: true,
    onFilter: (value: any, question: IQuestion) => {
      return value === (question.topic as ITopic)._id
    }
  },
  {
    title: 'Dificultad',
    key: 'rating',
    render: ({ rating }) => {
      // console.log(rating)
      return <div>{rating}</div>
    }
  },
  {
    title: 'Oposición Pasada',
    dataIndex: 'pastexam',
    key: 'pastexam',
    render: (isPast: boolean) => (isPast ? <IconCheck size={15} color='green'/> : <IconX size={15} color='red'/>)
  },
  {
    title: '',
    dataIndex: '',
    key: 'x',
    width: 50,
    render: (question: IQuestion) => (
      <button className='table-action' onClick={() => { onEdit(question) }}>
        <IconEdit size={15} color="white" />
      </button>
    )
  },
  {
    title: '',
    dataIndex: '',
    key: 'x',
    render: (question: IQuestion) => (
      <button className='table-action' onClick={() => { onRemove(question) }}>
        <IconTrash size={15} color="white" />
      </button>
    )
  }
]
