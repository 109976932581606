import { useMemo, type FC } from 'react'
import './Countdown.scss'

interface Props {
  timeLeft: number
}

const Countdown: FC<Props> = ({ timeLeft }) => {
  const minutes = useMemo(() => Math.floor(timeLeft / 60), [timeLeft])
  const seconds = useMemo(() => timeLeft % 60, [timeLeft])

  return (
    <div className="countdown">
        <p>
            Tiempo restante:
            <span>{`${minutes ? `${minutes}:` : ''}${seconds && seconds > 9 ? seconds : `0${seconds}`} ${minutes ? 'min' : 'segundos'}`}</span>
        </p>
    </div>
  )
}

export { Countdown }
