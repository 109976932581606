import { type FC, type PropsWithChildren, createContext, useState } from 'react'
import { type IUser } from '../types/user'

export enum Theme {
  dark = 'dark',
  light = 'light'
}

interface IAuthContext {
  user: IUser | null
  setUser: (user: IUser | null) => void
  setTheme: (theme: Theme) => void
  theme: Theme
}

export const AuthContext = createContext<IAuthContext>({
  user: null,
  setUser: () => {},
  theme: Theme.dark,
  setTheme: () => {}
})

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<IUser | null>(null)
  const [theme, setTheme] = useState<Theme>(Theme.light)

  return (
    <AuthContext.Provider value={{ user, setUser, theme, setTheme }}>
      {children}
    </AuthContext.Provider>

  )
}
