import { Spin } from 'antd'

import { Screen } from '../../components'

import './LoadingScreen.scss'

import Logo from '../../assets/cf-logo.png'

const LoadingScreen = () => (
    <Screen id="loading" dark sidebar={false}>
        <div className='content'>
            <img alt="logo" src={Logo} />
            <Spin tip="Loading" size="large" />
        </div>
    </Screen>
)

export { LoadingScreen }
