import { IconEye, IconEyeOff } from '@tabler/icons-react'
import { useMemo, type FC } from 'react'

interface Props {
  _id: string
  name: string
  onToggle: (val: string) => void
  editedAlloweance?: string[]
}

export const AlloweanceContent: FC<Props> = ({ editedAlloweance, _id, name, onToggle }) => {
  const toggleHidden = () => {
    onToggle(_id)
  }

  const isHidden = useMemo(() => {
    return editedAlloweance?.includes(_id)
  }, [editedAlloweance, _id])

  console.log('editedAlloweance', editedAlloweance)

  return (
        <div className='line-content'>
                <button className='eye' onClick={toggleHidden}>
                    {isHidden
                      ? (
                        <IconEyeOff color="red" />
                        )
                      : (
                        <IconEye color='green'/>
                        )}
                </button>
                <p>{name}</p>
            </div>
  )
}
