import { gql } from '@apollo/client'

export const GET_SIGNED_URL = gql`
    query getSignedUrl($input: String!) {
        getSignedUrl(input: $input)
    }
`

export const GET_MULTIPART_UPLOAD_ID = gql`
    query getMultipartUploadId($input: String!) {
        getMultipartUploadId(input: $input)
    }
`

export const GET_PRESIGNED_URLS = gql`
    query getPresignedUrls($input: GetPresignedUrlsInput!) {
        getPresignedUrls(input: $input)
    }
`

export const COMPLETE_MULTIPART_UPLOAD = gql`
    query completeMultiPartUpload($input: CompleteUploadInput!) {
        completeMultiPartUpload(input: $input)
    }
`
