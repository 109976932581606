import Input from 'antd/es/input/Input'
import { type FilterDropdownProps } from 'antd/es/table/interface'
import { useEffect, type ReactNode } from 'react'

const DropDownFilterTitle = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: FilterDropdownProps): ReactNode => {
  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: string
  ) => {
    confirm({ closeDropdown: false })
  }

  useEffect(() => {
    handleSearch(selectedKeys as string[], confirm, 'Title')
  }, [selectedKeys])

  return <Input type='text'
    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
  />
}

export { DropDownFilterTitle }
